import { apiRequest, ServiceConfig } from "../utils/backend";

export type WorkspaceKey =
  | "inspection"
  | "ier"
  | "site-diary"
  | "qa"
  | "quality-standard"
  | "fotolio"
  | "punchlist"
  | "eventmanager"
  | "powerflow"
  | "dms";

export type WorkspaceAccess = {
  [key in WorkspaceKey]: any;
};

export type PowerFlowRegister = {
  permissions: Object;
  register: {
    type: string;
    id: number;
    register_name: string;
    flag_workspace: boolean;
  };
};

export interface AccessPermission {
  application: string;
  resource: string;
  action: string;
}

export interface ApplicationPermissionSetData {
  id: string;
  applicationCode: string;
  objectCode: string;
  action: string;
}

export interface EntityRoleAccessRight {
  roleId: string;
  permissions: AccessPermission[];
}

export interface EntityAccessScope {
  entity: string;
  scopes: string[];
}

export const getMyRoleAccessRight = async (
  serviceConfig: ServiceConfig,
  entityType: "Root" | "Operation",
  entityId: string
) => {
  return await apiRequest<string[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/access-control/me?entityType=${entityType}&entityId=${entityId}`,
    serviceConfig
  );
};

export const getAllMyRoleAccessRight = async (
  serviceConfig: ServiceConfig,
  entityType: "Root" | "Operation"
) => {
  return await apiRequest<EntityAccessScope[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/access-control/all?entityType=${entityType}`,
    serviceConfig
  );
};

export const getRoleAccessRight = async (
  serviceConfig: ServiceConfig,
  entityId: string
) => {
  const res = await apiRequest<{ roles: EntityRoleAccessRight[] }>(
    `${serviceConfig.coreServiceApiBaseUrl}/access-control/${entityId}`,
    serviceConfig
  );
  return res.roles;
};

export const createRoleAccessRight = async (
  serviceConfig: ServiceConfig,
  entityId: string,
  accessRights: EntityRoleAccessRight[]
) => {
  return await apiRequest<any>(
    `${serviceConfig.coreServiceApiBaseUrl}/access-control/${entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({ roles: accessRights }),
    }
  );
};

export const getAllAccessRight = async (
  serviceConfig: ServiceConfig,
  applicationCode?: string,
  objectCode?: string,
  action?: string
) => {
  return await apiRequest<ApplicationPermissionSetData[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/application-permission-set-data` +
      (applicationCode || objectCode || action
        ? "?" +
          ((applicationCode ? `applicationCode=${applicationCode}` : "") +
            (objectCode ? `objectCode=${objectCode}` : "") +
            (action ? `action=${action}` : ""))
        : ""),
    serviceConfig
  );
};

export const getMyApps = async (
  serviceConfig: ServiceConfig,
  entityId: string
) => {
  return await apiRequest<WorkspaceAccess>(
    `${serviceConfig.coreServiceApiBaseUrl}/application/${entityId}`,
    serviceConfig
  );
};

export const getPowerflowRegister = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<PowerFlowRegister[]>(
    `${serviceConfig.powerFlowServiceApiBaseUrl}/powerflow/entities/${serviceConfig.entityId}/register_home`,
    serviceConfig
  );
};

export const getIsPlatformAdmin = async (serviceConfig: ServiceConfig) =>
  apiRequest<boolean>(
    `${serviceConfig.coreServiceApiBaseUrl}/access-control/isPlatformAdmin`,
    serviceConfig
  );
