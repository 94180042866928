import {
  NxpButton,
  NxpHeader,
  NxpSpin,
  NxpTable,
  notify,
} from "@nexploretechnology/nxp-ui";
import { InputNumber } from "antd";
import { useCallback, useEffect, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import {
  getCurrencyBase,
  CurrencyBase,
  getQuoteCurrencies,
  QuoteCurrencies,
  updateCurrencyRate,
  getAllCurrencies,
  Currency,
} from "../../services/currency";
import AddCurrencyModal from "./AddCurrency/AddCurrencyModal";
import { ColumnProps } from "antd/lib/table";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";

type CurrencyUiState =
  | "uninitialized"
  | "fetchingData"
  | "ready"
  | "adding"
  | "editSaving";

function CurrencyExchangePage() {
  const appContext = useAppContext();
  const { errorHandler, serviceConfig } = appContext;
  const { t } = useTranslation();
  const [currencyUiState, setCurrencyUiState] =
    useState<CurrencyUiState>("uninitialized");
  const [currencyBase, setCurrencyBase] = useState<CurrencyBase>();
  const [quoteCurrencies, setQuoteCurrencies] = useState<QuoteCurrencies[]>([]);
  const [allCurrencies, setAllCurrencies] = useState<Currency[]>([]);
  const [initQuoteCurrencies, setInitQuoteCurrencies] = useState<
    QuoteCurrencies[]
  >([]);

  const baseCurrencyColumn = useCallback(() => {
    return [
      {
        title: t("CurrencyExchangePage.column.code"),
        dataIndex: "currency",
        width: 200,
      },
      {
        title: t("CurrencyExchangePage.column.unit"),
        dataIndex: "description",
        width: 250,
      },
      {
        title: t("CurrencyExchangePage.column.rate"),
        dataIndex: "rate",
        width: 200,
      },
    ];
  }, [t]);

  const getTableEditRowFormDefaultFormstateColumns =
    (): ColumnProps<QuoteCurrencies>[] => [
      {
        title: t("CurrencyExchangePage.column.code"),
        dataIndex: "currency",
        width: 200,
      },
      {
        title: t("CurrencyExchangePage.column.unit"),
        dataIndex: "description",
        width: 250,
      },
      {
        title: t("CurrencyExchangePage.column.rate"),
        dataIndex: "rate",
        width: 200,
        render: (_: unknown, record: QuoteCurrencies) => {
          return (
            <InputNumber
              min={0}
              value={record.rate}
              onChange={(e) => {
                setQuoteCurrencies((prev) => {
                  const newQuoteCurrency = cloneDeep(prev);
                  const targetIndex = newQuoteCurrency.findIndex((currency) => {
                    return currency.currency === record.currency;
                  });
                  if (e) {
                    newQuoteCurrency[targetIndex].rate = e;
                  }
                  return newQuoteCurrency;
                });
              }}
            />
          );
        },
      },
    ];

  const fetch = useCallback(async () => {
    setCurrencyUiState("fetchingData");
    try {
      const result = await Promise.all([
        getCurrencyBase(serviceConfig),
        getQuoteCurrencies(serviceConfig),
        getAllCurrencies(serviceConfig),
      ]);

      const currencyBase = result[0];
      currencyBase.rate = 1;
      setCurrencyBase(currencyBase);

      const quoteCurrencyList = result[1];
      setQuoteCurrencies(
        quoteCurrencyList.filter(
          (currency) => currency.currency !== currencyBase.currency
        )
      );
      setInitQuoteCurrencies(
        quoteCurrencyList.filter(
          (currency) => currency.currency !== currencyBase.currency
        )
      );

      const allCurrencies = result[2];
      setAllCurrencies(allCurrencies);
    } catch (err) {
      errorHandler(err, t("CurrencyExchangePage.error.fetch"));
    } finally {
      setCurrencyUiState("ready");
    }
  }, [errorHandler, serviceConfig, t]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleRefresh = () => {
    fetch();
  };

  const handleAddCurrencyModalClose = () => {
    setCurrencyUiState("ready");
  };

  const handleAddCurrencyModalOpen = () => {
    setCurrencyUiState("adding");
  };

  const handleEditSave = async () => {
    setCurrencyUiState("editSaving");
    try {
      const diff: QuoteCurrencies[] = [];
      initQuoteCurrencies.forEach((currency) => {
        quoteCurrencies.forEach((quote) => {
          if (
            quote.currency === currency.currency &&
            quote.rate !== currency.rate
          ) {
            diff.push(quote);
          }
        });
      });
      const apiPromise = diff.map((currency) => {
        return updateCurrencyRate(
          serviceConfig,
          currency.rate,
          currency.currency
        );
      });
      await Promise.all(apiPromise);
      notify.actionCompleted();
      fetch();
    } catch (err) {
      errorHandler(err, t("CurrencyExchangePage.error.update"));
    } finally {
      setCurrencyUiState("ready");
    }
  };

  const addCurrencyDropDown = () => {
    let currencyDropDown: Currency[] = [];
    const quoteCurrencyName = quoteCurrencies.map((currency) => {
      return currency.currency;
    });

    const baseAndQuoteCurrency = [currencyBase?.currency, ...quoteCurrencyName];

    allCurrencies.forEach((currency) => {
      if (!baseAndQuoteCurrency.includes(currency.code)) {
        currencyDropDown.push(currency);
      }
    });
    return currencyDropDown;
  };

  const sortQuoteCurrencies = quoteCurrencies.sort((a, b) => {
    if (a.currency < b.currency) {
      return -1;
    }
    if (a.currency > b.currency) {
      return 1;
    }
    return 0;
  });

  if (currencyUiState === "fetchingData" || currencyUiState === "editSaving") {
    return (
      <NxpSpin size="large" style={{ marginTop: "180px" }}>
        <div className="content" />
      </NxpSpin>
    );
  }
  return (
    <>
      <AddCurrencyModal
        addCurrencyModalVisible={currencyUiState === "adding"}
        onModalClose={handleAddCurrencyModalClose}
        addCurrencyDropDown={addCurrencyDropDown()}
        onRefresh={handleRefresh}
      />

      <NxpHeader
        titleContent={t("CurrencyExchangePage.title.manageCurrency")}
        actionContent={
          <NxpButton onClick={handleAddCurrencyModalOpen}>
            {t("CurrencyExchangePage.button.addCurrency")}
          </NxpButton>
        }
      />
      <NxpHeader
        level={3}
        titleContent={t("CurrencyExchangePage.subtitle.baseCurrency")}
      />
      <NxpTable
        rowKey={(row) => row.currency}
        useDefaultHeight={true}
        pagination={false}
        columns={baseCurrencyColumn()}
        dataSource={currencyBase && [currencyBase]}
      />

      <NxpHeader
        level={3}
        titleContent={t("CurrencyExchangePage.subtitle.quoteCurrency")}
        actionContent={
          <>
            <NxpButton onClick={handleEditSave}>
              {t("app.common.Save")}
            </NxpButton>
            <NxpButton
              onClick={() => {
                setQuoteCurrencies(initQuoteCurrencies);
              }}
              type="text"
            >
              {t("CurrencyExchangePage.button.reset")}
            </NxpButton>
          </>
        }
      />
      <NxpTable
        rowKey={(row) => row.currency}
        useDefaultHeight={true}
        pagination={false}
        columns={getTableEditRowFormDefaultFormstateColumns()}
        dataSource={sortQuoteCurrencies}
      />
    </>
  );
}

export default CurrencyExchangePage;
