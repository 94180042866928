import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { PlusCircleFilled } from "@ant-design/icons";
import {
  DateFormatPattern,
  getColumnSettings,
  mapDefaultColumnsToSettings,
  NxpButton,
  NxpColumnsModalButton,
  NxpColumnsSetting,
  NxpDateDisplay,
  NxpFilter,
  NxpFilterModalButton,
  NxpFilterOption,
  NxpHeader,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { find, get } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { DictionaryType } from "../../services/dictionary";
import DictionaryTypeTable from "./DictionaryTypeTable";

interface DictionaryTypePageLayoutProps {
  dictionaryTypes: DictionaryType[];
  submitting: boolean;
  onClickAddType: () => void;
  onClickTableRowEdit: (editItem: DictionaryType) => void;
  tableFilters: NxpFilter[];
  onFiltersApply: (filters: NxpFilter[]) => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {},
}));

const DictionaryTypePageLayout: React.FC<DictionaryTypePageLayoutProps> = ({
  dictionaryTypes,
  submitting,
  onClickAddType,
  onClickTableRowEdit,
  tableFilters,
  onFiltersApply,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeEntity, users } = useAppContext();
  const navigate = useNavigate();
  const defaultColumns: ColumnType<DictionaryType>[] = useMemo(() => {
    return [
      {
        title: t("app.common.Name"),
        dataIndex: "name",
        width: 250,
        sorter: (a, b) => sorterForString(a.name, b.name),
      },
      {
        title: t("DictionaryType.Application"),
        dataIndex: "application",
        width: 250,
        sorter: (a, b) => sorterForString(a.application, b.application),
      },
      {
        title: t("DictionaryType.Description"),
        dataIndex: "description",
        width: 250,
        sorter: (a, b) => sorterForString(a.description, b.description),
      },
      {
        title: t("DictionaryType.DataType"),
        dataIndex: "dataType",
        width: 200,
        sorter: (a, b) => sorterForString(a.dataType, b.dataType),
      },
      {
        title: t("DictionaryType.CreatedOn"),
        dataIndex: "createdDate",
        render: (val: Date) => (
          <NxpDateDisplay date={val} format={DateFormatPattern.date} />
        ),
        width: 250,
        sorter: (a, b) => sorterForDate(a.createdDate, b.createdDate),
      },
      {
        title: t("DictionaryType.UpdatedOn"),
        dataIndex: "lastUpdatedDate",
        render: (val: Date) => (
          <NxpDateDisplay date={val} format={DateFormatPattern.date} />
        ),
        width: 250,
        sorter: (a, b) => sorterForDate(a.lastUpdatedDate, b.lastUpdatedDate),
      },
      {
        title: t("DictionaryType.CreateBy"),
        dataIndex: "createdById",
        render: (val: string) => {
          return (
            <Typography.Text>
              {get(find(users, { id: val }), "displayName", val)}
            </Typography.Text>
          );
        },
        width: 250,
        sorter: (a, b) => sorterForDate(a.lastUpdatedDate, b.lastUpdatedDate),
      },
      {
        title: t("DictionaryType.LastUpdatedBy"),
        dataIndex: "lastUpdatedById",
        render: (val: string) => {
          return (
            <Typography.Text>
              {get(find(users, { id: val }), "displayName", val)}
            </Typography.Text>
          );
        },
        width: 250,
        sorter: (a, b) => sorterForDate(a.lastUpdatedDate, b.lastUpdatedDate),
      },
    ];
  }, [t, users]);

  const defaultColumnsSettings: NxpColumnsSetting[] = useMemo(() => {
    return mapDefaultColumnsToSettings(defaultColumns);
  }, [defaultColumns]);
  const [columns, setColumns] =
    useState<ColumnType<DictionaryType>[]>(defaultColumns);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const filterOptions: NxpFilterOption[] = useMemo(() => {
    return [
      {
        fieldKey: "name",
        fieldLabel: "Name",
        valueType: "string",
      },
      {
        fieldKey: "application",
        fieldLabel: "Application",
        valueType: "string",
      },
      {
        fieldKey: "description",
        fieldLabel: "Description",
        valueType: "string",
      },
      {
        fieldKey: "dataType",
        fieldLabel: "DataType",
        valueType: "string",
      },
      {
        fieldKey: "createdOn",
        fieldLabel: "Created On",
        valueType: "date",
      },
      {
        fieldKey: "updatedOn",
        fieldLabel: "Updated On",
        valueType: "date",
      },
    ];
  }, []);

  const handleColumnsApply = useCallback(
    (columnsSetting: NxpColumnsSetting[]) => {
      const newColumns: ColumnType<DictionaryType>[] = [];
      columnsSetting.forEach((setting) => {
        const column = columns.find((item) => item.title === setting.title);
        if (column) {
          newColumns.push(column);
        }
      });
      setColumns(newColumns);
    },
    [columns]
  );

  return (
    <div className={classes.root}>
      <NxpHeader
        titleContent={t("app.layout.DictionaryType")}
        actionContent={
          <>
            <NxpFilterModalButton
              filterOptions={filterOptions}
              filters={tableFilters}
              onFiltersApply={(filters) => onFiltersApply(filters)}
            />
            <NxpColumnsModalButton
              columns={getColumnSettings(columns, defaultColumns)}
              defaultColumns={defaultColumnsSettings}
              onColumnsApply={handleColumnsApply}
            />

            {activeEntity && (
              <NxpButton
                onClick={() => {
                  navigate(`/entities/${activeEntity.id}/dictionary`);
                }}
              >
                {t("DictionaryType.ProjectDictionary")}
              </NxpButton>
            )}

            <NxpButton onClick={onClickAddType} icon={<PlusCircleFilled />}>
              {t("DictionaryType.AddType")}
            </NxpButton>
          </>
        }
      />
      <DictionaryTypeTable
        columns={columns}
        tableData={dictionaryTypes}
        submitting={submitting}
        onClickTableRowEdit={onClickTableRowEdit}
      />
    </div>
  );
};

export default DictionaryTypePageLayout;
