import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import SignUpUserForm from "./SignUpUserForm";

interface SignUpUserModalProps {
  isSubmitting: boolean;
  showSignUpUserModal: boolean;
  setShowSignUpUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSignUpUser: (form: SignUpUserFormData) => void;
}

export interface SignUpUserFormData {
  firstName: string;
  lastName: string;
  email: string;
}

export const emptyForm: SignUpUserFormData = {
  firstName: "",
  lastName: "",
  email: "",
};

const SignUpUserModal: React.FC<SignUpUserModalProps> = ({
  isSubmitting,
  showSignUpUserModal,
  setShowSignUpUserModal,
  onSignUpUser,
}) => {
  const { t } = useTranslation();

  const [form, setForm] = useState<SignUpUserFormData>(emptyForm);

  const formSchema = yup.object().shape({
    firstName: yup.string().required(t("ManageUsers.FirstNameIsRequired")),
    lastName: yup.string().required(t("ManageUsers.LastNameIsRequired")),
    email: yup
      .string()
      .required(t("ManageUsers.EmailIsRequired"))
      .email(({ value }) =>
        t("ManageUsers.EmailIsNotAValidEmail", { email: value })
      ),
  });

  const handleFormGridStateChange = (
    fieldName: keyof SignUpUserFormData,
    value: any
  ) => {
    const data: Partial<SignUpUserFormData> = {};
    data[fieldName] = value;
    setForm({ ...form, ...data });
  };

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<SignUpUserFormData>(form, formSchema, () =>
      onSignUpUser(form)
    );

  useEffect(() => {
    setForm(emptyForm);
    clearError();
  }, [showSignUpUserModal, clearError]);

  return (
    <NxpModal
      title={t("ManageUsers.SignUpUser")}
      visible={showSignUpUserModal}
      onCancel={() => setShowSignUpUserModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="default"
            onClick={() => setShowSignUpUserModal(false)}
            loading={isSubmitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton
            onClick={() => saveWithValidate(undefined)}
            loading={isSubmitting}
          >
            {t("ManageUsers.SignUp")}
          </NxpButton>
        </>
      }
    >
      <SignUpUserForm
        form={form}
        validationError={validationError}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default SignUpUserModal;
