import { LangLabel } from "../i18n/LangLabelEnum";

export const LAZY_LOAD_PAGE_SIZE = 50;

export const DEFAULT_DECIMAL_PLACE = 2;

export const APPLICATION_CODE = "entity";

export const DEFAULT_LANGUAGE_KEY = LangLabel.EN;

export const DEFAULT_LANGUAGES = [
  LangLabel.EN,
  LangLabel.ES,
  LangLabel.DE,
  LangLabel.ZH_HK,
];

export const DRAG_DROP_TYPE_PDS_ITEM = "pds-item";
