import React, { useCallback, useState } from "react";

import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  children: React.ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      users: [],
      organisationTypes: [],
    }
  );

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const hasRight = useCallback(
    (right: string) =>
      !!appContextCache.accessPermissionList &&
      appContextCache.accessPermissionList.length > 0 &&
      appContextCache.accessPermissionList.some(
        (permission) => permission === right
      ),
    [appContextCache.accessPermissionList]
  );

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        hasRight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
