import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpTable,
  NxpTableRowDropdown,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";
import { ColumnProps } from "antd/lib/table";

import useAppContext from "../../../hooks/useAppContext";
import { Subcontractor } from "../../../services/subcontractor";

export interface SubcontractorListingLayoutProps {
  onClickStateConfig: (subcontractor: Subcontractor) => void;
  subcontractors: Subcontractor[];
  onRowClick: (subcontractor: Subcontractor) => void;
}
const useStyles = createUseStyles((theme) => ({
  disabledRow: {
    cursor: ["not-allowed", "!important"],
    color: [theme.palette.dim, "!important"],
    "& > td.ant-table-cell": {
      backgroundColor: [theme.palette.backgroundDark, "!important"],
    },
  },
}));

const SubcontractorListingLayout: React.FC<SubcontractorListingLayoutProps> = ({
  subcontractors,
  onRowClick,
  onClickStateConfig,
}) => {
  const { t } = useTranslation();
  const { hasRight } = useAppContext();
  const renderActionMenu = useCallback(
    (_: any, subcontractor: Subcontractor) => (
      <NxpTableRowDropdown
        overlay={
          <Menu>
            <Menu.Item
              disabled={!hasRight("@company/company:delete")}
              key={subcontractor?.isDisabled ? "enable" : "disable"}
              onClick={(e) => {
                e?.domEvent?.stopPropagation();
                onClickStateConfig(subcontractor);
              }}
            >
              {subcontractor?.isDisabled
                ? t("ManageCompanies.action.enable.label")
                : t("ManageCompanies.action.disable.label")}
            </Menu.Item>
          </Menu>
        }
      />
    ),
    [hasRight, onClickStateConfig, t]
  );
  const classes = useStyles();

  const columns: ColumnProps<Subcontractor>[] = useMemo(() => {
    return [
      {
        title: t("ManageCompanies.label.company"),
        dataIndex: "name",
        width: 100,
        defaultSortOrder: "ascend",
        sorter: (a, b) => sorterForString(a.name, b.name),
      },
      {
        title: t("ManageCompanies.label.contactPerson"),
        dataIndex: "contactPerson",
        width: 100,
        sorter: (a, b) => sorterForString(a.contactPerson, b.contactPerson),
      },
      {
        title: t("ManageCompanies.label.email"),
        dataIndex: "mailDomain",
        render: (_, record) => `@${record?.mailDomain}`,
        width: 150,
        sorter: (a, b) => sorterForString(a.mailDomain, b.mailDomain),
      },
      {
        title: t("ManageCompanies.label.contactNo"),
        dataIndex: "phoneNumber",
        width: 80,
        sorter: (a, b) => sorterForString(a.phoneNumber, b.phoneNumber),
      },
      {
        fixed: "right",
        align: "right",
        title: t("ManageCompanies.label.action"),
        dataIndex: "action",
        width: 40,
        render: renderActionMenu,
      },
    ];
  }, [t, renderActionMenu]);
  return (
    <NxpTable
      rowKey={(item) => item.id}
      columns={columns}
      useDefaultHeight={-70}
      dataSource={subcontractors}
      rowClassName={(item) => (item.isDisabled ? classes.disabledRow : "")}
      onRow={(record: Subcontractor) => ({
        onClick: () =>
          hasRight("@company/company:edit") &&
          !record?.isDisabled &&
          onRowClick(record),
      })}
    />
  );
};

export default SubcontractorListingLayout;
