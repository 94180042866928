import { sorterForString } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import countries, { getNames } from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const countryList: LabeledValue[] = Object.entries(
  getNames("en", { select: "official" })
)
  .map(([code, name]) => ({
    label: name,
    value: code,
  }))
  .sort((a, b) => sorterForString(a.label, b.label));
