import React from "react";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  NxpUpload,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Col, Row, Tooltip } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";

import {
  User,
  userSalutationValues,
} from "../../../../../services/userDirectory";
import AppUserProfile from "../../AppUserProfile";

const useStyles = createUseStyles((theme) => ({
  appAccountSettingsGeneralLayout: {
    marginTop: theme.spacing(1),
  },
  profile: {
    marginLeft: theme.spacing(1),
    "& .userImage": { margin: theme.spacing(0, 3.5) },
    "& .userInfo": { marginLeft: theme.spacing(6) },
  },
  upload: {
    "& .ant-upload": {
      display: "block",
    },
    "& button": {
      marginLeft: 0,
      width: "100%",
    },
  },
  recommendedSize: {
    fontSize: theme.fontSize.small,
    margin: 0,
  },
}));

interface AppAccountSettingsGeneralLayoutProps {
  editForm: User;
  validationError: ValidationResult<User>;
  saveInProgress: boolean;
  onFormGridStateChange: (fieldKey: keyof User, value: unknown) => void;
  onSave: () => void;
  onImageUpload: (file: RcFile) => Promise<UploadFile>;
  onImageUploadClear: () => void;
  onModalClose: () => void;
}

const AppAccountSettingsGeneralLayout: React.FC<AppAccountSettingsGeneralLayoutProps> =
  ({
    editForm,
    validationError,
    onFormGridStateChange,
    saveInProgress,
    onSave,
    onImageUpload,
    onImageUploadClear,
    onModalClose,
  }) => {
    const classes = useStyles();

    const formItems: NxpFormGridItemProps<User>[] = [
      {
        controlType: "custom",
        label: "",
        itemFieldName: "id",
        span: 8,
        customContent: (
          <Tooltip
            overlay={
              <p className={classes.recommendedSize}>
                Recommended Size: 150x150 pixels
              </p>
            }
          >
            <NxpUpload
              maxCount={1}
              acceptedFiles="image/*"
              className={classes.upload}
              onUpload={onImageUpload}
              onChange={(e) => {
                if (!e.fileList.length) {
                  onImageUploadClear();
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        controlType: "select",
        controlProps: {
          allowClear: true,
          options: [
            ...userSalutationValues.map((salutation) => ({
              label: salutation,
              value: salutation,
            })),
          ],
          onChange: (val) =>
            onFormGridStateChange("salutation", val ? val : null),
        },
        startOnNewRow: true,
        label: "Salutation",
        itemFieldName: "salutation",
        span: 8,
      },
      {
        controlType: "input",
        required: true,
        label: "First Name",
        itemFieldName: "firstName",
        span: 8,
      },
      {
        controlType: "input",
        required: true,
        label: "Last Name",
        itemFieldName: "lastName",
        span: 8,
      },
      {
        controlType: "input",
        required: true,
        label: "Display Name",
        itemFieldName: "displayName",
        span: 8,
      },
      {
        controlType: "input",
        label: "Job Title",
        itemFieldName: "title",
        span: 8,
      },
      {
        controlType: "input",
        startOnNewRow: true,
        required: true,
        label: "Email",
        itemFieldName: "primaryEmail",
        span: 8,
      },
      {
        controlType: "input",
        label: "Mobile",
        itemFieldName: "mobileNumber",
        span: 8,
      },
      {
        controlType: "input",
        label: "Telephone",
        itemFieldName: "officeNumber",
        span: 8,
      },
    ];

    return (
      <>
        <Row gutter={16} className={classes.appAccountSettingsGeneralLayout}>
          <Col span={24}>
            <AppUserProfile user={editForm} className={classes.profile} />
          </Col>
          <Col span={24}>
            <NxpFormGrid
              formState={editForm}
              validationError={validationError}
              formItems={formItems}
              onFormStateChange={onFormGridStateChange}
            />
          </Col>
        </Row>
        <NxpModalFooter>
          <>
            <NxpButton
              type="default"
              onClick={onModalClose}
              disabled={saveInProgress}
            >
              Cancel
            </NxpButton>
            <NxpButton onClick={onSave} loading={saveInProgress}>
              Save
            </NxpButton>
          </>
        </NxpModalFooter>
      </>
    );
  };

export default AppAccountSettingsGeneralLayout;
