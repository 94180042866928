import React, { useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";

import { PdsSpectrum } from "../../../../services/pds";
import UpdateSpectrumModal from "./UpdateSpectrumModal";

interface UpdateSpectrumButtonProps {
  pdsId: string;
  spectrum: PdsSpectrum;
  onSpectrumUpdated: () => void;
}

const UpdateSpectrumButton: React.FC<UpdateSpectrumButtonProps> = ({
  pdsId,
  spectrum,
  onSpectrumUpdated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton icon={<EditOutlined />} type="text" onClick={handleClick}>
        Edit
      </NxpButton>
      <UpdateSpectrumModal
        visible={modalVisible}
        pdsId={pdsId}
        spectrum={spectrum}
        onSpectrumUpdated={onSpectrumUpdated}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default UpdateSpectrumButton;
