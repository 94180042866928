import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import * as yup from "yup";

import AddDictionaryForm from "./AddDictionaryForm";

interface AddDictionaryModalProps {
  showModal: boolean;
  loading: boolean;
  isEdit: boolean;
  editData?: AddDictionaryFormData;
  dictionaryTypeOptions: LabeledValue[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (form: AddDictionaryFormData) => void;
}

export interface AddDictionaryFormData {
  name: string;
  dictionaryType: string;
}

const emptyForm: AddDictionaryFormData = {
  name: "",
  dictionaryType: "",
};

const AddDictionaryModal: React.FC<AddDictionaryModalProps> = ({
  showModal,
  loading,
  isEdit,
  editData,
  dictionaryTypeOptions,
  setShowModal,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<AddDictionaryFormData>(emptyForm);

  const formSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required(t("app.common.NameIsRequired")),
      dictionaryType: yup
        .string()
        .required(t("Dictionary.DictionaryTypeIsRequired")),
    });
  }, [t]);

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<AddDictionaryFormData>(form, formSchema, () =>
      onSubmit(form)
    );

  useEffect(() => {
    if (isEdit) {
      setForm(editData!);
    } else {
      setForm(emptyForm);
    }
    clearError();
  }, [isEdit, editData, showModal, clearError]);

  const handleFormGridStateChange = (
    fieldName: keyof AddDictionaryFormData,
    value: any
  ) => {
    const data: Partial<AddDictionaryFormData> = {};
    data[fieldName] = value;
    if (fieldName === "dictionaryType") {
      data.name = dictionaryTypeOptions.find((o) => o.value === value)!
        .label as string;
    }
    setForm({ ...form, ...data });
  };
  return (
    <NxpModal
      title={
        isEdit ? t("app.common.EditDictionary") : t("Dictionary.AddDictionary")
      }
      visible={showModal}
      onCancel={() => setShowModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="text"
            onClick={() => setShowModal(false)}
            loading={loading}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton
            onClick={() => saveWithValidate(undefined)}
            loading={loading}
          >
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <AddDictionaryForm
        form={form}
        validationError={validationError}
        isEdit={isEdit}
        dictionaryTypeOptions={dictionaryTypeOptions}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDictionaryModal;
