import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DateFormatPattern,
  formatDate,
  notify,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { getMyRoleAccessRight } from "../../services/accessControl";
import { EntityRole, getEntityRoles } from "../../services/entity";
import {
  AddUser,
  AddUserEntity,
  createUser,
  createUserEntity,
  deleteUserEntity,
  disableEntityUser,
  enableEntityUser,
  getUsers,
  PatchUser,
  patchUserInfo,
  resetPassword,
  User,
} from "../../services/userDirectory";
import AssignRoleModal from "./AssignRoleModal";
import ManageUsersPageLayout from "./ManageUsersPageLayout";
import SignUpUserModal, { SignUpUserFormData } from "./SignUpUserModal";
import UserDetailModal from "./UserDetailModal";

interface ManageUsersPageContainerProps {}

export interface UsersTableItem {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  roles: Array<string>;
  email: string;
  disabled: boolean;
  userEntities: Array<string>;
  salutation?: string | null;
  displayName: string;
  title?: string | null;
  mobileNumber?: string | null;
  officeNumber?: string | null;
  isPrimaryContact?: boolean;
  startDate?: Date;
  endDate?: Date;
  status?: string | null;
  comments?: string | null;
}

const ManageUsersPageContainer: React.FC<ManageUsersPageContainerProps> =
  () => {
    const {
      activeEntityType,
      activeEntity,
      serviceConfig,
      accessPermissionList,
      errorHandler,
      onAppContextCacheItemUpdate,
      hasRight,
    } = useAppContext();
    const navigate = useNavigate();

    const [init, setInit] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [tableData, setTableData] = useState<UsersTableItem[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [entityRoles, setEntityRoles] = useState<EntityRole[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [detailData, setDetailData] = useState<UsersTableItem>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [showAssignRoleModal, setShowAssignRoleModal] =
      useState<boolean>(false);
    const [showSignUpUserModal, setShowSignUpUserModal] =
      useState<boolean>(false);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

    const fetch = useCallback(async () => {
      try {
        if (!activeEntity || !activeEntityType) return;
        setLoading(true);

        const result = await Promise.all([
          getEntityRoles(
            serviceConfig,
            activeEntity!.id,
            activeEntityType,
            true
          ),
          getUsers(serviceConfig),
          getMyRoleAccessRight(
            serviceConfig,
            activeEntityType,
            activeEntity!.id
          ),
        ]);

        const entityRoles = result[0];
        setEntityRoles(entityRoles);
        const users = result[1];
        onAppContextCacheItemUpdate("users", users);
        setUsers(users);
        const accessPermissionList = result[2];
        onAppContextCacheItemUpdate(
          "accessPermissionList",
          accessPermissionList
        );
        setInit(false);
      } catch (err) {
        errorHandler(err, "fetch roles");
      } finally {
        setLoading(false);
      }
    }, [
      activeEntityType,
      activeEntity,
      serviceConfig,
      errorHandler,
      onAppContextCacheItemUpdate,
    ]);

    useEffect(() => {
      if (refresh) {
        fetch();
        setSelectedRows([]);
        setRefresh(false);
      }
    }, [refresh, fetch]);

    useEffect(() => {
      if (
        !init &&
        (!hasRight("@entity/entity:view") || !hasRight("@user/user:view"))
      ) {
        onAppContextCacheItemUpdate("activeEntity", undefined);
        navigate("/entities");
      }
    }, [
      init,
      accessPermissionList,
      hasRight,
      onAppContextCacheItemUpdate,
      navigate,
    ]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    useEffect(() => {
      let tData: UsersTableItem[] = [];
      entityRoles.forEach((role) => {
        role.userEntities?.forEach((userEntity) => {
          const idx = tData.findIndex((row) => row.id === userEntity.userId);
          if (idx !== -1) {
            tData[idx].roles.push(role.roleName);
            tData[idx].userEntities.push(userEntity.id);
          } else {
            const userDetail = users.find((u) => u.id === userEntity.userId);
            if (userDetail) {
              const item: UsersTableItem = {
                id: userDetail.id,
                salutation: userDetail.salutation,
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                displayName: userDetail.displayName,
                name: userDetail.displayName
                  ? userDetail.displayName
                  : `${userDetail.firstName} ${userDetail.lastName}`,
                email: userDetail.primaryEmail,
                title: userDetail.title,
                officeNumber: userDetail.officeNumber,
                mobileNumber: userDetail.mobileNumber,
                isPrimaryContact: userDetail.isPrimaryContact,
                startDate: userDetail.startDate
                  ? new Date(userDetail.startDate)
                  : undefined,
                endDate: userDetail.endDate
                  ? new Date(userDetail.endDate)
                  : undefined,
                status: userDetail.status,
                comments: userDetail.comments,
                disabled: userDetail.isSuspended,
                roles: [role.roleName],
                userEntities: [userEntity.id],
              };
              tData.push(item);
            }
          }
        });
      });
      setTableData(tData);
    }, [users, entityRoles]);

    const handleRowSelect = useCallback(
      (item: UsersTableItem) => {
        let newSelectedRows: string[] = [];
        if (selectedRows.includes(item.id)) {
          newSelectedRows = selectedRows.filter((row) => row !== item.id);
        } else {
          newSelectedRows = [...selectedRows, item.id];
        }
        setSelectedRows(newSelectedRows);
      },
      [selectedRows]
    );

    const handleRemoveUser = useCallback(
      async (item: UsersTableItem) => {
        try {
          setIsSubmitting(true);
          const promiseArray: Promise<any>[] = item.userEntities.map((u) => {
            return deleteUserEntity(serviceConfig, u);
          });
          const results = await Promise.all(promiseArray);
          notify.actionCompleted();
          setRefresh(true);
          return results;
        } catch (err) {
          errorHandler(err, "delete user");
        } finally {
          setIsSubmitting(false);
        }
      },
      [serviceConfig, errorHandler]
    );

    const handleAssignRole = useCallback(
      async (roles: string[]) => {
        try {
          setIsSubmitting(true);
          const promiseArray: Promise<any>[] = [];
          selectedRows.forEach((userId) => {
            const row = tableData.find((u) => u.id === userId);
            const originalRoles = row!.roles;
            const newRoles = roles.filter(
              (role) =>
                !originalRoles.includes(
                  entityRoles.find((r) => r.id === role)!.roleName
                )
            );
            if (newRoles.length > 0) {
              const addUserEntity: AddUserEntity = {
                entityRoleIds: newRoles,
              };
              promiseArray.push(
                createUserEntity(serviceConfig, userId, addUserEntity)
              );
            }
          });
          const results = await Promise.all(promiseArray);
          notify.actionCompleted();
          setRefresh(true);
          setShowAssignRoleModal(false);
          return results;
        } catch (err) {
          errorHandler(err, "assign role");
        } finally {
          setIsSubmitting(false);
        }
      },
      [entityRoles, selectedRows, tableData, serviceConfig, errorHandler]
    );

    const handleSignUpUser = useCallback(
      async (form: SignUpUserFormData) => {
        try {
          setIsSubmitting(true);
          const addUser: AddUser = {
            firstName: form.firstName,
            lastName: form.lastName,
            primaryEmail: form.email,
            entityRoleIds: entityRoles
              .filter((entityRole) => entityRole.roleCode === "gu")
              .map((entityRole) => entityRole.id),
          };
          const result = await createUser(serviceConfig, addUser);
          notify.actionCompleted();
          setRefresh(true);
          setShowSignUpUserModal(false);
          return result;
        } catch (err) {
          errorHandler(err, "sign up user");
        } finally {
          setIsSubmitting(false);
        }
      },
      [entityRoles, serviceConfig, errorHandler]
    );

    const handleResetPassword = useCallback(
      async (item: UsersTableItem) => {
        try {
          setIsSubmitting(true);
          await resetPassword(serviceConfig, item.id);
          notify.actionCompleted();
          setRefresh(true);
        } catch (err) {
          errorHandler(err, "reset user password");
        } finally {
          setIsSubmitting(false);
        }
      },
      [serviceConfig, errorHandler]
    );

    const handleClickEnable = useCallback(
      async (item: UsersTableItem) => {
        try {
          setIsSubmitting(true);
          if (item.disabled) {
            await enableEntityUser(serviceConfig, activeEntity!.id, item.id);
          } else {
            await disableEntityUser(serviceConfig, activeEntity!.id, item!.id);
          }
          notify.actionCompleted();
          setRefresh(true);
        } catch (err) {
          errorHandler(err, "enable/disable user");
        } finally {
          setIsSubmitting(false);
        }
      },
      [activeEntity, serviceConfig, errorHandler]
    );

    const handleDetailCancel = useCallback(() => {
      setShowDetailModal(false);
      setDetailData(undefined);
      setIsEdit(false);
    }, []);

    const handleClickViewDetails = useCallback((item: UsersTableItem) => {
      setDetailData(item);
      setIsEdit(false);
      setShowDetailModal(true);
    }, []);

    const handleClickEdit = useCallback((item: UsersTableItem) => {
      setDetailData(item);
      setIsEdit(true);
      setShowDetailModal(true);
    }, []);

    const handleDetailSubmit = useCallback(
      async (form: UsersTableItem) => {
        try {
          setLoading(true);
          const editedUser = {
            salutation: form.salutation,
            firstName: form.firstName,
            lastName: form.lastName,
            title: form.title,
            primaryEmail: form.email,
            mobileNumber: form.mobileNumber,
            officeNumber: form.officeNumber,
            isPrimaryContact: form.isPrimaryContact,
            startDate: formatDate(form.startDate, DateFormatPattern.date),
            endDate: formatDate(form.endDate, DateFormatPattern.date),
            status: form.status,
            comments: form.comments,
          } as PatchUser;
          await patchUserInfo(serviceConfig, form.id, editedUser);
          setShowDetailModal(false);
          notify.actionCompleted();
          setRefresh(true);
        } finally {
          setLoading(false);
        }
      },
      [serviceConfig]
    );

    return (
      <>
        <ManageUsersPageLayout
          loading={loading}
          tableData={tableData}
          selectedRows={selectedRows}
          onRowSelect={handleRowSelect}
          onRemoveUser={handleRemoveUser}
          onResetPassword={handleResetPassword}
          onClickEnable={handleClickEnable}
          onClickViewDetails={handleClickViewDetails}
          onClickEdit={handleClickEdit}
          setShowAssignRoleModal={setShowAssignRoleModal}
          setShowSignUpUserModal={setShowSignUpUserModal}
        />
        <AssignRoleModal
          isSubmitting={isSubmitting}
          showAssignRoleModal={showAssignRoleModal}
          entityRoles={entityRoles}
          setShowAssignRoleModal={setShowAssignRoleModal}
          onAssignRole={handleAssignRole}
        />
        <SignUpUserModal
          isSubmitting={isSubmitting}
          showSignUpUserModal={showSignUpUserModal}
          setShowSignUpUserModal={setShowSignUpUserModal}
          onSignUpUser={handleSignUpUser}
        />
        {detailData ? (
          <UserDetailModal
            detailData={detailData}
            visible={showDetailModal}
            submitting={isSubmitting}
            isEdit={isEdit}
            onCancel={handleDetailCancel}
            onSubmit={handleDetailSubmit}
          />
        ) : undefined}
      </>
    );
  };

export default ManageUsersPageContainer;
