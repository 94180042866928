import React from "react";
import { useNavigate } from "react-router-dom";

import { NxpHeader, NxpSpin } from "@nexploretechnology/nxp-ui";

import { Pds } from "../../../services/pds";
import UpdatePdsTemplateButton from "../../PdsTemplatesPage/PdsTemplateModal/UpdatePdsTemplateButton";
import ReorderSpectrumsButton from "./ReorderSpectrums/ReorderSpectrumsButton";
import SpectrumItem from "./SpectrumItem";
import CreateSpectrumButton from "./SpectrumModal/CreateSpectrumButton";
import ExportSpectrumButton from "./SpectrumModal/ExportSpectrumButton";

interface SpectrumsProps {
  loading: boolean;
  pds?: Pds;
  onPdsRefresh: () => void;
}

const Spectrums: React.FC<SpectrumsProps> = ({
  loading,
  pds,
  onPdsRefresh,
}) => {
  const navigate = useNavigate();

  return (
    <NxpSpin spinning={loading} size="large" expand>
      <NxpHeader
        titleContent={
          pds && !pds.enabled
            ? `PDS Template - ${pds.name}`
            : "Project Data Structure (PDS)"
        }
        onBackClick={
          pds?.enabled ? undefined : () => navigate("./../pds-templates")
        }
        actionContent={
          <>
            {" "}
            {pds ? <ExportSpectrumButton pds={pds} /> : null}
            {pds && !pds.enabled ? (
              <UpdatePdsTemplateButton
                pds={pds}
                onSpectrumUpdated={onPdsRefresh}
              />
            ) : null}{" "}
            {pds ? (
              <ReorderSpectrumsButton pds={pds} onPdsRefresh={onPdsRefresh} />
            ) : null}{" "}
            <CreateSpectrumButton
              pdsId={pds?.id}
              onSpectrumCreated={onPdsRefresh}
            />
          </>
        }
      />
      {pds ? (
        pds.spectrums.length ? (
          pds.spectrums
            .sort((a, b) => a.order - b.order)
            .map((spectrum) => (
              <SpectrumItem
                key={spectrum.id}
                pds={pds}
                spectrum={spectrum}
                onPdsRefresh={onPdsRefresh}
              />
            ))
        ) : (
          <p>No groups defined.</p>
        )
      ) : (
        <p>No PDS record found.</p>
      )}
    </NxpSpin>
  );
};

export default Spectrums;
