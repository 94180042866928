import React, { useCallback, useContext, useState } from "react";
import { createUseStyles } from "react-jss";

import { notify } from "@nexploretechnology/nxp-ui";

import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import AppChangePromptModal from "../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../hooks/useAppContext";
import { createPds, PdsForm, PdsIconType } from "../../../services/pds";
import PdsTemplateForm from "./PdsTemplateForm";

const useStyles = createUseStyles((theme) => ({
  pdsTemplateModal: {},
}));

interface CreatePdsTemplateModalProps {
  visible: boolean;
  onPdsTemmplateCreated: () => void;
  onModalClose: () => void;
}

const CreatePdsTemplateModal: React.FC<CreatePdsTemplateModalProps> = ({
  visible,
  onPdsTemmplateCreated,
  onModalClose,
}) => {
  const { activeEntity, serviceConfig, errorHandler } = useAppContext();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    async (form: PdsForm) => {
      try {
        setLoading(true);
        await createPds(form, serviceConfig);
        notify.actionCompleted();
        onModalClose();
        onPdsTemmplateCreated();
        onChangePromptUpdate(false);
      } catch (ex) {
        errorHandler(ex, `pds template creation`);
      } finally {
        setLoading(false);
      }
    },
    [
      serviceConfig,
      onPdsTemmplateCreated,
      onModalClose,
      onChangePromptUpdate,
      errorHandler,
    ]
  );

  const classes = useStyles();

  return visible ? (
    <AppChangePromptModal
      withNoPropagation
      visible={visible}
      onCancel={onModalClose}
      className={classes.pdsTemplateModal}
      title="Add PDS Template"
    >
      <PdsTemplateForm
        initForm={{
          enabled: false, // for template
          operationEntityId: activeEntity!.id,
          name: "",
          icon: "" as PdsIconType,
        }}
        onSave={handleSave}
        onCancel={onModalClose}
        loading={loading}
      />
    </AppChangePromptModal>
  ) : null;
};

export default CreatePdsTemplateModal;
