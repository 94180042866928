import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";

import { RadarChartOutlined as companyIcon } from "@ant-design/icons";
import {
  NxpBackTop,
  NxpButton,
  NxpLayout,
  NxpNavbarConfigMenu,
  NxpSidebarLinkProps,
  NxpSidebarProps,
  NxpSidebarSubmenuProps,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";
import { Col, Row } from "antd";
import i18n from "i18next";

import useAppContext from "../../../hooks/useAppContext";
import entitiesIcon from "../../../images/sidebar/sidebar-entities.svg";
import securityIcon from "../../../images/sidebar/sidebar-security.svg";
import settingsIcon from "../../../images/sidebar/sidebar-settings.svg";
import workspaceIcon from "../../../images/sidebar/sidebar-workspace.svg";
import { patchMyUserPreference } from "../../../services/userDirectory";
import { APPLICATION_CODE } from "../../../utils/const";
import AppAccountSettingsButton from "./AppAccountSettingsModal/AppAccountSettingsButton";
import AppUserProfile from "./AppUserProfile";

const useStyles = createUseStyles((theme) => ({
  appLayout: {},
  entities: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${entitiesIcon})`,
      transform: "scale(.85)",
    },
  },
  workspace: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${workspaceIcon})`,
      transform: "scale(.75)",
    },
  },
  security: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${securityIcon})`,
      transform: "scale(.75)",
    },
  },
  settings: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${settingsIcon})`,
    },
  },
  company: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `${companyIcon}`,
    },
  },
  navBar: {},
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
      color: theme.palette.textLabel,
    },
  },

  userPopoverButtons: {
    "& > div": {
      padding: theme.spacing(1),
      "& > button": {
        width: "100%",
      },
    },
  },
}));

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const routeLocation = useLocation();

  const classes = useStyles();
  const {
    activeEntity,
    activeUser,
    language,
    serviceConfig,
    isPlatformAdmin,
    hasRight,
    onAppContextCacheItemUpdate,
  } = useAppContext();
  const { keycloak } = useKeycloak();

  const changeLanguage = useCallback(
    async (key: string) => {
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: APPLICATION_CODE,
        parameter: "language",
        value: key,
      });
      onAppContextCacheItemUpdate("language", key);
      return newUserPreference;
    },
    [serviceConfig, onAppContextCacheItemUpdate]
  );

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const sidebarProps: NxpSidebarProps = useMemo(() => {
    const items: NxpSidebarProps["items"] = [
      {
        type: "link",
        href: `/entities`,
        isActive: routeLocation.pathname === "/entities",
        title: t("app.layout.Entities"),
        className: classes.entities,
      },
    ];

    if (activeEntity && hasRight("@entity/entity:view")) {
      items.push({
        type: "link",
        href: `/entities/${activeEntity.id}/workspace`,
        isActive: routeLocation.pathname.endsWith("/workspace"),
        title: t("app.layout.Workspace"),
        className: classes.workspace,
      });

      if (
        hasRight("@entity/role:view") ||
        hasRight("@access/permission:assign") ||
        hasRight("@dictionary/dictionary:view") ||
        hasRight("@user/user:view")
      ) {
        const menuLinks: NxpSidebarSubmenuProps["menuLinks"] = [];
        if (hasRight("@entity/role:view")) {
          menuLinks.push({
            href: `/entities/${activeEntity.id}/roles`,
            title: t("app.layout.ManageRoles"),
          });
        }
        if (hasRight("@access/permission:assign")) {
          menuLinks.push({
            href: `/entities/${activeEntity.id}/access`,
            title: t("app.layout.Access"),
          });
        }
        if (hasRight("@dictionary/dictionary:view")) {
          menuLinks.push({
            href: `/entities/${activeEntity.id}/dictionary`,
            title: t("app.layout.Dictionaries"),
          });
        }
        if (hasRight("@user/user:view")) {
          menuLinks.push({
            href: `/entities/${activeEntity.id}/manage-users`,
            title: t("app.layout.ManageUsers"),
          });
        }
        if (hasRight("@user/user:view")) {
          menuLinks.push({
            href: `/entities/${activeEntity.id}/edit-entity`,
            title: t("app.layout.EditEntity"),
          });
        }
        items.push({
          type: "submenu",
          title: t("app.layout.Security"),
          className: classes.security,
          menuLinks: menuLinks,
        });
      }
    }

    const menuLinksSetting: NxpSidebarSubmenuProps["menuLinks"] = [];
    if (isPlatformAdmin) {
      menuLinksSetting.push({
        href: `/dictionary-type`,
        title: t("app.layout.DictionaryType"),
      });
    }

    if (activeEntity && hasRight("@pds/pds:view")) {
      const subMenuLinksPds: Omit<NxpSidebarLinkProps, "className">[] = [];
      subMenuLinksPds.push({
        href: `/entities/${activeEntity.id}/pds`,
        title: t("app.layout.Pds"),
      });
      subMenuLinksPds.push({
        href: `/entities/${activeEntity.id}/pds-templates`,
        title: t("app.layout.PdsTemplate"),
      });
      menuLinksSetting.push({
        menuGroupName: t("app.layout.Pds"),
        subMenuLinks: subMenuLinksPds,
      });
    }

    if (
      hasRight("@company/type:view") ||
      (activeEntity && hasRight("@company/company:view"))
    ) {
      const subMenuLinksCompany: Omit<NxpSidebarLinkProps, "className">[] = [];
      if (hasRight("@company/type:view")) {
        subMenuLinksCompany.push({
          href: `/company/organisation-type`,
          title: t("app.layout.OrganisationType"),
        });
      }
      if (activeEntity && hasRight("@company/company:view")) {
        subMenuLinksCompany.push({
          href: `/entities/${activeEntity.id}/company/manage-company`,
          title: t("app.layout.ManageCompanies"),
        });
      }

      menuLinksSetting.push({
        menuGroupName: t("AccessRight.company"),
        subMenuLinks: subMenuLinksCompany,
      });
      if (activeEntity) {
        menuLinksSetting.push({
          href: `/entities/${activeEntity.id}/manage-currency`,
          title: "Currency",
        });
      }
    }
    items.push({
      type: "submenu",
      title: t("app.layout.Settings"),
      className: classes.settings,
      menuLinks: menuLinksSetting,
    });

    return {
      items: items,
    };
  }, [
    activeEntity,
    classes,
    hasRight,
    isPlatformAdmin,
    routeLocation.pathname,
    t,
  ]);

  return (
    <NxpLayout
      className={classes.appLayout}
      navBarProps={{
        title: `Quality Control`,
        subtitleContent: `${activeEntity?.name || "--"} (v0.4.0)`,
        subheading: "",
        username: activeUser
          ? activeUser.displayName
            ? activeUser.displayName
            : `${activeUser.firstName} ${activeUser.lastName}`
          : "",
        userImage: activeUser?.avatar?.url,
        userPopoverContent: (onPopoverVisbleChange) =>
          activeUser ? (
            <>
              <AppUserProfile user={activeUser} />
              <Row className={classes.userPopoverButtons}>
                <Col span={12}>
                  <AppAccountSettingsButton
                    onPopoverVisbleChange={onPopoverVisbleChange}
                    user={activeUser}
                  />
                </Col>
                <Col span={12}>
                  <NxpButton onClick={() => keycloak.logout()}>
                    Logout
                  </NxpButton>
                </Col>
              </Row>
            </>
          ) : null,
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={changeLanguage}
            onLogoutClick={() => keycloak.logout()}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={sidebarProps}
    >
      {children}
      <NxpBackTop />
    </NxpLayout>
  );
};

export default AppLayout;
