import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import {
  NxpPanel,
  NxpTable,
  NxpTableRowDropdown,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";
import { ColumnProps } from "antd/lib/table";

import { LangLabel } from "../../../i18n/LangLabelEnum";
import {
  Pds,
  PdsDataSetForm,
  PdsSpectrum,
  PdsTranslationFieldNameEnum,
} from "../../../services/pds";
import { DEFAULT_LANGUAGE_KEY } from "../../../utils/const";
import ReorderDataSetsButton from "../DataSetDetails/ReorderDataSets/ReorderDataSetsButton";
import CreateDataSetButton from "./CreateDataSet/CreateDataSetButton";
import UpdateSpectrumButton from "./SpectrumModal/UpdateSpectrumButton";

interface SpectrumItemProps {
  pds: Pds;
  spectrum: PdsSpectrum;
  onPdsRefresh: () => void;
}
const useStyles = createUseStyles((theme) => ({
  spectrumItem: {},
  attributeButtons: {
    textAlign: "right",
    marginTop: theme.spacing(1),
  },
}));

const SpectrumItem: React.FC<SpectrumItemProps> = ({
  pds,
  spectrum,
  onPdsRefresh,
}) => {
  const navigate = useNavigate();

  const redirectToDataSet = (dataSetId?: string) => {
    if (pds.enabled) {
      navigate(`./datasets/${dataSetId}`);
    } else {
      navigate(`./../${pds.id}/datasets/${dataSetId}`);
    }
  };

  const columns: ColumnProps<PdsDataSetForm>[] = [
    {
      title: "Display Name",
      dataIndex: ["displayName", DEFAULT_LANGUAGE_KEY],
      width: 150,
      fixed: "left",
      sorter: (a, b) =>
        sorterForString(
          a.displayName[DEFAULT_LANGUAGE_KEY],
          b.displayName[DEFAULT_LANGUAGE_KEY]
        ),
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
      fixed: "left",
      sorter: (a, b) => sorterForString(a.code, b.code),
    },
    {
      title: "Description",
      dataIndex: ["description", DEFAULT_LANGUAGE_KEY],
      width: 150,
      fixed: "left",
      sorter: (a, b) =>
        sorterForString(
          a.description[DEFAULT_LANGUAGE_KEY],
          b.description[DEFAULT_LANGUAGE_KEY]
        ),
    },
    {
      fixed: "right",
      align: "right",
      title: "action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <NxpTableRowDropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={(menuInfo) => {
                  menuInfo.domEvent.stopPropagation();
                  redirectToDataSet(record.id);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item>Copy As New</Menu.Item>
            </Menu>
          }
        />
      ),
    },
  ];

  const dataSource: PdsDataSetForm[] = useMemo(
    () =>
      spectrum.dataSets
        .sort((a, b) => a.order - b.order)
        .map((dataSet) => {
          const displayName = {} as Record<LangLabel, string>;
          const description = {} as Record<LangLabel, string>;
          dataSet.translations.forEach((translation) => {
            switch (translation.fieldName) {
              case PdsTranslationFieldNameEnum.DISPLAY_NAME:
                displayName[translation.langKey] = translation.value;
                break;
              case PdsTranslationFieldNameEnum.DESCRIPTION:
                description[translation.langKey] = translation.value;
                break;
            }
          });
          return {
            id: dataSet.id,
            code: dataSet.code,
            name: dataSet.name,
            displayName: displayName,
            description: description,
          } as PdsDataSetForm;
        }),
    [spectrum.dataSets]
  );

  const classes = useStyles();

  return (
    <NxpPanel
      className={classes.spectrumItem}
      titleContent={`${spectrum.code} - ${
        spectrum.translations.find(
          (translation) =>
            translation.fieldName ===
              PdsTranslationFieldNameEnum.DISPLAY_NAME &&
            translation.langKey === DEFAULT_LANGUAGE_KEY
        )?.value
      } (${spectrum.dataSets.length})`}
      defaultCollapsed
      extra={
        <UpdateSpectrumButton
          pdsId={pds.id}
          spectrum={spectrum}
          onSpectrumUpdated={onPdsRefresh}
        />
      }
    >
      <NxpTable
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        useBackgroundLight
        onRow={(record) => ({
          onClick: () => {
            redirectToDataSet(record.id);
          },
        })}
      />
      <div className={classes.attributeButtons}>
        <ReorderDataSetsButton
          disabled={dataSource.length < 2}
          pds={pds}
          spectrum={spectrum}
          onPdsRefresh={onPdsRefresh}
        />
        <CreateDataSetButton
          spectrumId={spectrum.id}
          onDataSetCreated={onPdsRefresh}
        />
      </div>
    </NxpPanel>
  );
};

export default SpectrumItem;
