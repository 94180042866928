import React from "react";
import { useParams } from "react-router-dom";

import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";

import { Pds } from "../../services/pds";
import DataSetDetails from "../PdsPage/DataSetDetails";
import { findPdsDataSet } from "../PdsPage/pdsHelper";
import Spectrums from "../PdsPage/Spectrums";

interface PdsTemplateDetailsProps {
  pdsLoading: boolean;
  pds: Pds;
  onPdsRefresh: () => void;
}

const PdsTemplateDetails: React.FC<PdsTemplateDetailsProps> = ({
  pdsLoading,
  pds,
  onPdsRefresh,
}) => {
  const { dataSetId } = useParams<{ dataSetId: string }>();

  const dataSet = pds && dataSetId ? findPdsDataSet(dataSetId, pds) : undefined;

  if (dataSetId && !dataSet) {
    notify.error("Pds attribute not found.");
  }

  return (
    <NxpSpin spinning={pdsLoading} size="large" expand>
      {dataSet ? (
        <DataSetDetails
          pdsLoading={pdsLoading}
          pds={pds}
          dataSet={dataSet}
          onPdsRefresh={onPdsRefresh}
        />
      ) : (
        <Spectrums loading={pdsLoading} pds={pds} onPdsRefresh={onPdsRefresh} />
      )}
    </NxpSpin>
  );
};

export default PdsTemplateDetails;
