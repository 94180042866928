import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";
import { isUndefined } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import {
  disbleOrganisationTypeStatus,
  enableOrganisationTypeStatus,
  getOrganisationTypes,
  OrganisationType,
  OrganisationTypeTableData,
} from "../../services/organisationType";
import OrganisationTypeListingLayout from "./OrganisationTypePageLayout";

interface OrganisationTypeListingContainerProps {}

const OrganisationTypeListingContainer: React.FC<OrganisationTypeListingContainerProps> =
  () => {
    const { t } = useTranslation();
    const { serviceConfig, onAppContextCacheItemUpdate } = useAppContext();
    const fetchOrganisationTypeListAsyncData = useCallback(async () => {
      const organisationTypeListRes = await getOrganisationTypes(serviceConfig);
      if (!isUndefined(organisationTypeListRes))
        onAppContextCacheItemUpdate(
          "organisationTypes",
          organisationTypeListRes
        ); // need to confirm with kelvin k
      return organisationTypeListRes;
    }, [onAppContextCacheItemUpdate, serviceConfig]);

    const [organisationTypeListAsyncData, setOrganisationTypeListAsyncData] =
      useAsync<OrganisationTypeTableData[]>(() =>
        fetchOrganisationTypeListAsyncData()
      );

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedOrganisationType, setSelectedOrganisationType] =
      useState<OrganisationType>({} as OrganisationType);
    const [showOrganisationTypeModal, setShowOrganisationTypeModal] =
      useState<boolean>(false);
    const handleClickViewDetails = (organisationType: OrganisationType) => {
      notify.info(`Viewing ${organisationType.id}`);
    };

    const handleEditSubcontractor = (organisationType: OrganisationType) => {
      setSelectedOrganisationType(organisationType);
      setShowOrganisationTypeModal(true);
    };
    const handleFetch = useCallback(async () => {
      setOrganisationTypeListAsyncData(() =>
        fetchOrganisationTypeListAsyncData()
      );
      if (organisationTypeListAsyncData?.error)
        notify.error(organisationTypeListAsyncData?.error);
    }, [
      fetchOrganisationTypeListAsyncData,
      organisationTypeListAsyncData?.error,
      setOrganisationTypeListAsyncData,
    ]);
    const handleOrganisationTypeStatus = useCallback(
      async (organisationType: OrganisationTypeTableData) => {
        try {
          setLoading(true);
          await (organisationType?.isDisabled
            ? enableOrganisationTypeStatus(serviceConfig, organisationType?.id)
            : disbleOrganisationTypeStatus(
                serviceConfig,
                organisationType?.id
              ));
          organisationType?.isDisabled
            ? notify.success(t("Organisation.enabled.success"))
            : notify.success(t("Organisation.disabled.success"));
        } catch (error: unknown) {
          notify.error(error);
        } finally {
          setLoading(false);
          handleFetch();
        }
      },
      [handleFetch, serviceConfig, t]
    );
    return (
      <NxpSpin spinning={organisationTypeListAsyncData?.loading && loading}>
        <OrganisationTypeListingLayout
          selectedOrganisationType={selectedOrganisationType}
          showOrganisationTypeModal={showOrganisationTypeModal}
          onClickViewDetails={handleClickViewDetails}
          onEditOrganisationType={handleEditSubcontractor}
          onToggleOrganisationTypeStatus={handleOrganisationTypeStatus}
          setSelectedOrganisationType={setSelectedOrganisationType}
          setShowOrganisationTypeModal={setShowOrganisationTypeModal}
          onFetchingOrganisationType={handleFetch}
          organisationTypes={
            organisationTypeListAsyncData?.data as OrganisationTypeTableData[]
          }
        />
      </NxpSpin>
    );
  };

export default OrganisationTypeListingContainer;
