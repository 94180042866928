import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { NxpCardList, NxpHeader } from "@nexploretechnology/nxp-ui";

import { Pds } from "../../services/pds";
import PdsTemplateIcon from "./PdsTemplateIcon";
import CreatePdsTemplateModal from "./PdsTemplateModal/CreatePdsTemplateModal";

const useStyles = createUseStyles((theme) => ({
  pdsTemplateList: {
    "& .nxpCardList > button": {
      minHeight: 230,
    },
    "& .nxpCard:hover .pdsTemplateIcon": {
      cursor: "pointer",
      filter: "none",
    },
  },
  cardCover: {
    background: theme.palette.background,
    textAlign: "center",
    padding: theme.spacing(6, 0),
  },
}));

interface PdsTemplateListProps {
  loading: boolean;
  pdsList?: Pds[];
  onPdsRefresh: () => void;
}

const PdsTemplateList: React.FC<PdsTemplateListProps> = ({
  loading,
  pdsList,
  onPdsRefresh,
}) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const navigate = useNavigate();

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleCardClick = (pdsId: string) => {
    navigate(`./${pdsId}`);
  };

  const handleCreateModalClose = () => {
    setCreateModalVisible(false);
  };

  const classes = useStyles();

  return (
    <div className={classes.pdsTemplateList}>
      <NxpHeader titleContent="PDS Template" />
      <NxpCardList
        isLoading={loading}
        onCreateClick={handleCreateClick}
        createText="New PDS Template"
        cards={(pdsList || []).map((pds) => ({
          id: pds.id.toString(),
          onClick: () => handleCardClick(pds.id),
          cover: (
            <div className={classes.cardCover}>
              <PdsTemplateIcon iconType={pds.icon || "civil"} />
            </div>
          ),
          children: (
            <>
              <h3>{pds.name}</h3>
            </>
          ),
        }))}
      />
      {createModalVisible && (
        <CreatePdsTemplateModal
          visible={createModalVisible}
          onPdsTemmplateCreated={onPdsRefresh}
          onModalClose={handleCreateModalClose}
        />
      )}
    </div>
  );
};

export default PdsTemplateList;
