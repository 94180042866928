import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpButtonWithConfirm,
  NxpFormItem,
  NxpHeader,
  NxpSelectUserOption,
  NxpSpin,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Dropdown, Input, Menu, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { appTheme } from "../../components/App/appTheme";
import useAppContext from "../../hooks/useAppContext";
import { ADUser } from "../../services/userDirectory";
import {
  ManageRoleTableData,
  SelectUserFormData,
} from "./ManageRolePageContainer";

interface ManageRolePageLayoutProps {
  rowData?: ManageRoleTableData[];
  userRoles: Record<string, SelectUserFormData>;
  selectedRows: string[];
  searchValue: string;
  searchValueDebounced: string;
  isFormDirty: boolean;
  isSubmitting: boolean;
  loading: boolean;
  isSearchingAdUsers: boolean;
  userSelectOptions: (NxpSelectUserOption & { user?: ADUser; tag: string })[];
  onSearch: (searchValue: string) => void;
  onClickAddRole: () => void;
  onRowSelect: (item: ManageRoleTableData) => void;
  onRowEdit: (item: ManageRoleTableData) => void;
  onRowDelete: (item: ManageRoleTableData) => void;
  onUserRolesChange: (entityRoleId: string, users: string[]) => void;
  onReset: () => void;
  onClickSubmit: () => void;
  setShowBatchAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSearchAdUsers: (searchString: string) => void;
}

const useStyles = createUseStyles((theme) => ({
  action: {
    display: "flex",
  },
  buttonDiv: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ManageRolePageLayout: React.FC<ManageRolePageLayoutProps> = ({
  rowData,
  userRoles,
  selectedRows,
  searchValue,
  searchValueDebounced,
  isFormDirty,
  isSubmitting,
  loading,
  isSearchingAdUsers,
  userSelectOptions,
  onSearch,
  onClickAddRole,
  onRowSelect,
  onRowEdit,
  onRowDelete,
  onUserRolesChange,
  onReset,
  onClickSubmit,
  setShowBatchAssignRoleModal,
  onSearchAdUsers,
}) => {
  const { t } = useTranslation();
  const { hasRight } = useAppContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const renderCheckbox = useCallback(
    (_: any, item: ManageRoleTableData) => (
      <NxpFormItem
        key={`CheckBox-${item.id}`}
        controlType="checkbox"
        controlProps={{
          checked: selectedRows.includes(item.id),
          onChange: (_) => onRowSelect(item),
          disabled: !hasRight("@entity/role:assign"),
        }}
      />
    ),
    [selectedRows, onRowSelect, hasRight]
  );

  const renderTag = useCallback(
    (props: CustomTagProps) => {
      const option = userSelectOptions.find((o) => o.id === props.value);
      const tag = option ? option.tag : props.label?.toString().split(" <")[0];
      return (
        <Tag
          style={{ fontSize: appTheme.fontSize.main, border: "none" }}
          closable={props.closable}
          onClose={props.onClose}
        >
          {tag}
        </Tag>
      );
    },
    [userSelectOptions]
  );

  const renderUsers = useCallback(
    (_: any, item: ManageRoleTableData) => (
      <NxpFormItem
        key={`user-${item.id}`}
        controlType="selectUserMultiple"
        controlProps={{
          onSearch: onSearchAdUsers,
          loading: isSearchingAdUsers,
          options: userSelectOptions,
          value: userRoles[item.id].users,
          tagRender: renderTag,
          onChange: (value) => {
            if (value && value instanceof Array) {
              onUserRolesChange(item.id, value);
            }
          },
          disabled: !hasRight("@entity/role:assign"),
        }}
      />
    ),
    [
      userRoles,
      isSearchingAdUsers,
      userSelectOptions,
      onUserRolesChange,
      hasRight,
      onSearchAdUsers,
      renderTag,
    ]
  );

  const renderActionMenu = useCallback(
    (_: any, item: ManageRoleTableData) => (
      <Dropdown
        disabled={
          (!hasRight("@entity/role:edit") &&
            !hasRight("@entity/role:delete")) ||
          isFormDirty
        }
        overlay={
          <Menu>
            <Menu.Item key="edit">
              {hasRight("@entity/role:edit") && (
                <NxpButton
                  type="link"
                  onClick={() => onRowEdit(item)}
                  disabled={isFormDirty}
                >
                  {t("app.common.Edit")}
                </NxpButton>
              )}
            </Menu.Item>
            <Menu.Item key="delete">
              {hasRight("@entity/role:delete") && (
                <NxpButtonWithConfirm
                  type="link"
                  modalSize="small"
                  modalTitle={t("app.common.DeleteRole")}
                  modalContent={t(
                    "ManageRole.RolesBeingDeletedWillNotBeRetrievableConfirmToProceed"
                  )}
                  confirmButtonContent={t("app.common.Confirm")}
                  onConfirm={() => onRowDelete(item)}
                  disabled={isFormDirty}
                >
                  {t("app.common.Delete")}
                </NxpButtonWithConfirm>
              )}
            </Menu.Item>
          </Menu>
        }
      >
        <NxpButton type="link">
          <EllipsisOutlined />
        </NxpButton>
      </Dropdown>
    ),
    [isFormDirty, onRowDelete, onRowEdit, t, hasRight]
  );

  const columns: ColumnsType<ManageRoleTableData> = useMemo(
    () => [
      {
        dataIndex: "select",
        width: 20,
        render: renderCheckbox,
      },
      {
        title: t("app.common.Role"),
        dataIndex: "roleName",
        width: 100,
        sorter: (a: ManageRoleTableData, b: ManageRoleTableData) =>
          sorterForString(a.roleName, b.roleName),
      },
      {
        title: t("ManageRole.User"),
        dataIndex: "users",
        width: 300,
        render: renderUsers,
      },
      {
        fixed: "right",
        align: "right",
        title: t("app.common.Action"),
        dataIndex: "action",
        width: 30,
        render: renderActionMenu,
      },
    ],
    [renderCheckbox, renderUsers, renderActionMenu, t]
  );

  return (
    <div>
      <NxpSpin spinning={loading}>
        <NxpHeader
          titleContent={t("app.layout.ManageRoles")}
          onBackClick={() => navigate(-1)}
          actionContent={
            <div className={classes.action}>
              <Input
                prefix={<SearchOutlined />}
                value={searchValue}
                allowClear
                onChange={(e) => onSearch(e.target.value)}
              />
              {hasRight("@entity/role:create") && (
                <NxpButton onClick={onClickAddRole} disabled={isFormDirty}>
                  {t("app.common.AddRole")}
                </NxpButton>
              )}
              {hasRight("@entity/role:assign") && (
                <NxpButton
                  disabled={
                    isFormDirty || !selectedRows || selectedRows.length === 0
                  }
                  onClick={() => setShowBatchAssignRoleModal(true)}
                >
                  {t("app.common.BatchAssign")}
                </NxpButton>
              )}
            </div>
          }
        />
        <NxpTable
          rowKey={(item) => item.roleCode}
          useDefaultHeight={true}
          pagination={false}
          columns={columns}
          dataSource={rowData?.filter((row) =>
            row.roleName
              .toLowerCase()
              .includes(searchValueDebounced.toLowerCase())
          )}
        />
        {hasRight("@entity/role:assign") && (
          <div className={classes.buttonDiv}>
            <NxpButton type="default" onClick={onReset} loading={isSubmitting}>
              {t("app.common.DiscardChanges")}
            </NxpButton>
            <NxpButton
              onClick={onClickSubmit}
              disabled={!isFormDirty}
              loading={isSubmitting}
            >
              {t("ManageRole.SaveChanges")}
            </NxpButton>
          </div>
        )}
      </NxpSpin>
    </div>
  );
};

export default ManageRolePageLayout;
