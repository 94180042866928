import debounce from "lodash/debounce";
import { useEffect, useCallback, useRef, useState } from "react";

// https://stackoverflow.com/a/62017005/8252769
export function useDebounce(
  cb: Function,
  delay: number,
  additionalDeps: unknown[]
) {
  const cbRef = useRef(cb);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCb = useCallback(
    debounce((...args) => cbRef.current(...args), delay),
    [delay]
  );
  useEffect(() => {
    cbRef.current = cb;
  });
  // set additionalDeps to execute effect, when other values change (not only on delay change)
  useEffect(debouncedCb, [debouncedCb, ...additionalDeps]);
}

export function useDebouncedValue<T>(
  stateValue: T,
  delay: number,
  additionalDeps: unknown[]
) {
  const [value, setValue] = useState<T>(stateValue);
  useDebounce(() => setValue(stateValue), delay, additionalDeps);
  return value;
}
