import { apiRequest, ServiceConfig } from "../utils/backend";
import { EntityRole } from "./entity";

export interface UserEntity {
  id: string;
  userId: string;
  entityType: string;
  entityId: string;
  entityRoleId: string;
  entityRole?: EntityRole;
  user?: User;
}

export type UserStatus = "US_FULLTIME" | "US_PARTTIME";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
  isSuspended: boolean;
  userEntities?: UserEntity[];

  salutation?: string | null;
  displayName: string;
  title?: string | null;
  mobileNumber?: string | null;
  officeNumber?: string | null;
  comments?: string | null;
  isPrimaryContact?: boolean;
  startDate?: string | null;
  endDate?: string | null;
  status?: UserStatus | null;
  avatar?: {
    filename?: string;
    assetId?: string;
    url?: string;
  } | null;
}

export interface PatchUser {
  salutation: string;
  firstName: string;
  lastName: string;
  title: string;
  primaryEmail: string;
  mobileNumber: string;
  officeNumber: string;
  isPrimaryContact: boolean;
  startDate: string;
  endDate: string;
  status: string;
  comments: string;
}

export interface AddUserEntity {
  entityRoleIds: string[];
}

export interface UserInvitation {
  createUser: {
    primaryEmail: string;
    firstName: string;
    lastName: string;
  };
}

export type AddUser = {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  isADUser?: boolean;
  entityRoleIds?: string[];
} & Partial<AddUserEntity>;

export interface ADUser {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
}

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}

export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getUsers = async (serviceConfig: ServiceConfig) => {
  return apiRequest<User[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/${serviceConfig.entityId}`,
    serviceConfig
  );
};

export const getUser = async (serviceConfig: ServiceConfig) => {
  return apiRequest<User>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/myself`,
    serviceConfig
  );
};

export const createUserEntity = async (
  serviceConfig: ServiceConfig,
  userId: string,
  addUserEntity: AddUserEntity
) => {
  return apiRequest<UserEntity>(
    `${serviceConfig.coreServiceApiBaseUrl}/user-entity/${serviceConfig.entityId}/${userId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addUserEntity),
    }
  );
};

export const deleteUserEntity = async (
  serviceConfig: ServiceConfig,
  userEntityId: string
) => {
  return apiRequest<any>(
    `${serviceConfig.coreServiceApiBaseUrl}/user-entity/${serviceConfig.entityId}/${userEntityId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const createUser = async (
  serviceConfig: ServiceConfig,
  createUser: AddUser
) => {
  return apiRequest<User>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(createUser),
    }
  );
};

export const getADUsers = async (
  serviceConfig: ServiceConfig,
  tenantId: string,
  searchString: string = ""
) => {
  return apiRequest<ADUser[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/azureAD/${tenantId}?s=${searchString}`,
    serviceConfig
  );
};

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};

export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.coreServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};

export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.coreServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};

export const resetPassword = async (
  serviceConfig: ServiceConfig,
  userId: string
) => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/user/password/${userId}/entities/{entity}`,
    serviceConfig,
    {
      method: "POST",
    }
  );
};

export const enableEntityUser = async (
  serviceConfig: ServiceConfig,
  entityId: string,
  userId: string
) => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/user/entities/${entityId}/users/${userId}/enable`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export const disableEntityUser = async (
  serviceConfig: ServiceConfig,
  entityId: string,
  userId: string
) => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/user/entities/${entityId}/users/${userId}/disable`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export const userSalutationValues = ["Ms.", "Mrs.", "Mr."] as const;
export type UserSalutation = typeof userSalutationValues[number];
export const updateMyProfile = async (
  user: User,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<User>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.displayName,
        primaryEmail: user.primaryEmail,
        mobileNumber: user.mobileNumber,
        title: user.title,
        salutation: user.salutation,
        officeNumber: user.officeNumber,
        avatar: user.avatar?.assetId ? user.avatar : null,
      }),
    }
  );
};

export const updateMyPassword = async (
  newPassword: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/user/password/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        secret: newPassword,
      }),
    }
  );
};

export const patchUserInfo = async (
  serviceConfig: ServiceConfig,
  userId: string,
  editedUser: Partial<PatchUser>
) => {
  return apiRequest<any>(
    `${serviceConfig.coreServiceApiBaseUrl}/user/${userId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editedUser),
    }
  );
};
