import React, { useState } from "react";

import { PlusCircleFilled } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";

import CreateDataSetModal from "./CreateDataSetModal";

interface CreateDataSetButtonProps {
  spectrumId: string;
  onDataSetCreated: () => void;
}

const CreateDataSetButton: React.FC<CreateDataSetButtonProps> = ({
  spectrumId,
  onDataSetCreated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton
        icon={<PlusCircleFilled />}
        onClick={handleClick}
        type="text"
        noDefaultLeftMargin
      >
        Add Attribute
      </NxpButton>
      {modalVisible && (
        <CreateDataSetModal
          visible={modalVisible}
          spectrumId={spectrumId}
          onDataSetCreated={onDataSetCreated}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default CreateDataSetButton;
