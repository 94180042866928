import React, { useState } from "react";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import CreateSpectrumModal from "./CreateSpectrumModal";

interface CreateSpectrumButtonProps {
  pdsId?: string;
  onSpectrumCreated: () => void;
}

const CreateSpectrumButton: React.FC<CreateSpectrumButtonProps> = ({
  pdsId,
  onSpectrumCreated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={handleClick}>Add Group</NxpButton>
      <CreateSpectrumModal
        visible={modalVisible}
        pdsId={pdsId}
        onSpectrumCreated={onSpectrumCreated}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default CreateSpectrumButton;
