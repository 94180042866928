// written in common js to support usage in both craco.config.js and appTheme.ts
const baseFontSize = 16;

function pxToRem(px) {
  var x = baseFontSize,
    rem = (1 / x) * px + "rem";
  return rem;
}
module.exports = {
  spacing: (...multipliers) =>
    multipliers.map((item) => `${item * 8}px`).join(" "),
  pxToRem: (px) => pxToRem(px),
  palette: {
    text: "rgb(62, 63, 66)",
    textInverted: "rgb(255,255,255)",
    textLabel: "rgb(138,153,153)",
    textLink: "rgb(0, 87, 148)",

    background: "rgb(250, 250, 252)",
    backgroundLight: "rgb(255,255,255)",
    backgroundDark: "rgb(243, 245, 245)",
    backgroundDarker: "rgb(138, 153, 153)",
    backgroundPrimaryLight: "rgba(2, 74, 148, 0.06)",

    border: "rgb(188, 203, 203)",
    borderLight: "rgb(234, 237, 243)",

    primary: "rgb(0, 87, 148)",
    primaryLight: "rgb(30, 117, 178)",
    primaryDark: "rgb(0, 57, 118)",

    warningLight: "rgb(255,95,95)",
    warning: "rgb(255,65,65)",
    warningDark: "rgb(220,35,35)",

    dangerLight: "rgb(255,95,95)",
    danger: "rgb(255,65,65)",
    dangerDark: "rgb(220,35,35)",

    success: "rgb(60,160,71)",
    successLight: "rgb(90,190,101)",
    successDark: "rgb(30,130,41)",

    dim: "rgb(168,183,183)",
    dimLight: "rgb(243,245,245)",
    dimDark: "rgb(138,153,153)",

    marker: "rgb(255,255,105)",
    markerLight: "rgb(255,255,175)",
    markerDark: "rgb(230,230,30)",
  },
  fontSize: {
    smaller: "0.7rem",
    small: "0.85rem",
    main: baseFontSize,
    h1: "1.6rem",
    h2: "1.25rem",
    h3: "1.15rem",
    h4: "1.1rem",
    h5: "1.05rem",
    h6: "1rem",
  },
  fontWeight: {
    bold: 500,
    bolder: 700,
  },
  borderRadius: {
    small: 2,
    main: 4,
    large: 8,
  },
  modalWidth: {
    smaller: 380,
    small: 576,
    main: 768,
    large: 992,
  },
  transition: {
    main: "0.25s ease-in-out",
    fast: "0.15s ease-in-out",
    faster: "0.1s ease-in-out",
  },
};
