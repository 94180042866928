import { LangLabel } from "../i18n/LangLabelEnum";
import { apiRequest, ServiceConfig } from "../utils/backend";

export interface DictionaryType {
  id: string;
  name: string;
  description: string;
  dataType: DictionaryDataType;
  application: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  createdById: string;
  lastUpdatedById: string;
}

export interface Dictionary {
  id: string;
  name: string;
  entityType: string;
  entityId: string;
  members: DictionaryItem[];
  dictionaryTypeId: string;
}

export interface DictionaryItem {
  key: string;
  labels: { langKey: LangLabel; value: string }[];
  value: string | number;
  members?: DictionaryItem[];
  isEngaged: boolean;
  itemDataId?: string;
  id?: string;
}

export enum DictionaryNature {
  APPLICATION = "Application",
  ENTITY_APPLICATION = "Entity-Application",
  ENTITY = "Entity",
  UNKNOWN = "Unknown",
}

export enum DictionaryDataType {
  TEXT = "Text",
  NUMBER = "Number",
  CONTACT = "Contact",
  GEOLOCATION = "Geolocation",
  BLOB = "BLOB",
}

export interface CreateDictionaryType {
  name: string;
  slug: string;
  description?: string;
  nature: DictionaryNature;
  application: string;
  dataType: string;
}

export type EditDictionaryType = Partial<
  Omit<CreateDictionaryType, "name" | "slug" | "nature" | "application">
>;

export interface CreateDictionaryData {
  name: string;
  entityType: "Root" | "Operation";
  entityId: string;
  dictionaryTypeId: string;
  members: DictionaryItem[];
  isInheritable: boolean;
}
export interface CreateDictionaryTypeItem {
  labels: DictionaryItem["labels"];
  dictionaryDataId: string;
  key: string;
  value: string;
  parentId: string | null;
  isEngaged: boolean;
}
export type EditDictionaryTypeItem = Pick<
  CreateDictionaryTypeItem,
  "labels" | "isEngaged" | "value" | "key"
>;

export type EditDictionaryData = Partial<
  Omit<
    CreateDictionaryData,
    "entityType" | "entityId" | "dictionaryTypeId" | "isInheritable"
  >
>;

export const getDictionaryTypes = async (serviceConfig: ServiceConfig) => {
  const data = await apiRequest<{ data: DictionaryType[] }>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-type`,
    serviceConfig
  );
  return data.data;
};

export const createDictionaryData = async (
  serviceConfig: ServiceConfig,
  addDictionaryData: CreateDictionaryData
) => {
  return await apiRequest<Dictionary>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-data/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addDictionaryData),
    }
  );
};

export const createDictionaryTypeItem = async (
  serviceConfig: ServiceConfig,
  addDictionaryData: CreateDictionaryTypeItem
) => {
  return await apiRequest<Dictionary>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-item/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addDictionaryData),
    }
  );
};
export const patchDictionaryTypeItem = async (
  serviceConfig: ServiceConfig,
  editDictionaryData: EditDictionaryTypeItem,
  editDictionaryTypeItemId: string
) => {
  return await apiRequest<Dictionary>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-item/${serviceConfig.entityId}/${editDictionaryTypeItemId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editDictionaryData),
    }
  );
};

export const deleteDictionaryTypeItem = async (
  serviceConfig: ServiceConfig,
  editDictionaryTypeItemId: string
) => {
  return await apiRequest<Dictionary>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-item/${serviceConfig.entityId}/${editDictionaryTypeItemId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const patchDictionaryData = async (
  serviceConfig: ServiceConfig,
  dictionaryId: string,
  editDictionaryData: EditDictionaryData
) => {
  return await apiRequest<Dictionary>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-data/${serviceConfig.entityId}/${dictionaryId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editDictionaryData),
    }
  );
};

export const createDictionaryType = async (
  serviceConfig: ServiceConfig,
  addDictionaryType: CreateDictionaryType
) => {
  return await apiRequest<DictionaryType>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-type`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addDictionaryType),
    }
  );
};

export const patchDictionaryType = async (
  serviceConfig: ServiceConfig,
  dictionaryTypeId: string,
  editDictionaryType: EditDictionaryType
) => {
  return await apiRequest<DictionaryType>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-type/${dictionaryTypeId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editDictionaryType),
    }
  );
};

export const deleteDictionaryType = async (
  serviceConfig: ServiceConfig,
  dictionaryTypeId: string
) => {
  return await apiRequest<DictionaryType>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-type/${dictionaryTypeId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const getDictionaryList = async (
  serviceConfig: ServiceConfig,
  dictionaryKey?: string
) => {
  const data = await apiRequest<{ data: Dictionary[] }>(
    `${serviceConfig.coreServiceApiBaseUrl}/dictionary-data/pagination` +
      (dictionaryKey ? `?dictionaryTypeName=${dictionaryKey}` : "") +
      `?entityId=${serviceConfig?.entityId}`,
    serviceConfig
  );
  return data.data;
};
