import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { NxpAuthSpinner, NxpLayoutGlobal } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import notify from "../../../utils/notify";
import AppChangePromptContextProvider from "../../AppChangePrompt/AppChangePromptProvider";
import AppLayout from "../AppLayout/AppLayout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedPageProps {
  requireActiveEntity?: boolean;
  component: ReactNode;
}

const AppProtectedPage: React.FC<AppProtectedPageProps> = ({
  requireActiveEntity,
  component,
}) => {
  const { keycloak } = useKeycloak();
  const appContext = useAppContext();
  useRouteAppContextUpdate();

  if (keycloak?.authenticated) {
    if (
      !requireActiveEntity ||
      (appContext.activeEntity &&
        appContext.accessPermissionList &&
        appContext.accessPermissionList.some(
          (permission) => permission === "@entity/entity:view"
        ))
    ) {
      return (
        // AppChangePromptContextProvider sits here in order to use useLocation() which only works inside <Router /> of react-router-dom
        <AppChangePromptContextProvider>
          <AppLayout>{component}</AppLayout>
        </AppChangePromptContextProvider>
      );
    } else if (
      appContext.activeEntity === undefined ||
      appContext.accessPermissionList === undefined
    ) {
      return (
        <NxpLayoutGlobal>
          <NxpAuthSpinner />
        </NxpLayoutGlobal>
      );
    } else {
      return <Navigate to="/entities" />;
    }
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(
        "Cannot connect to authentication server. Please try refreshing your browser or contact support."
      );
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedPage;
