import {
  NxpButton,
  NxpFormTableColumnProps,
  NxpFullFormTable,
  NxpModal,
  NxpModalFooter,
  notify,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import React, { useCallback, useEffect, useState } from "react";
import { Currency, createCurrency } from "../../../services/currency";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import useAppContext from "../../../hooks/useAppContext";
import { useTranslation } from "react-i18next";

type FormStateItem = {
  code: string;
  currency: string;
  rate: number;
  itemState: {
    new: boolean;
    modified: boolean;
    deleted: boolean;
  };
  itemUuid: string;
};

const initFormState = [
  {
    code: "",
    currency: "",
    rate: 0,
    itemState: {
      new: true,
      modified: false,
      deleted: false,
    },
    itemUuid: uuidv4(),
  },
];

type AddCurrencyModalProps = {
  addCurrencyModalVisible: boolean;
  onModalClose: () => void;
  addCurrencyDropDown: Currency[];
  onRefresh: () => void;
};

const AddCurrencyModal: React.FC<AddCurrencyModalProps> = ({
  addCurrencyModalVisible,
  onModalClose,
  addCurrencyDropDown,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const formSchema = yup.array(
    yup.object().shape({
      code: yup
        .string()
        .required(t("CurrencyExchangePage.addCurrency.column.code.error")),
      currency: yup.string(),
      rate: yup
        .number()
        .required(t("CurrencyExchangePage.addCurrency.column.rate.error"))
        .typeError(
          t("CurrencyExchangePage.addCurrency.column.rate.type.error")
        ),
    })
  );
  const appContext = useAppContext();
  const { errorHandler, serviceConfig } = appContext;
  const [formState, setFormState] = useState<FormStateItem[]>(initFormState);
  const [loading, setLoading] = useState<boolean>(false);

  const selectedCodes = formState.map((item) => item.code);
  const addCurrencyCodeDropDown = addCurrencyDropDown
    .filter((item) => !selectedCodes.includes(item.code))
    .map((currency) => {
      return { label: currency.code, value: currency.code };
    });

  const columns: NxpFormTableColumnProps<any>[] = [
    {
      title: t("CurrencyExchangePage.addCurrency.column.code"),
      dataIndex: "code",
      width: 150,
      formItemProps: {
        controlType: "select",
        controlProps: {
          options: addCurrencyCodeDropDown,
        },
      },
    },
    {
      title: t("CurrencyExchangePage.addCurrency.column.unit"),
      dataIndex: "currency",
      width: 150,
      formItemProps: {
        controlType: "input",
        controlProps: {
          disabled: true,
        },
      },
    },
    {
      title: t("CurrencyExchangePage.addCurrency.column.rate"),
      dataIndex: "rate",
      width: 150,
      formItemProps: {
        controlType: "input",
      },
    },
  ];

  const handleInitFormState = useCallback(async () => {
    setFormState(initFormState);
  }, []);

  useEffect(() => {
    handleInitFormState();
  }, [handleInitFormState]);

  const handleRowAdd = () => {
    const newRow = {
      code: "",
      currency: "",
      rate: 0,
      itemState: {
        new: true,
        modified: false,
        deleted: false,
      },
      itemUuid: uuidv4(),
    };
    setFormState([...formState, newRow]);
  };

  const handleSaveValidated = async () => {
    setLoading(true);
    try {
      const apiPromise = formState.map((curency) => {
        return createCurrency(
          serviceConfig,
          Number(curency.rate),
          curency.code
        );
      });
      await Promise.all(apiPromise);
      onModalClose();
      notify.actionCompleted();
      onRefresh();
    } catch (err) {
      errorHandler(err, "create currencies");
    } finally {
      setLoading(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<any>(
    formState,
    formSchema,
    handleSaveValidated
  );
  const convertCodetoDescribe = useCallback(
    (code: string | unknown) => {
      return addCurrencyDropDown.find((currency) => currency.code === code)
        ?.description;
    },
    [addCurrencyDropDown]
  );

  const handleFormStateChange = useCallback(
    (fieldName: string | number | symbol, value: any, itemUuid: string) => {
      const currencyDscribe = convertCodetoDescribe(value);
      setFormState((prevState) => {
        if (fieldName === "code" && currencyDscribe) {
          return prevState.map((item) => {
            return item.itemUuid === itemUuid
              ? {
                  ...item,
                  currency: currencyDscribe,
                  [fieldName]: value,
                  itemState: {
                    ...item.itemState,
                    modified: true,
                  },
                }
              : item;
          });
        } else {
          return prevState.map((item) => {
            return item.itemUuid === itemUuid
              ? {
                  ...item,
                  [fieldName]: value,
                  itemState: {
                    ...item.itemState,
                    modified: true,
                  },
                }
              : item;
          });
        }
      });
    },
    [convertCodetoDescribe]
  );

  const handleRowToggleDelete = useCallback(
    (itemUuid: string) => {
      const deleteItem = formState.find((item) => item.itemUuid === itemUuid);
      if (deleteItem) {
        if (deleteItem.itemState.new) {
          setFormState((prevState) =>
            prevState.filter((item) => item.itemUuid !== itemUuid)
          );
          // clear validation if deleted item has any
          if (
            validationError[
              formState?.findIndex((item) => item.itemUuid === itemUuid)
            ]
          ) {
            clearError();
          }
        } else {
          setFormState((prevState) =>
            prevState.map((item) =>
              item.itemUuid === itemUuid
                ? {
                    ...item,
                    itemState: {
                      ...item.itemState,
                      deleted: !item.itemState.deleted,
                    },
                  }
                : item
            )
          );
        }
      }
    },
    [clearError, formState, validationError]
  );
  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleInitFormState();
      clearError();
    },
    [clearError, handleInitFormState]
  );

  const handleSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  return (
    <NxpModal
      title="Add Currency"
      visible={addCurrencyModalVisible}
      centered
      onCancel={() => {
        setFormState(initFormState);
        onModalClose();
      }}
    >
      <NxpFullFormTable
        rowKey="itemUuid"
        formState={formState}
        columns={columns}
        validationError={validationError as any}
        onAddClick={handleRowAdd}
        onFormStateChange={handleFormStateChange}
        onRowToggleDelete={handleRowToggleDelete}
        disabled={false}
      />
      <NxpModalFooter>
        <NxpButton onClick={handleReset} loading={loading} type="text">
          {t("CurrencyExchangePage.button.reset")}
        </NxpButton>
        <NxpButton onClick={handleSave} loading={loading}>
          {t("app.common.Save")}
        </NxpButton>
      </NxpModalFooter>
    </NxpModal>
  );
};

export default AddCurrencyModal;
