import { useContext, useState } from "react";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import AppChangePromptButton from "../../../components/AppChangePrompt/AppChangePromptButton";
import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import { PdsForm, pdsIconTypeValues } from "../../../services/pds";
import PdsTemplateIcon from "../PdsTemplateIcon";

const useStyles = createUseStyles((theme) => ({
  SpectrumModalForm: {},
}));

interface SpectrumModalFormProps {
  initForm: PdsForm;
  loading: boolean;

  onSave: (form: PdsForm) => void;
  onCancel: () => void;
}

const SpectrumModalForm: React.FC<SpectrumModalFormProps> = ({
  initForm,
  loading,
  onSave,
  onCancel,
}) => {
  const [form, setForm] = useState<PdsForm>(initForm);

  const formSchema = yup.object({
    name: yup.string().nullable().required("Name required."),
    icon: yup.string().nullable().required("Icon required."),
  });

  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [validationError, , , saveWithValidate] = useYupValidate<PdsForm>(
    form,
    formSchema,
    () => onSave(form)
  );

  const handleFormGridStateChange = (
    fieldName: keyof PdsForm,
    value: unknown
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    onChangePromptUpdate(true);
  };

  const formItems: NxpFormGridItemProps<PdsForm>[] = [
    {
      controlType: "input",
      label: "Name",
      itemFieldName: "name",
      required: true,
      span: 12,
    },
    {
      controlType: "custom",
      startOnNewRow: true,
      customContent: pdsIconTypeValues.map((iconTypeItem) => (
        <PdsTemplateIcon
          key={iconTypeItem}
          iconType={iconTypeItem}
          selected={form.icon === iconTypeItem}
          onClick={() => handleFormGridStateChange("icon", iconTypeItem)}
        />
      )),
      error: validationError.icon,
      label: "Select Icon",
      itemFieldName: "icon",
      span: 24,
    },
  ];

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const classes = useStyles();

  return (
    <div className={classes.SpectrumModalForm}>
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <AppChangePromptButton
          disabled={loading}
          type="text"
          onClick={onCancel}
        >
          Cancel
        </AppChangePromptButton>
        <NxpButton loading={loading} onClick={handleSave}>
          Save
        </NxpButton>
      </NxpModalFooter>
    </div>
  );
};

export default SpectrumModalForm;
