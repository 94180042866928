import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { DictionaryDataType, DictionaryItem } from "../../services/dictionary";
import AddDictionaryItemForm from "./AddDictionaryItemForm";
import { DictionarySelectItem } from "./DictionaryPageContainer";

interface AddDictionaryItemModalProps {
  showModal: boolean;
  loading: boolean;
  isEdit: boolean;
  editData?: AddDictionaryItemFormData & {
    labelCN: string;
    labelEN: string;
    labelES: string;
    labelDE: string;
  };
  members: string[];
  dataType: DictionaryDataType;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (form: AddDictionaryItemFormData) => void;
  onDelete: (item?: DictionarySelectItem) => void;
}

export interface AddDictionaryItemFormData {
  key: string;
  labels: DictionaryItem["labels"];
  value: string | number;
  item?: DictionarySelectItem;
  label: string;
}

const emptyForm: AddDictionaryItemFormData & {
  labelCN: string;
  labelEN: string;
  labelES: string;
  labelDE: string;
} = {
  key: "",
  labels: [],
  value: "",
  item: undefined,
  labelCN: "",
  labelEN: "",
  labelES: "",
  labelDE: "",
  label: "",
};

const AddDictionaryItemModal: React.FC<AddDictionaryItemModalProps> = ({
  showModal,
  loading,
  isEdit,
  editData,
  members,
  dataType,
  setShowModal,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { hasRight } = useAppContext();
  const [form, setForm] = useState<
    AddDictionaryItemFormData & {
      labelCN: string;
      labelEN: string;
      labelES: string;
      labelDE: string;
    }
  >(emptyForm);

  const formSchema = useMemo(() => {
    return yup.object().shape(
      {
        key: yup
          .string()
          .required(t("Dictionary.KeyIsRequired"))
          .notOneOf(members, t("Dictionary.DuplicatedKey")),
        value:
          dataType === DictionaryDataType.TEXT
            ? yup.string().required(t("Dictionary.ValueIsRequired"))
            : yup.number().required(t("Dictionary.ValueIsRequired")),
        labelCN: yup.string().when(["labelEN", "labelES", "labelDE"], {
          is: (labelEN: any, labelES: any, labelDE: any) =>
            labelEN || labelES || labelDE,
          then: yup.string(),
          otherwise: yup.string().required(`Chinese label is required`),
        } as any),
        labelEN: yup.string().required(`English label is required`),
        labelES: yup.string().when(["labelCN", "labelEN", "labelDE"], {
          is: (labelCN: any, labelEN: any, labelDE: any) =>
            labelCN || labelEN || labelDE,
          then: yup.string(),
          otherwise: yup.string().required(`Spanish label is required`),
        } as any),
        labelDE: yup.string().when(["labelCN", "labelEN", "labelES"], {
          is: (labelCN: any, labelEN: any, labelES: any) =>
            labelCN || labelEN || labelES,
          then: yup.string(),
          otherwise: yup.string().required(`German label is required`),
        } as any),
      },
      [
        ["labelCN", "labelEN"],
        ["labelCN", "labelES"],
        ["labelCN", "labelDE"],
        ["labelEN", "labelES"],
        ["labelEN", "labelDE"],
        ["labelES", "labelDE"],
      ]
    );
  }, [members, dataType, t]);

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<AddDictionaryItemFormData>(form, formSchema, () =>
      onSubmit(form)
    );
  useEffect(() => {
    if (isEdit) {
      setForm(editData!);
    } else {
      setForm(emptyForm);
    }
    clearError();
  }, [isEdit, editData, showModal, clearError]);

  const handleFormGridStateChange = useCallback(
    (fieldName: keyof AddDictionaryItemFormData, value: any) => {
      const data: Partial<AddDictionaryItemFormData> = {};
      data[fieldName] = value;
      setForm((prevForm) => ({ ...prevForm, ...data }));
    },
    []
  );

  return (
    <NxpModal
      title={
        isEdit
          ? t("app.common.EditDictionaryItem")
          : t("Dictionary.AddDictionaryItem")
      }
      visible={showModal}
      onCancel={() => setShowModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="text"
            onClick={() => setShowModal(false)}
            loading={loading}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          {isEdit && hasRight("@dictionary/dictionary:delete") && (
            <NxpButton
              type="text"
              danger={true}
              onClick={() => onDelete(form.item)}
              loading={loading}
            >
              {t("app.common.Delete")}
            </NxpButton>
          )}
          <NxpButton
            onClick={() => saveWithValidate(undefined)}
            loading={loading}
          >
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <AddDictionaryItemForm
        form={form}
        validationError={validationError}
        dataType={dataType}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDictionaryItemModal;
