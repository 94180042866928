import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";

import { NxpHeader, NxpSpin } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import eventManagerAppIconUrl from "../../images/app/em.svg";
import fotolioAppIconUrl from "../../images/app/fotolio.svg";
import ierAppIconUrl from "../../images/app/ier.svg";
import inspectionAppIconUrl from "../../images/app/inspection.svg";
import ndmAppIconUrl from "../../images/app/ndm.png";
import powerflowAppIconUrl from "../../images/app/powerflow.svg";
import punchlistAppIconUrl from "../../images/app/punchlist.svg";
import qualityAlertAppIconUrl from "../../images/app/quality_alert.svg";
import qualityStandardAppIconUrl from "../../images/app/quality_standard.svg";
import siteDiaryAppIconUrl from "../../images/app/site_diary.svg";
import {
  getMyApps,
  getPowerflowRegister,
  PowerFlowRegister,
  WorkspaceAccess,
  WorkspaceKey,
} from "../../services/accessControl";

const useStyles = createUseStyles((theme) => ({
  workspacePage: {
    "& p": {
      textTransform: "uppercase",
      color: theme.palette.textLabel,
      fontSize: theme.fontSize.small,
      fontWeight: theme.fontWeight.bold,
      marginBottom: theme.spacing(2),
    },
    "& a": {
      marginRight: theme.spacing(5),
      display: "inline-block",
      verticalAlign: "top",
    },
    "& img": {
      width: 152,
      height: 152,
    },
    "& span": {
      display: "block",
      color: theme.palette.text,
      margin: theme.spacing(1, 0, 2.5),
      fontSize: theme.fontSize.small,
      maxWidth: 152,
    },
  },
  powerflowRegisterTitle: {
    backgroundColor: "rgb(134, 56, 133)",
    display: "flex",
    width: "100px",
    height: "100px",
    boxSizing: "border-box",
    position: "absolute",
    color: "#fff",
    padding: "4px 2px 4px 2px",
    alignItems: "center",
    marginTop: "-130px",
    marginLeft: "27px",
  },
}));

export const workspace: {
  key: WorkspaceKey;
  name: string;
}[] = [
  {
    key: "inspection",
    name: "Inspection",
  },
  {
    key: "ier",
    name: "IER",
  },
  {
    key: "site-diary",
    name: "Site Diary",
  },
  {
    key: "qa",
    name: "Quality Alert",
  },
  {
    key: "quality-standard",
    name: "Quality Standard",
  },
  {
    key: "fotolio",
    name: "Fotolio",
  },
  {
    key: "punchlist",
    name: "Punchlist",
  },
  {
    key: "eventmanager",
    name: "Event Manager",
  },
  {
    key: "powerflow",
    name: "Powerflow",
  },
];

interface WorkspacePageProps {}

const WorkspacePage: React.FC<WorkspacePageProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const appContext = useAppContext();
  const { activeEntity, serviceConfig, errorHandler } = appContext;
  const navigate = useNavigate();

  const [workspaceAccess, setWorkspaceAccess] = useState<WorkspaceAccess>();

  const workspaceWithUrl: {
    key: WorkspaceKey;
    name: string;
    icon: string;
    url: string;
  }[] = [
    {
      key: "inspection",
      name: "Inspection",
      icon: inspectionAppIconUrl,
      url: serviceConfig.inspectionAppUrl.replace(
        ":entityId",
        activeEntity!.id
      ),
    },
    {
      key: "ier",
      name: "IR",
      icon: ierAppIconUrl,
      url: serviceConfig.ierAppUrl.replace(":entityId", activeEntity!.id),
    },
    {
      key: "site-diary",
      name: "Site Diary",
      icon: siteDiaryAppIconUrl,
      url: serviceConfig.siteDiaryAppUrl.replace(":entityId", activeEntity!.id),
    },
    {
      key: "qa",
      name: "Quality Alert",
      icon: qualityAlertAppIconUrl,
      url: serviceConfig.qualityAlertAppUrl.replace(
        ":entityId",
        activeEntity!.id
      ),
    },
    {
      key: "quality-standard",
      name: "Quality Standard",
      icon: qualityStandardAppIconUrl,
      url: serviceConfig.qualityStandardAppUrl.replace(
        ":entityId",
        activeEntity!.id
      ),
    },
    {
      key: "fotolio",
      name: "Fotolio",
      icon: fotolioAppIconUrl,
      url: serviceConfig.fotolioAppUrl.replace(":entityId", activeEntity!.id),
    },
    {
      key: "punchlist",
      name: "Punchlist",
      icon: punchlistAppIconUrl,
      url: serviceConfig.punchlistAppUrl.replace(":entityId", activeEntity!.id),
    },
    {
      key: "eventmanager",
      name: "Event Manager",
      icon: eventManagerAppIconUrl,
      url: serviceConfig.eventManagerAppUrl.replace(
        ":entityId",
        activeEntity!.id
      ),
    },
    {
      key: "powerflow",
      name: "Powerflow",
      icon: powerflowAppIconUrl,
      url: serviceConfig.powerflowAppUrl.replace(":entityId", activeEntity!.id),
    },
    {
      key: "dms",
      name: "Nexplore Document Manager",
      icon: ndmAppIconUrl,
      url: serviceConfig.dmsAppUrl.replace(":entityId", activeEntity!.id),
    },
  ];

  const fetchWorkspace = useCallback(async () => {
    try {
      setWorkspaceAccess(await getMyApps(serviceConfig, activeEntity!.id));
    } catch (ex) {
      errorHandler(ex, "fetch workspace");
    }
  }, [activeEntity, serviceConfig, errorHandler]);

  const [powerflowRegisters, setPowerflowRegisters] = useState<
    PowerFlowRegister[]
  >([]);
  const fetchPowerFlowRegister = useCallback(async () => {
    try {
      const constPowerflowRegisters = await getPowerflowRegister(serviceConfig);
      const workplaceRegister = constPowerflowRegisters.filter((register) => {
        return register.register.flag_workspace === true;
      });
      setPowerflowRegisters(workplaceRegister);
    } catch (ex) {
      // errorHandler(ex, "fetch PowerFlow Registers");
    }
  }, [serviceConfig]);

  useEffect(() => {
    fetchWorkspace();
    fetchPowerFlowRegister();
  }, [fetchPowerFlowRegister, fetchWorkspace]);

  return (
    <>
      <NxpHeader
        titleContent={t("app.layout.Workspace")}
        onBackClick={() => navigate(-1)}
      />
      <div className={classes.workspacePage}>
        {workspaceAccess && powerflowRegisters ? (
          <>
            <p>{`${activeEntity?.name} - ${
              workspaceWithUrl.filter((app) =>
                Object.keys(workspaceAccess).includes(app.key)
              ).length
            } ${t("Workspace.Apps")}`}</p>
            {workspaceWithUrl
              .filter((app) => Object.keys(workspaceAccess).includes(app.key))
              .map((app) => (
                <a
                  title={app.name}
                  href={app.url}
                  target="_blank"
                  rel="noreferrer"
                  key={app.key}
                >
                  <img src={app.icon} alt={app.key} />
                  <span>{app.name}</span>
                </a>
              ))}
            {powerflowRegisters?.map((register: PowerFlowRegister) => (
              <a
                title={register.register.register_name}
                href={`${serviceConfig.powerflowAppUrl.replace(
                  ":entityId",
                  activeEntity!.id
                )}/${register.register.id}`}
                target="_blank"
                rel="noreferrer"
                key={register.register.register_name}
              >
                <img src={powerflowAppIconUrl} alt={"powerflow"} />
                <div className={classes.powerflowRegisterTitle}>
                  {register.register.register_name}
                </div>
                <span>{t("app.layout.Workspace.powerflow.Register")}</span>
              </a>
            ))}
          </>
        ) : (
          <NxpSpin size="large" />
        )}
      </div>
    </>
  );
};

export default WorkspacePage;
