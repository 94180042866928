import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import {
  DateFormatPattern,
  formatDate,
  NxpButton,
  NxpFormTableColumnProps,
  NxpFullFormTable,
  NxpPanel,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import { LangLabel } from "../../../i18n/LangLabelEnum";
import { DEFAULT_LANGUAGE_KEY, DEFAULT_LANGUAGES } from "../../../utils/const";
import { DataSetDetailsEntry, DataSetDetailsForm } from "./DataSetDetails";
import DataSetEntriesCopyExcel from "./DataSetEntriesCopyExcel";

const useStyles = createUseStyles((theme) => ({
  dataSetEntries: {},
}));

interface DataSetEntriesProps {
  dataEntries: DataSetDetailsEntry[];
  validationError: ValidationResult<DataSetDetailsForm>;
  saveInProgress: boolean;
  onFormChange: (
    handler: (prevState: DataSetDetailsForm) => DataSetDetailsForm
  ) => void;
  clearError: () => void;
  form: DataSetDetailsForm;
}

const DataSetEntries: React.FC<DataSetEntriesProps> = ({
  dataEntries,
  validationError,
  saveInProgress,
  onFormChange,
  clearError,
  form,
}) => {
  const { t } = useTranslation();

  const [excelCopying, setExcelCopying] = useState(false);
  const [excelExporting, setExcelExporting] = useState(false);
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const columns: NxpFormTableColumnProps<DataSetDetailsEntry>[] = [
    {
      title: "code",
      dataIndex: "code",
      width: 150,
      formItemProps: {
        controlType: "input",
      },
    },
    ...DEFAULT_LANGUAGES.map(
      (lang) =>
        ({
          title: t(`app.common.Language.${lang}`),
          dataIndex: `displayName_${lang}`,
          width: 150,
          formItemProps: {
            controlType: "input",
          },
        } as NxpFormTableColumnProps<DataSetDetailsEntry>)
    ),
  ];

  const handleRowAdd = useCallback(() => {
    onFormChange((prevState) => ({
      ...prevState,
      dataEntries: [
        ...prevState.dataEntries,
        {
          name: "",
          code: "",
          itemUuid: uuidv4(),
          displayName: {} as Record<LangLabel, string>,
          itemState: {
            new: true,
            deleted: false,
            modified: false,
          },
        },
      ],
    }));
    onChangePromptUpdate(true);
  }, [onChangePromptUpdate, onFormChange]);

  const handleRowToggleDelete = useCallback(
    (uuid: string) => {
      const deleteItem = dataEntries.find((entry) => entry.itemUuid === uuid);
      if (deleteItem) {
        if (!deleteItem.id) {
          onFormChange((prevState) => ({
            ...prevState,
            dataEntries: [
              ...prevState.dataEntries.filter(
                (entry) => entry.itemUuid !== uuid
              ),
            ],
          }));
          // clear validation if deleted item has any
          if (
            validationError.dataEntries &&
            validationError.dataEntries[
              dataEntries?.findIndex((entry) => entry.itemUuid === uuid) || -1
            ]
          ) {
            clearError();
          }
        } else {
          onFormChange((prevState) => ({
            ...prevState,
            dataEntries: prevState.dataEntries.map((entry) =>
              entry.itemUuid === uuid
                ? {
                    ...entry,
                    itemState: {
                      ...entry.itemState,
                      deleted: !entry.itemState.deleted,
                    },
                  }
                : entry
            ),
          }));
        }
        onChangePromptUpdate(true);
      }
    },
    [
      clearError,
      dataEntries,
      onChangePromptUpdate,
      onFormChange,
      validationError.dataEntries,
    ]
  );

  const handleTableChange = useCallback(
    (fieldName: keyof DataSetDetailsEntry, value: unknown, uuid: string) => {
      onFormChange((prevState) => ({
        ...prevState,
        dataEntries: prevState.dataEntries.map((entry) => {
          if (entry.itemUuid === uuid) {
            if (fieldName.indexOf("displayName_") === 0) {
              const displayName = {
                ...entry.displayName,
                [fieldName.replace("displayName_", "")]: value,
              };
              return {
                ...entry,
                displayName: displayName,
                itemState: {
                  ...entry.itemState,
                  modified: true,
                },
                name: displayName[LangLabel.EN],
              } as DataSetDetailsEntry;
            } else {
              return {
                ...entry,
                [fieldName]: value,
                itemState: {
                  ...entry.itemState,
                  modified: true,
                },
              };
            }
          } else {
            return entry;
          }
        }),
      }));
      onChangePromptUpdate(true);
    },
    [onChangePromptUpdate, onFormChange]
  );

  const buildDisplayNameFields = (entry: DataSetDetailsEntry) => {
    const fields = {};
    Object.entries(entry.displayName).forEach(([lang, value]) =>
      Object.assign(fields, { [`displayName_${lang}`]: value })
    );
    return fields;
  };

  const buildValidationError = () => {
    const errors = validationError.dataEntries as any;
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (errors[key].displayName) {
          errors[key][`displayName_${DEFAULT_LANGUAGE_KEY}`] =
            errors[key].displayName;
        }
      });
    }
    return errors;
  };

  const handleExcelCopyClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setExcelCopying(true);
  };

  const handleExportExcel: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setExcelExporting(true);
  };

  const header = columns.map((column) => {
    const headers: any[] = [];
    headers.push(column.title);
    return column.title;
  });

  useEffect(() => {
    if (excelExporting) {
      const res: any[] = [];

      res.push(["code", form.code]);

      const displayName = DEFAULT_LANGUAGES.map((lang) => {
        return form.displayName[lang];
      });

      const description = DEFAULT_LANGUAGES.map((lang) => {
        return form.description[lang];
      });

      res.push(["", "", "ENGLISH", "ESPAÑOL", "DEUTSCH", "中文"]);
      res.push(["displayName", "", ...displayName]);
      res.push(["description", "", ...description]);
      res.push([""]);

      res.push(["", ...header]);
      dataEntries.forEach((entry) => {
        const rows: string[] = [];
        rows.push("", entry.code);

        DEFAULT_LANGUAGES.forEach((lang) => {
          rows.push(entry.displayName[lang]);
        });
        res.push(rows);
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(res);
      const today = formatDate(new Date(), DateFormatPattern.date);
      const code = form.code;
      const title = form.displayName[LangLabel.EN];

      XLSX.utils.book_append_sheet(wb, ws, `${title}`);
      XLSX.writeFile(wb, `${title}_${code}_${today}.xlsx`);
      setExcelExporting(false);
    }
  }, [
    excelExporting,
    dataEntries,
    header,
    form.code,
    form.displayName,
    form.description,
    form,
  ]);
  const classes = useStyles();

  return (
    <NxpPanel
      titleContent="Value Entries"
      className={classes.dataSetEntries}
      extra={
        <>
          <NxpButton
            onClick={handleExportExcel}
            type="text"
            icon={<UploadOutlined />}
          >
            EXPORT TO EXCEL
          </NxpButton>

          <NxpButton
            type="text"
            onClick={handleExcelCopyClick}
            icon={<CopyOutlined />}
          >
            Copy from Excel
          </NxpButton>
        </>
      }
    >
      <NxpFullFormTable
        scroll={{ y: 525 }}
        useBackgroundLight
        rowKey="itemUuid"
        formState={dataEntries.map((entry) => ({
          ...entry,
          ...buildDisplayNameFields(entry),
        }))}
        columns={columns}
        validationError={buildValidationError()}
        disabled={saveInProgress}
        onFormStateChange={handleTableChange}
        onRowToggleDelete={handleRowToggleDelete}
        onAddClick={handleRowAdd}
      />
      <DataSetEntriesCopyExcel
        modalVisible={excelCopying}
        dataEntries={dataEntries}
        onFormChange={onFormChange}
        onModalClose={() => setExcelCopying(false)}
      />
    </NxpPanel>
  );
};

export default DataSetEntries;
