import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { PlusCircleFilled } from "@ant-design/icons";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import {
  OrganisationType,
  OrganisationTypeTableData,
} from "../../services/organisationType";
import OrganisationTypeModel from "./OrganisationTypeModal";
import OrganisationTypesListingLayout from "./OrganisationTypesListingLayout";

const useStyles = createUseStyles(() => ({
  CompanyListingLayout: {},
}));

interface CompanyListingLayoutProps {
  selectedOrganisationType: OrganisationType;
  showOrganisationTypeModal: boolean;
  onClickViewDetails: (organisationType: OrganisationType) => void;
  onEditOrganisationType: (organisationType: OrganisationType) => void;
  onToggleOrganisationTypeStatus: (
    organisationType: OrganisationTypeTableData
  ) => void;
  setSelectedOrganisationType: React.Dispatch<
    React.SetStateAction<OrganisationType>
  >;
  setShowOrganisationTypeModal: React.Dispatch<React.SetStateAction<boolean>>;
  onFetchingOrganisationType: () => void;
  organisationTypes: OrganisationTypeTableData[];
}

const CompanyListingLayout: React.FC<CompanyListingLayoutProps> = ({
  selectedOrganisationType,
  showOrganisationTypeModal,
  onClickViewDetails,
  onToggleOrganisationTypeStatus,
  onEditOrganisationType,
  setSelectedOrganisationType,
  setShowOrganisationTypeModal,
  onFetchingOrganisationType,
  organisationTypes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasRight } = useAppContext();
  return (
    <div className={classes.CompanyListingLayout}>
      <NxpHeader
        titleContent={t("app.layout.OrganisationType")}
        onBackClick={() => navigate(-1)}
        actionContent={
          hasRight("@company/type:create") && (
            <NxpButton onClick={() => setShowOrganisationTypeModal(true)}>
              <PlusCircleFilled color="primary" />{" "}
              {t("OrganisationTye.AddOrganisationType")}
            </NxpButton>
          )
        }
      />
      <OrganisationTypesListingLayout
        data={organisationTypes}
        onClickEdit={(record: OrganisationTypeTableData) => {
          if (!record?.isDisabled) {
            onEditOrganisationType(record);
            setShowOrganisationTypeModal(true);
          }
        }}
        onClickEnable={onToggleOrganisationTypeStatus}
      />
      <OrganisationTypeModel
        editOrganisationType={selectedOrganisationType}
        show={showOrganisationTypeModal}
        onClose={() => {
          setSelectedOrganisationType({} as OrganisationType);
          setShowOrganisationTypeModal(false);
          onFetchingOrganisationType();
        }}
        onRefresh={onFetchingOrganisationType}
      ></OrganisationTypeModel>
    </div>
  );
};

export default CompanyListingLayout;
