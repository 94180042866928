import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";
import { DataNode } from "antd/lib/tree";

import useAppContext from "../../hooks/useAppContext";
import {
  AccessPermission,
  ApplicationPermissionSetData,
  createRoleAccessRight,
  EntityRoleAccessRight,
  getAllAccessRight,
  getMyRoleAccessRight,
  getRoleAccessRight,
} from "../../services/accessControl";
import { EntityRole, getEntityRoles } from "../../services/entity";
import AccessRightPageLayout from "./AccessRightPageLayout";

interface AccessRightPageContainerProps {}

const AccessRightPageContainer: React.FC<AccessRightPageContainerProps> =
  () => {
    const appContext = useAppContext();
    const { t } = useTranslation();
    const {
      activeEntity,
      activeEntityType,
      serviceConfig,
      accessPermissionList,
      errorHandler,
      onAppContextCacheItemUpdate,
      hasRight,
    } = appContext;
    const navigate = useNavigate();

    const [entityRoles, setEntityRoles] = useState<EntityRole[]>();
    const [selectedRole, setSelectedRole] = useState<EntityRole>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [userRights, setUserRights] = useState<EntityRoleAccessRight[]>([]);
    const [applicationPermissionSetData, setApplicationPermissionSetData] =
      useState<ApplicationPermissionSetData[]>([]);
    const [init, setInit] = useState<boolean>(true);

    const permissionRegex = /^@(\w+)\/(\w+):(\w+)/;

    const fetch = useCallback(async () => {
      try {
        if (!activeEntity || !activeEntityType) return;
        const results = await Promise.all([
          getEntityRoles(serviceConfig, activeEntity!.id, activeEntityType),
          getRoleAccessRight(serviceConfig, activeEntity!.id),
          getAllAccessRight(serviceConfig),
          getMyRoleAccessRight(
            serviceConfig,
            activeEntityType,
            activeEntity!.id
          ),
        ]);
        const entityRoles = results[0];
        setEntityRoles(entityRoles);
        const userRights = results[1];
        setUserRights(userRights);
        const applicationPermissionSetData = results[2];
        setApplicationPermissionSetData(applicationPermissionSetData);
        const accessPermissionList = results[3];
        onAppContextCacheItemUpdate(
          "accessPermissionList",
          accessPermissionList
        );
        setInit(false);
      } catch (err) {
        errorHandler(err, "fetch access right");
      }
    }, [
      activeEntity,
      activeEntityType,
      serviceConfig,
      errorHandler,
      onAppContextCacheItemUpdate,
    ]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    useEffect(() => {
      if (refresh) {
        fetch();
        setRefresh(false);
      }
    }, [fetch, refresh]);

    useEffect(() => {
      if (
        !init &&
        (!hasRight("@entity/entity:view") ||
          !hasRight("@access/permission:assign"))
      ) {
        onAppContextCacheItemUpdate("activeEntity", undefined);
        navigate("/entities");
      }
    }, [
      init,
      accessPermissionList,
      hasRight,
      onAppContextCacheItemUpdate,
      navigate,
    ]);

    useEffect(() => {
      const role = entityRoles ? entityRoles[0] : undefined;
      setSelectedRole(role);
    }, [entityRoles]);

    const handleRoleSelect = (key: string) => {
      if (!key) return;
      const selectedRole = entityRoles?.find((role) => role.roleCode === key);
      setSelectedRole(selectedRole);
    };

    const entityRolesTree: DataNode[] = useMemo(() => {
      const localRoles = entityRoles?.map((role) => {
        return {
          key: role.roleCode,
          title: role.roleName,
          isLeaf: true,
        } as DataNode;
      });
      const inheritedRoles: DataNode[] = [];
      return [
        {
          key: "localRoles",
          title: t("AccessRight.LocalRoles"),
          isLeaf: false,
          selectable: false,
          children: localRoles,
        },
        {
          key: "inheritedRoles",
          title: t("AccessRight.InheritedRoles"),
          isLeaf: false,
          selectable: false,
          children: inheritedRoles,
        },
      ] as DataNode[];
    }, [entityRoles, t]);

    const handleSubmit = async (scopes: string[]) => {
      try {
        setIsSubmitting(true);
        const permissions: AccessPermission[] = [];
        scopes.forEach((s) => {
          const arr = permissionRegex.exec(s);
          if (arr && arr.length > 3) {
            permissions.push({
              application: arr[1],
              resource: arr[2],
              action: arr[3],
            } as AccessPermission);
          }
        });
        const accessRightList: EntityRoleAccessRight[] = [
          {
            roleId: selectedRole!.id,
            permissions: permissions,
          },
        ];
        const result = await createRoleAccessRight(
          serviceConfig,
          activeEntity!.id,
          accessRightList
        );
        notify.actionCompleted();
        setRefresh(true);
        return result;
      } catch (err) {
        errorHandler(err, "update access right");
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <>
        <AccessRightPageLayout
          selectedRole={selectedRole}
          entityRolesTree={entityRolesTree}
          isSubmitting={isSubmitting}
          userRights={userRights}
          applicationPermissionSetData={applicationPermissionSetData}
          onRoleSelect={handleRoleSelect}
          onSubmit={handleSubmit}
        />
      </>
    );
  };

export default AccessRightPageContainer;
