import React, { useContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { CaretRightFilled } from "@ant-design/icons";
import { notify, NxpButton } from "@nexploretechnology/nxp-ui";
import { isEqual } from "lodash";

import AppChangePromptButton from "../../../../components/AppChangePrompt/AppChangePromptButton";
import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import AppChangePromptModal from "../../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../../hooks/useAppContext";
import {
  Pds,
  PdsDataSet,
  PdsReorderItem,
  PdsSpectrum,
  reorderPdsDataSets,
} from "../../../../services/pds";
import { DEFAULT_LANGUAGE_KEY } from "../../../../utils/const";
import ReorderPdsDragDropPlaceholder from "../../Spectrums/ReorderSpectrums/ReorderPdsDragDropPlaceholder";
import ReorderPdsItem from "../../Spectrums/ReorderSpectrums/ReorderPdsItem";

interface ReorderDataSetsModalProps {
  modalVisible: boolean;
  pds: Pds;
  spectrum: PdsSpectrum;
  onModalClose: () => void;
  onPdsRefresh: () => void;
}

const ReorderDataSetsModal: React.FC<ReorderDataSetsModalProps> = ({
  modalVisible,
  pds,
  spectrum,
  onModalClose,
  onPdsRefresh,
}) => {
  const appContext = useAppContext();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [editState, setEditState] = useState(
    pds.spectrums.find((s) => s.id === spectrum.id)?.dataSets || []
  );

  const [loading, setLoading] = useState(false);

  const getDataSetOrders = (dataSets: PdsDataSet[]) => {
    return dataSets
      .sort((a, b) => a.order - b.order)
      .map((s) => ({ dataSetId: s.id, order: s.order }));
  };

  const handleDrop = (dataSet: PdsReorderItem, newPosition: number) => {
    setEditState((prevState) => {
      const nextState = prevState.map((varDataSet) => {
        if (varDataSet.id === dataSet.id) {
          if (dataSet.order > newPosition) {
            return { ...varDataSet, order: newPosition };
          }
          if (dataSet.order < newPosition) {
            return { ...varDataSet, order: newPosition - 1 };
          }
        } else if (
          dataSet.order > newPosition &&
          varDataSet.order < dataSet.order &&
          varDataSet.order >= newPosition
        ) {
          return { ...varDataSet, order: varDataSet.order + 1 };
        } else if (
          dataSet.order < newPosition &&
          varDataSet.order > dataSet.order &&
          varDataSet.order < newPosition
        ) {
          return { ...varDataSet, order: varDataSet.order - 1 };
        }
        return varDataSet;
      });
      return nextState;
    });
    onChangePromptUpdate(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await reorderPdsDataSets(
        spectrum.id,
        { dataSetOrders: getDataSetOrders(editState) },
        appContext.serviceConfig
      );
      onPdsRefresh();
      notify.actionCompleted();
      onModalClose();
      onChangePromptUpdate(false);
    } catch (ex) {
      appContext.errorHandler(ex, `dataSet reorder`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppChangePromptModal
      width="small"
      title="Reorder PDS Attributes"
      visible={modalVisible}
      footer={
        <>
          <AppChangePromptButton
            type="default"
            disabled={loading}
            onClick={onModalClose}
          >
            Cancel
          </AppChangePromptButton>
          <NxpButton
            onClick={handleSave}
            loading={loading}
            disabled={isEqual(
              getDataSetOrders(editState),
              getDataSetOrders(spectrum.dataSets)
            )}
          >
            Save
          </NxpButton>
        </>
      }
      onCancel={onModalClose}
    >
      <DndProvider backend={HTML5Backend}>
        <p>
          <CaretRightFilled /> {spectrum.code} -{" "}
          {
            spectrum.translations?.find(
              (translation) => translation.langKey === DEFAULT_LANGUAGE_KEY
            )?.value
          }
        </p>
        {editState
          .sort((a, b) => a.order - b.order)
          .map((dataSet) => (
            <ReorderPdsItem
              key={dataSet.id}
              item={{
                id: dataSet.id,
                code: dataSet.code,
                order: dataSet.order,
                translations: dataSet.translations,
              }}
              onDrop={handleDrop}
            />
          ))}
        <ReorderPdsDragDropPlaceholder
          position={Math.max(...editState.map((s) => s.order)) + 1}
          onDrop={handleDrop}
        />
      </DndProvider>
    </AppChangePromptModal>
  );
};

export default ReorderDataSetsModal;
