import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpTableEditable,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { DictionarySelectItem } from "./DictionaryPageContainer";

interface DictionaryTableProps {
  loading: boolean;
  tableData: DictionarySelectItem[];
  onClickTableRowEdit: (item: DictionarySelectItem) => void;
}

const DictionaryTable: React.FC<DictionaryTableProps> = ({
  loading,
  tableData,
  onClickTableRowEdit,
}) => {
  const { t } = useTranslation();
  const { hasRight } = useAppContext();

  const columns = useMemo(
    () => [
      {
        title: t("Dictionary.Key"),
        dataIndex: "key",
        sorter: (a: DictionarySelectItem, b: DictionarySelectItem) =>
          sorterForString(a.key, b.key),
      },
      {
        title: t("Dictionary.Label"),
        dataIndex: "label",
        sorter: (a: DictionarySelectItem, b: DictionarySelectItem) =>
          sorterForString(a.label, b.label),
      },
      {
        title: t("Dictionary.Value"),
        dataIndex: "value",
        sorter: (a: DictionarySelectItem, b: DictionarySelectItem) => {
          const aValue = a.value;
          const bValue = b.value;
          if (typeof aValue === "string" && typeof bValue === "string") {
            return sorterForString(aValue, bValue);
          } else if (typeof aValue === "number" && typeof bValue === "number") {
            return sorterForNumber(aValue, bValue);
          }
        },
      },
    ],
    [t]
  );

  return (
    <NxpTableEditable
      rowKey={(item) => item.key}
      useDefaultHeight={true}
      pagination={false}
      columns={columns as any}
      dataSource={tableData}
      saveInProgress={loading}
      itemCompareKey="key"
      onRowEdit={onClickTableRowEdit}
      rowDisableEdit={(_) => !hasRight("@dictionary/dictionary:edit")}
    />
  );
};

export default DictionaryTable;
