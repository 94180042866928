import clsx from "clsx";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { createUseStyles } from "react-jss";

import { HolderOutlined } from "@ant-design/icons";

import { LangLabel } from "../../../../i18n/LangLabelEnum";
import {
  PdsReorderItem,
  PdsTranslationFieldNameEnum,
} from "../../../../services/pds";
import { DRAG_DROP_TYPE_PDS_ITEM } from "../../../../utils/const";

const useStyles = createUseStyles((theme) => ({
  reorderPdsItem: {
    padding: `0 0 2px 4px`,
    borderTop: `1px solid transparent`,
    margin: theme.spacing(0.5, 0, 1, 1.5),
    background: theme.palette.backgroundPrimaryLight,
    borderRadius: theme.borderRadius.main,
    transform: "translate3d(0,0,0)", // react-dnd preview fix, special thanks to - https://github.com/react-dnd/react-dnd/issues/832#issuecomment-442071628
    "& > .reorderSpectrum": {
      margin: theme.spacing(0.5, 0, 0, 1.5),
      overflow: "hidden",
    },
    "& > .anticon": {
      color: theme.palette.primary,
      marginLeft: theme.spacing(-0.5),
      cursor: "pointer",
    },
    "& > .anticon:first-child": {
      color: theme.palette.textLabel,
      margin: theme.spacing(0, 0.5, 0, 0),
      cursor: "move",
    },
    "&.isOverCurrent": {
      borderColor: theme.palette.primary,
    },
    "&.isDragging": {
      borderColor: "transparent",
      opacity: 0.5,
      /////////////////// //   "& div.isOverCurrent": {
      //     // no border highlight when hovering the children of the item itself
      //     // this rule affect ReorderGroupDragDropPlaceholder also
      //     borderColor: "transparent",
      //   },
    },
  },
}));

interface ReorderPdsItemProps {
  item: PdsReorderItem;
  onDrop: (item: PdsReorderItem, newPosition: number) => void;
}

const ReorderPdsItem: React.FC<ReorderPdsItemProps> = ({ item, onDrop }) => {
  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: DRAG_DROP_TYPE_PDS_ITEM,
      drop(dropItem: PdsReorderItem, monitor) {
        if (monitor.didDrop()) {
          return;
        }
        onDrop(dropItem, item.order);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [item, onDrop]
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DRAG_DROP_TYPE_PDS_ITEM,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        // handlerId: monitor.getHandlerId(),
      }),
    }),
    [item]
  );

  const classes = useStyles();

  return (
    <div ref={drop}>
      <div
        className={clsx(
          classes.reorderPdsItem,
          isOverCurrent && "isOverCurrent",
          isDragging && "isDragging"
        )}
        ref={preview}
        // eslint-disable-next-line jsx-a11y/aria-role
        role="reorder-group-item"
      >
        {<HolderOutlined title={`Drag Handle for ${item.code}`} ref={drag} />}
        {item.code} -{" "}
        {
          item.translations.find(
            (translation) =>
              translation.langKey === LangLabel.EN &&
              translation.fieldName === PdsTranslationFieldNameEnum.DISPLAY_NAME
          )?.value
        }
      </div>
    </div>
  );
};

export default ReorderPdsItem;
