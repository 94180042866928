import { useCallback, useLayoutEffect, useState } from "react";

import useAppContext from "./useAppContext";

export interface AsyncResult<T> {
  loading: boolean;
  data?: T;
  error?: Error;
}

export function useAsync<T>(
  initialRequest?: () => Promise<T>, // use it only when you want to trigger the async operation at initiation
  defaultResultData?: T,
  errorTaskDescription?: string,
  hideNotificaiton?: boolean
) {
  const { errorHandler } = useAppContext();

  useLayoutEffect(() => {
    if (initialRequest) {
      setAsyncRequest(initialRequest);
    }
    // initialRequest is not intended to be updated, so below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [asyncResult, setAsyncResult] = useState<AsyncResult<T>>({
    loading: false,
    data: defaultResultData,
    error: undefined,
  });

  const setAsyncRequest = useCallback(
    (asyncRequest: (() => Promise<T>) | undefined) => {
      if (asyncRequest !== undefined) {
        setAsyncResult((prevState) => ({
          loading: true,
          data: prevState.data,
          error: undefined,
        }));

        asyncRequest()
          .then((data) => {
            setAsyncResult({
              loading: false,
              data,
              error: undefined,
            });
          })
          .catch((error) => {
            errorHandler(
              error,
              errorTaskDescription || "async operation fail to resolve",
              undefined,
              hideNotificaiton
            );
            setAsyncResult({
              loading: false,
              data: defaultResultData,
              error,
            });
          });
      } else {
        setAsyncResult({
          loading: false,
          data: defaultResultData,
          error: undefined,
        });
      }
    },
    [defaultResultData, errorHandler, errorTaskDescription, hideNotificaiton]
  );

  return [asyncResult, setAsyncRequest, setAsyncResult] as const;
}
