import React from "react";
import { createUseStyles } from "react-jss";

import clsx from "clsx";

import { AppTheme } from "../../components/App/appTheme";
import buildingIcon from "../../images/pdsTemplate/building.svg";
import civilIcon from "../../images/pdsTemplate/civil.svg";
import militaryIcon from "../../images/pdsTemplate/military.svg";
import railwayIcon from "../../images/pdsTemplate/railway.svg";
import { PdsIconType } from "../../services/pds";

const useStyles = createUseStyles<
  "pdsTemplateIcon",
  { clickable: boolean },
  AppTheme
>((theme) => ({
  pdsTemplateIcon: ({ clickable }) => ({
    filter: "grayscale(100%) brightness(2)",
    cursor: clickable ? "pointer" : "default",
    display: "inline-block",
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    borderRadius: theme.borderRadius.main,

    "&:hover": {
      backgroundColor: clickable ? theme.palette.backgroundDark : "none",
      filter: "none",
    },
    "&.selected": {
      backgroundColor: theme.palette.backgroundPrimaryLight,
      filter: "none",
    },

    "& > span": {
      transform: "scale(1)",
      width: 75,
      height: 75,
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "none",
    },

    "&.civil > span": {
      backgroundImage: `url(${civilIcon})`,
    },

    "&.building > span": {
      backgroundImage: `url(${buildingIcon})`,
    },

    "&.railway > span": {
      backgroundImage: `url(${railwayIcon})`,
    },

    "&.military > span": {
      backgroundImage: `url(${militaryIcon})`,
    },
  }),
}));

interface PdsTemplateIconProps {
  iconType: PdsIconType;
  selected?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const PdsTemplateIcon: React.FC<PdsTemplateIconProps> = ({
  iconType,
  selected,
  className,
  onClick,
}) => {
  const classes = useStyles({ clickable: !!onClick });

  return (
    <div
      className={clsx(
        "pdsTemplateIcon",
        classes.pdsTemplateIcon,
        iconType,
        selected && "selected",
        className
      )}
      onClick={onClick}
    >
      <span />
    </div>
  );
};

export default PdsTemplateIcon;
