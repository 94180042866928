import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";

import { notify } from "@nexploretechnology/nxp-ui";

import AppChangePromptModal from "../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../hooks/useAppContext";
import { Pds, PdsForm, updatePds } from "../../../services/pds";
import SpectrumModalForm from "./PdsTemplateForm";

const useStyles = createUseStyles(() => ({
  updatePdsTemplateModal: {},
}));

interface UpdatePdsTemplateModalProps {
  visible: boolean;
  pds: Pds;
  onSpectrumUpdated: () => void;
  onModalClose: () => void;
}

const UpdatePdsTemplateModal: React.FC<UpdatePdsTemplateModalProps> = ({
  visible,
  pds,
  onSpectrumUpdated,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    async (form: PdsForm) => {
      try {
        setLoading(true);
        await updatePds(
          pds.id,
          { name: form.name, enabled: form.enabled, icon: form.icon },
          appContext.serviceConfig
        );
        notify.actionCompleted();
        onModalClose();
        onSpectrumUpdated();
      } catch (ex) {
        appContext.errorHandler(ex, `pds template update`);
      } finally {
        setLoading(false);
      }
    },
    [pds.id, appContext, onModalClose, onSpectrumUpdated]
  );

  const classes = useStyles();

  return visible ? (
    <AppChangePromptModal
      withNoPropagation
      visible={visible}
      onCancel={onModalClose}
      className={classes.updatePdsTemplateModal}
      title="Edit PDS Template"
    >
      <SpectrumModalForm
        initForm={{
          enabled: pds.enabled,
          operationEntityId: pds.operationEntityId,
          name: pds.name,
          icon: pds.icon,
        }}
        onSave={handleSave}
        onCancel={onModalClose}
        loading={loading}
      />
    </AppChangePromptModal>
  ) : null;
};

export default UpdatePdsTemplateModal;
