import { apiRequest, ServiceConfig } from "../utils/backend";
import { UserEntity } from "./userDirectory";

export enum EntityStatus {
  STARTED = "Started",
  IN_PROGRESS = "In Progress",
  READY = "Ready",
}

export enum OperationEntityStatus {
  ACTIVE = "Active",
  ARCHIVED = "Archived",
}

export interface EntitySelectionContext {
  id: string;
  name: string;
  description: string;
  breadcrumb?: string;
}

export interface Entity {
  id: string;
  name: string;
  entityCode: string; // added for CPCS-976
  description: string;
  members?: Entity[];
  tenantId?: string | null;
  type?: "Root" | "Operation";
}

export interface AddEntity {
  name: string;
  entityCode: string;
  description?: string;
  subscriptionAccount: string;
  status: EntityStatus;
}

export interface OperationEntity extends Entity {
  level: string;
  rootEntityId?: string;
  parentOperationEntityId?: string;
  timezone: string;
  parentOperationEntity?: OperationEntity;
  rootEntity?: Entity;
}

export interface AddOperationEntity {
  name: string;
  entityCode: string;
  description?: string;
  rootEntityId?: string;
  parentOperationEntityId?: string;
  entityTypeId: string;
  entityStatus: OperationEntityStatus;
  environmentStatus: EntityStatus;
  timezone: string;
}

export interface AddEntityRole {
  entityId: string;
  entityType: "Root" | "Operation";
  roleCode: string;
  roleName: string;
  description?: string;
  isDisabled: boolean;
  isAdmin: boolean;
}

export type EditEntityRole = Omit<AddEntityRole, "entityId" | "entityType">;

export interface EntityRole {
  id: string;
  rootEntityId: string | null;
  operationEntityId: string | null;
  roleCode: string;
  roleName: string;
  description: string;
  isDisabled: boolean;
  isAdmin: boolean;
  isVirtual: boolean;
  isCopyFromEntityType: boolean;
  rootEntity: Entity | null;
  operationEntity: OperationEntity | null;
  userEntities?: UserEntity[];
}

export interface EntityType {
  id: string;
  name: string;
  label: string;
  environmentAllowed: boolean;
  extraEntityAllow: boolean;
  isDisabled: false;
}

export type EditEntity = {
  name: string;
  description?: string;
  timezone: string;
};

export const getEntity = async (
  serviceConfig: ServiceConfig,
  entityId: string
) => {
  return await apiRequest<Entity>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity/${entityId}`,
    serviceConfig
  );
};

export const getRootEntities = async (
  serviceConfig: ServiceConfig,
  isFindMembers = false
) => {
  const data = await apiRequest<{ data: Entity[] }>(
    `${serviceConfig.coreServiceApiBaseUrl}/root-entity${
      isFindMembers ? "?isFindMembers=true" : ""
    }`,
    serviceConfig
  );
  return data.data;
};

export const getOperationEntities = async (serviceConfig: ServiceConfig) => {
  const data = await apiRequest<{ data: OperationEntity[] }>(
    `${serviceConfig.coreServiceApiBaseUrl}/operation-entity`,
    serviceConfig
  );
  return data.data;
};

export const getOperationEntity = async (
  serviceConfig: ServiceConfig,
  entityId: string,
  isFindParent = false
) => {
  return await apiRequest<OperationEntity>(
    `${serviceConfig.coreServiceApiBaseUrl}/operation-entity/${entityId}${
      isFindParent ? "?isFindParent=true" : ""
    }`,
    serviceConfig
  );
};

export const createOperationEntity = async (
  addOperationEntity: AddOperationEntity,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Entity>(
    `${serviceConfig.coreServiceApiBaseUrl}/operation-entity/${
      addOperationEntity.rootEntityId
        ? addOperationEntity.rootEntityId
        : addOperationEntity.parentOperationEntityId
    }`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addOperationEntity),
    }
  );
};

export function getEntityBreadcrumb(
  entity: OperationEntity
): string | undefined {
  if (entity.rootEntity) {
    return entity.rootEntity.name;
  } else if (entity.parentOperationEntity) {
    return (
      getEntityBreadcrumb(entity.parentOperationEntity) +
      ` / ${entity.parentOperationEntity.name}`
    );
  }
}

export const getEntityRoles = async (
  serviceConfig: ServiceConfig,
  entityId: string,
  entityType: "Root" | "Operation",
  isInjectUserEntities = false
) => {
  return await apiRequest<EntityRole[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity-role?entityId=${entityId}&entityType=${entityType}&isInjectUserEntities=${isInjectUserEntities}`,
    serviceConfig
  );
};

export const createEntityRole = async (
  addEntityRole: AddEntityRole,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<EntityRole>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity-role/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(addEntityRole),
    }
  );
};

export const editEntityRole = async (
  editEntityRole: EditEntityRole,
  entityRoleId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<EntityRole>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity-role/${serviceConfig.entityId}/${entityRoleId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editEntityRole),
    }
  );
};

export const deleteEntityRole = async (
  entityRoleId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity-role/${serviceConfig.entityId}/${entityRoleId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const getEntityType = async (serviceConfig: ServiceConfig) => {
  return apiRequest<EntityType[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/entity-type`,
    serviceConfig
  );
};

export const updateEntityDetail = async (
  serviceConfig: ServiceConfig,
  editEntity: EditEntity
) => {
  return await apiRequest<EditEntity>(
    `${serviceConfig.coreServiceApiBaseUrl}/operation-entity/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editEntity),
    }
  );
};
