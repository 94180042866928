import React, { useState } from "react";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Pds } from "../../../../services/pds";
import ReorderSpectrumsModal from "./ReorderSpectrumsModal";

interface ReorderSpectrumsButtonProps {
  pds: Pds;
  onPdsRefresh: () => void;
}

const ReorderSpectrumsButton: React.FC<ReorderSpectrumsButtonProps> = ({
  pds,
  onPdsRefresh,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton
        disabled={pds.spectrums.length < 2}
        onClick={handleClick}
        type="default"
      >
        Reorder Groups
      </NxpButton>
      {modalVisible && (
        <ReorderSpectrumsModal
          pds={pds}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
          onPdsRefresh={onPdsRefresh}
        />
      )}
    </>
  );
};

export default ReorderSpectrumsButton;
