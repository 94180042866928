import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { PlusCircleFilled } from "@ant-design/icons";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { Subcontractor } from "../../services/subcontractor";
import CompanyModal from "./CompanyModal";
import SubcontractorListing from "./Subcontractors";

const useStyles = createUseStyles(() => ({
  manageCompaniesLayout: {},
  action: {
    display: "flex",
  },
}));

interface ManageCompaniesLayoutProps {
  selectedSubcontractor: Subcontractor | undefined;
  showCompanyModal: boolean;
  onClickStateConfig: (subcontractor: Subcontractor) => void;
  onEditSubcontractor: (subcontractor: Subcontractor) => void;
  setSelectedSubtractor: React.Dispatch<
    React.SetStateAction<Subcontractor | undefined>
  >;
  setShowCompanyModal: React.Dispatch<React.SetStateAction<boolean>>;
  onFetchingSubcontractor: () => void;
  subcontractors: Subcontractor[];
}

const ManageCompaniesLayout: React.FC<ManageCompaniesLayoutProps> = ({
  selectedSubcontractor,
  showCompanyModal,
  onClickStateConfig,
  onEditSubcontractor,
  setSelectedSubtractor,
  setShowCompanyModal,
  subcontractors,
  onFetchingSubcontractor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasRight } = useAppContext();

  return (
    <div className={classes.manageCompaniesLayout}>
      <NxpHeader
        titleContent={t("app.layout.ManageCompanies")}
        onBackClick={() => navigate(-1)}
        actionContent={
          <div className={classes.action}>
            {hasRight("@company/company:create") && (
              <NxpButton onClick={() => setShowCompanyModal(true)}>
                <PlusCircleFilled color="primary" />
                {t("ManageCompanies.action.label", {
                  action: t("ManageCompanies.action.add"),
                })}
              </NxpButton>
            )}
          </div>
        }
      />
      <SubcontractorListing
        onClickStateConfig={onClickStateConfig}
        onEditSubcontractor={onEditSubcontractor}
        subcontractors={subcontractors}
      />
      {showCompanyModal && (
        <CompanyModal
          editSubcontractor={selectedSubcontractor!}
          show={showCompanyModal}
          onClose={() => {
            setSelectedSubtractor(undefined);
            setShowCompanyModal(false);
          }}
          onRefresh={onFetchingSubcontractor}
        ></CompanyModal>
      )}
    </div>
  );
};

export default ManageCompaniesLayout;
