import React, { useContext } from "react";

import {
  NxpModal,
  NxpModalNoPropagation,
  NxpModalProps,
} from "@nexploretechnology/nxp-ui";

import AppChangePromptContext from "./AppChangePromptContext";

interface AppChangePromptModalProps extends NxpModalProps {
  withNoPropagation?: boolean;
}

const AppChangePromptModal: React.FC<AppChangePromptModalProps> = ({
  withNoPropagation,
  onCancel,
  ...restProps
}) => {
  const { changePrompt, onChangePromptUpdate } = useContext(
    AppChangePromptContext
  );

  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (changePrompt) {
      if (!window.confirm("Proceed to discard unsaved changes?")) {
        return;
      }
      onChangePromptUpdate(false);
    }

    if (onCancel) {
      onCancel(e);
    }
  };

  return withNoPropagation ? (
    <NxpModalNoPropagation onCancel={handleCancel} {...restProps} />
  ) : (
    <NxpModal onCancel={handleCancel} {...restProps} />
  );
};

export default AppChangePromptModal;
