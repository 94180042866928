import { omit } from "lodash";
import { apiRequest, ServiceConfig } from "../utils/backend";

export interface OrganisationType {
  id: string;
  name: string;
}
export interface OrganisationTypeTableData extends OrganisationType {
  createdDate: string | null;
  lastUpdatedDate: string | null;
  isDisabled: boolean;
  deletedAt: string | null;
}

export const getOrganisationTypes = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<OrganisationTypeTableData[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/organisation-type?includeIsDisabled=true`,
    serviceConfig
  );
};

export const createOrganisationType = async (
  serviceConfig: ServiceConfig,
  addOrganisationTypeData: OrganisationType
) => {
  return await apiRequest<OrganisationType>(
    `${serviceConfig.coreServiceApiBaseUrl}/organisation-type`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(omit(addOrganisationTypeData, ["id"])),
    }
  );
};

export const patchOrganisationType = async (
  serviceConfig: ServiceConfig,
  editOrganisationTypeData: Partial<OrganisationType>,
  editOrganisationTypeItemId: string
) => {
  return await apiRequest<OrganisationType>(
    `${serviceConfig.coreServiceApiBaseUrl}/organisation-type/${editOrganisationTypeItemId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editOrganisationTypeData),
    }
  );
};

export const disbleOrganisationTypeStatus = async (
  serviceConfig: ServiceConfig,
  organisationTypeItemId: string
) => {
  return await apiRequest<OrganisationType>(
    `${serviceConfig.coreServiceApiBaseUrl}/organisation-type/${organisationTypeItemId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
export const enableOrganisationTypeStatus = async (
  serviceConfig: ServiceConfig,
  organisationTypeItemId: string
) => {
  return await apiRequest<OrganisationType>(
    `${serviceConfig.coreServiceApiBaseUrl}/organisation-type/${organisationTypeItemId}/enable`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};
