import React from "react";
import { useParams } from "react-router-dom";

import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import { getPdsList } from "../../services/pds";
import PdsTemplateDetails from "./PdsTemplateDetails";
import PdsTemplateList from "./PdsTemplateList";

const PdsPage: React.FC = () => {
  const { serviceConfig } = useAppContext();
  const templateId = useParams<{ templateId: string }>().templateId;

  const fetchPdsList = () =>
    getPdsList(serviceConfig.entityId!, false, false, serviceConfig);

  const [pdsListAsyncResult, setPdsListAsyncRequest] = useAsync(fetchPdsList);

  const handleRefresh = () => {
    setPdsListAsyncRequest(fetchPdsList);
  };

  const pdsTemplate =
    templateId && pdsListAsyncResult.data?.length
      ? pdsListAsyncResult.data.find((pds) => pds.id === templateId)
      : undefined;

  if (pdsListAsyncResult.data?.length && templateId && !pdsTemplate) {
    notify.error("Pds template not found.");
  }

  return !pdsListAsyncResult.data ? (
    <NxpSpin spinning={!pdsListAsyncResult.data} size="large" expand />
  ) : pdsTemplate ? (
    <PdsTemplateDetails
      pdsLoading={pdsListAsyncResult.loading}
      pds={pdsTemplate}
      onPdsRefresh={handleRefresh}
    />
  ) : (
    <PdsTemplateList
      loading={pdsListAsyncResult.loading}
      pdsList={pdsListAsyncResult.data}
      onPdsRefresh={handleRefresh}
    />
  );
};

export default PdsPage;
