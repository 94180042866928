import { apiRequest, ServiceConfig } from "../utils/backend";

export enum SubcontractorType {
  Contractor,
  Supplier,
}

export interface SubcontractorMember {}

export interface Company {
  disciplines: string[];
  members: { user: string; id: string; enabled: boolean }[];
  id: string;
  name: string;
  registration: string;
  slug: string;
  addressLine1: string;
  addressLine2: string | null;
  suburb: string | null;
  city: string;
  state: string;
  country: string;
  postCode: string | null;
  website: string | null;
  mailDomains: string[];
  phone: string | null;
  createdOn: Date;
  lastUpdatedOn: Date;
}

export interface SubcontractorForm {
  name: string;
  logoAssetId: string;
  organisationTypeId: string;
  businessRegistrationNumber: string;
  postalAddress: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  mailDomain: string;
  contactPerson: string;
  contactPhone: string;
  jdeCode: string;
  parentCompany: string;
  bvdRating: string;
}
export interface Subcontractor extends SubcontractorForm {
  id: string;
  operationEntityId: string;
  createdById: string;
  lastUpdatedById: string;
  createdDate: string;
  lastUpdatedDate: string;
  deletedAt: string | null;
  isDisabled: boolean;
}

export const getSubcontractors = async (
  serviceConfig: ServiceConfig
): Promise<Subcontractor[]> => {
  return await apiRequest<Subcontractor[]>(
    `${serviceConfig?.coreServiceApiBaseUrl}/company/${serviceConfig?.entityId}?includeIsDisabled=true`,
    serviceConfig
  );
};
export const deleteSubcontractors = async (
  subcontractorId: Subcontractor["id"],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/company/${serviceConfig?.entityId}/${subcontractorId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
export const enableSubcontractors = async (
  subcontractorId: Subcontractor["id"],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/company/${serviceConfig?.entityId}/${subcontractorId}/enable`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};
export const createSubcontractor = async (
  subcontractorForm: SubcontractorForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor>(
    `${serviceConfig.coreServiceApiBaseUrl}/company/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(subcontractorForm),
    }
  );
};
export const updateSubcontractor = async (
  id: string,
  subcontractorForm: SubcontractorForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor>(
    `${serviceConfig.coreServiceApiBaseUrl}/company/${serviceConfig?.entityId}/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(subcontractorForm),
    }
  );
};
