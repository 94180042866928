import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";

import { AddRoleFormData } from "./AddRoleModal";

interface AddRoleFormProps {
  form: AddRoleFormData;
  validationError: ValidationResult<AddRoleFormData>;
  handleFormGridStateChange: (
    fieldName: keyof AddRoleFormData,
    value: any
  ) => void;
}

const AddRoleForm: React.FC<AddRoleFormProps> = ({
  form,
  validationError,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<AddRoleFormData>[] = [
    {
      controlType: "input",
      label: t("ManageRole.RoleName"),
      itemFieldName: "roleName",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("ManageRole.RoleCode"),
      itemFieldName: "roleCode",
      span: 12,
      required: true,
    },
    {
      controlType: "checkbox",
      label: t("ManageRole.IsAdmin"),
      itemFieldName: "isAdmin",
      span: 12,
    },
    {
      controlType: "checkbox",
      label: t("ManageRole.IsDisabled"),
      itemFieldName: "isDisabled",
      span: 12,
    },
    {
      controlType: "textarea",
      label: t("app.common.Description"),
      itemFieldName: "description",
      span: 24,
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default AddRoleForm;
