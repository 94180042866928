import { isEmpty } from "lodash";

import { DEFAULT_LANGUAGE_KEY } from "./const";

type LanguageObject =
  | Record<string, string | undefined | null>
  | null
  | undefined;

export const checkEmptyLangObject = (langObj?: LanguageObject) => {
  if (langObj) {
    for (const key of Object.keys(langObj)) {
      if (!isEmpty(langObj[key])) {
        return true;
      }
    }
  }
  return false;
};

export const checkDefaultLanguageDefined = (langObj?: LanguageObject) => {
  return !!(langObj && langObj[DEFAULT_LANGUAGE_KEY]?.trim());
};

export const getLocalizedText = (
  langObj: LanguageObject,
  activeLanguage: string,
  noFailOverToDefaultLanguage?: boolean
) => {
  let s: string | undefined = undefined;
  if (langObj) {
    if (typeof langObj[activeLanguage] === "string") {
      s = langObj[activeLanguage] as string;
    }
    // fail over to default language
    if (
      !noFailOverToDefaultLanguage &&
      !s?.trim() &&
      typeof langObj[DEFAULT_LANGUAGE_KEY] === "string"
    ) {
      s = langObj[DEFAULT_LANGUAGE_KEY] as string;
    }
  }
  return s;
};
