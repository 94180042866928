import React, { useContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { notify, NxpButton } from "@nexploretechnology/nxp-ui";
import { isEqual } from "lodash";

import AppChangePromptButton from "../../../../components/AppChangePrompt/AppChangePromptButton";
import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import AppChangePromptModal from "../../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../../hooks/useAppContext";
import {
  Pds,
  PdsReorderItem,
  PdsSpectrum,
  reorderPdsSpectrums,
} from "../../../../services/pds";
import ReorderPdsDragDropPlaceholder from "./ReorderPdsDragDropPlaceholder";
import ReorderPdsItem from "./ReorderPdsItem";

interface ReorderSpectrumsModalProps {
  modalVisible: boolean;
  pds: Pds;
  onModalClose: () => void;
  onPdsRefresh: () => void;
}

const ReorderSpectrumsModal: React.FC<ReorderSpectrumsModalProps> = ({
  modalVisible,
  pds,
  onModalClose,
  onPdsRefresh,
}) => {
  const appContext = useAppContext();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [editState, setEditState] = useState(pds.spectrums);

  const [loading, setLoading] = useState(false);

  const getSpectrumOrders = (spectrums: PdsSpectrum[]) => {
    return spectrums
      .sort((a, b) => a.order - b.order)
      .map((s) => ({ spectrumId: s.id, order: s.order }));
  };

  const handleDrop = (spectrum: PdsReorderItem, newPosition: number) => {
    setEditState((prevState) => {
      const nextState = prevState.map((varSpectrum) => {
        if (varSpectrum.id === spectrum.id) {
          if (spectrum.order > newPosition) {
            return { ...varSpectrum, order: newPosition };
          }
          if (spectrum.order < newPosition) {
            return { ...varSpectrum, order: newPosition - 1 };
          }
        } else if (
          spectrum.order > newPosition &&
          varSpectrum.order < spectrum.order &&
          varSpectrum.order >= newPosition
        ) {
          return { ...varSpectrum, order: varSpectrum.order + 1 };
        } else if (
          spectrum.order < newPosition &&
          varSpectrum.order > spectrum.order &&
          varSpectrum.order < newPosition
        ) {
          return { ...varSpectrum, order: varSpectrum.order - 1 };
        }
        return varSpectrum;
      });
      return nextState;
    });
    onChangePromptUpdate(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await reorderPdsSpectrums(
        pds.id,
        { spectrumOrders: getSpectrumOrders(editState) },
        appContext.serviceConfig
      );
      onPdsRefresh();
      notify.actionCompleted();
      onModalClose();
      onChangePromptUpdate(false);
    } catch (ex) {
      appContext.errorHandler(ex, `spectrum reorder`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppChangePromptModal
      width="small"
      title="Reorder PDS Groups"
      visible={modalVisible}
      footer={
        <>
          <AppChangePromptButton
            type="default"
            disabled={loading}
            onClick={onModalClose}
          >
            Cancel
          </AppChangePromptButton>
          <NxpButton
            onClick={handleSave}
            loading={loading}
            disabled={isEqual(
              getSpectrumOrders(editState),
              getSpectrumOrders(pds.spectrums)
            )}
          >
            Save
          </NxpButton>
        </>
      }
      onCancel={onModalClose}
    >
      <DndProvider backend={HTML5Backend}>
        {editState
          .sort((a, b) => a.order - b.order)
          .map((spectrum) => (
            <ReorderPdsItem
              key={spectrum.id}
              item={{
                id: spectrum.id,
                code: spectrum.code,
                order: spectrum.order,
                translations: spectrum.translations,
              }}
              onDrop={handleDrop}
            />
          ))}
        <ReorderPdsDragDropPlaceholder
          position={Math.max(...editState.map((s) => s.order)) + 1}
          onDrop={handleDrop}
        />
      </DndProvider>
    </AppChangePromptModal>
  );
};

export default ReorderSpectrumsModal;
