import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import { AddDictionaryTypeFormData } from "./AddDictionaryTypeModal";

interface AddDictionaryTypeFormProps {
  form: AddDictionaryTypeFormData;
  validationError: ValidationResult<AddDictionaryTypeFormData>;
  isEdit: boolean;
  dataTypeOptions: LabeledValue[];
  handleFormGridStateChange: (
    fieldName: keyof AddDictionaryTypeFormData,
    value: any
  ) => void;
}

const AddDictionaryTypeForm: React.FC<AddDictionaryTypeFormProps> = ({
  form,
  validationError,
  isEdit,
  dataTypeOptions,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<AddDictionaryTypeFormData>[] = [
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "name",
      span: 12,
      required: true,
      controlProps: {
        disabled: isEdit,
      },
    },
    {
      controlType: "select",
      label: t("DictionaryType.DataType"),
      itemFieldName: "dataType",
      span: 12,
      required: true,
      controlProps: {
        value: form.dataType,
        options: dataTypeOptions,
        placeholder: t("app.common.PleaseSelect"),
      },
    },
    {
      controlType: "input",
      label: t("DictionaryType.Application"),
      itemFieldName: "application",
      span: 12,
      controlProps: {
        disabled: isEdit,
      },
    },
    {
      controlType: "textarea",
      label: t("app.common.Description"),
      itemFieldName: "description",
      span: 24,
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default AddDictionaryTypeForm;
