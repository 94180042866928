import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormItem,
  NxpModal,
  NxpTable,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { SelectUserFormData } from "./ManageRolePageContainer";

interface BatchAssignConfirmationModalProps {
  showModal: boolean;
  isSubmitting: boolean;
  batchAssignConfirmForm?: SelectUserFormData;
  onBatchAssignConfirm: (notifyUsers: string[]) => void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ConfirmationTableData {
  userId: string;
  userName: string;
  notify: boolean;
  email: string;
}

const BatchAssignConfirmationModal: React.FC<BatchAssignConfirmationModalProps> =
  ({
    showModal,
    isSubmitting,
    batchAssignConfirmForm,
    onBatchAssignConfirm,
    setShowModal,
  }) => {
    const { t } = useTranslation();
    const { users } = useAppContext();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [tableData, setTableData] = useState<ConfirmationTableData[]>([]);

    const handleRowSelect = (item: ConfirmationTableData) => {
      let newSelectedRows: string[] = [];
      if (selectedRows.includes(item.userId)) {
        newSelectedRows = selectedRows.filter((row) => row !== item.userId);
      } else {
        newSelectedRows = [...selectedRows, item.userId];
      }
      setSelectedRows(newSelectedRows);
    };

    const columns = [
      {
        title: t("ManageRole.User"),
        dataIndex: "userName",
        width: 100,
      },
      {
        title: t("ManageRole.NotifyInvitee"),
        dataIndex: "notify",
        width: 75,
        render: (_: any, item: ConfirmationTableData) => (
          <NxpFormItem
            key={`CheckBox-${item.userId}`}
            controlType="checkbox"
            controlProps={{
              checked: selectedRows.includes(item.userId),
              onChange: (_) => handleRowSelect(item),
            }}
          />
        ),
      },
      {
        title: t("app.common.Email"),
        dataIndex: "email",
        width: 150,
      },
    ];

    useEffect(() => {
      const tableData = batchAssignConfirmForm
        ? batchAssignConfirmForm.users.map((userId) => {
            const adUser = batchAssignConfirmForm.adUsers.find(
              (a) => userId === `a_${a.id}`
            );
            if (adUser) {
              return {
                userId: userId,
                userName: adUser.displayName,
                notify: true,
                email: adUser.mail,
              } as ConfirmationTableData;
            } else {
              const user = users.find((u) => u.id === userId);
              return {
                userId: userId,
                userName: user
                  ? user.displayName
                    ? user.displayName
                    : `${user.firstName} ${user.lastName}`
                  : "",
                notify: true,
                email: user!.primaryEmail,
              } as ConfirmationTableData;
            }
          })
        : [];
      setTableData(tableData);
      const selectedRows = tableData.map((t) => t.userId);
      setSelectedRows(selectedRows);
    }, [users, batchAssignConfirmForm]);

    return (
      <NxpModal
        title={t("ManageRole.Confirmation")}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        width="large"
        destroyOnClose={true}
        footer={
          <>
            <NxpButton
              type="default"
              onClick={() => setShowModal(false)}
              loading={isSubmitting}
            >
              {t("app.common.Cancel")}
            </NxpButton>
            <NxpButton
              onClick={() => onBatchAssignConfirm(selectedRows)}
              loading={isSubmitting}
            >
              {t("app.common.Confirm")}
            </NxpButton>
          </>
        }
      >
        <NxpTable
          rowKey={(item) => item.userId}
          useDefaultHeight={true}
          pagination={false}
          columns={columns as any}
          dataSource={tableData}
        />
      </NxpModal>
    );
  };

export default BatchAssignConfirmationModal;
