import React, { useCallback, useEffect, useMemo, useState } from "react";

import { notify } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import { DataNode } from "antd/lib/tree";
import { find, forIn, get, head, toUpper } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { LangLabel } from "../../i18n/LangLabelEnum";
import {
  createDictionaryData,
  CreateDictionaryData,
  createDictionaryTypeItem,
  CreateDictionaryTypeItem,
  deleteDictionaryTypeItem,
  Dictionary,
  DictionaryDataType,
  DictionaryItem,
  DictionaryType,
  EditDictionaryData,
  EditDictionaryTypeItem,
  getDictionaryList,
  getDictionaryTypes,
  patchDictionaryData,
  patchDictionaryTypeItem,
} from "../../services/dictionary";
import { DEFAULT_LANGUAGES } from "../../utils/const";
import AddDictionaryItemModal, {
  AddDictionaryItemFormData,
} from "./AddDictionaryItemModal";
import AddDictionaryModal, {
  AddDictionaryFormData,
} from "./AddDictionaryModal";
import DictionaryPageLayout from "./DictionaryPageLayout";

interface DictionaryPageContainerProps {}

export interface DictionarySelectItem {
  id: string;
  key: string;
  label: string;
  labels: DictionaryItem["labels"];
  value: string | number;
  isDictionary: boolean;
  dictionaryTypeId: string;
  members?: string[];
  itemDataId: string;
}

const DictionaryPageContainer: React.FC<DictionaryPageContainerProps> = () => {
  const {
    language,
    activeEntity,
    activeEntityType,
    serviceConfig,
    errorHandler,
  } = useAppContext();
  const [dictionaries, setDictionaries] = useState<Dictionary[]>();
  const [dictionaryTypes, setDictionaryTypes] = useState<DictionaryType[]>();
  const [selectedTreeKey, setSelectedTreeKey] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<DictionarySelectItem>();
  const [showAddDictionaryModal, setShowAddDictionaryModal] =
    useState<boolean>(false);
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const [isEditDictionaryModal, setIsEditDictionaryModal] =
    useState<boolean>(false);
  const [isEditDictionaryItemModal, setIsEditDictionaryItemModal] =
    useState<boolean>(false);
  const [editDictionaryItemData, setEditDictionaryItemData] =
    useState<AddDictionaryItemFormData>();
  const [editDictionaryData, setEditDictionaryData] =
    useState<AddDictionaryFormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [dictionaryTree, setDictionaryTree] = useState<DataNode[]>();
  const [selectItemsMap, setSelectItemsMap] = useState<
    Map<string, DictionarySelectItem>
  >(new Map());
  const [itemMembers, setItemMembers] = useState<string[]>([]);

  const setTreeAfterDictionaryChange = useCallback(
    (dictionaries: Dictionary[]) => {
      const dictionaryTree: DataNode[] = [];
      const itemsMap = new Map<string, DictionarySelectItem>();
      let matchSelectedItemKey = false;
      const setDictionaryItemToSelectItemMap = (
        i: DictionaryItem,
        prefix: string,
        level: number,
        dictionaryTypeId: string
      ) => {
        const item: DictionarySelectItem = {
          id: `${level}_${prefix}_${i.key}`,
          itemDataId: i?.id!,
          key: i.key,
          labels: i.labels,
          label: get(i.labels, `${language}`),
          value: i.value,
          isDictionary: false,
          members: i.members?.map(
            (m) => `${level + 1}_${prefix}_${i.key}_${m.key}`
          ),
          dictionaryTypeId: dictionaryTypeId,
        };
        itemsMap.set(`${level}_${prefix}_${i.key}`, item);
        i.members?.forEach((m) => {
          setDictionaryItemToSelectItemMap(
            m,
            `${prefix}_${i.key}`,
            level + 1,
            dictionaryTypeId
          );
        });
      };

      const convertDictionaryItemToTree = (
        dictionary: DictionaryItem,
        prefix: string,
        level: number
      ): DataNode | undefined => {
        const treeKey = `${level}_${prefix}_${dictionary.key}`;
        if (treeKey === selectedTreeKey) {
          matchSelectedItemKey = true;
        }
        return {
          key: treeKey,
          title: get(dictionary.labels, `${language}`),
          isLeaf: !dictionary.members || dictionary.members.length === 0,
          children: dictionary.members?.map(
            (member) =>
              convertDictionaryItemToTree(
                member,
                `${prefix}_${dictionary.key}`,
                level + 1
              ) as DataNode
          ),
        };
      };
      dictionaries?.forEach((d) => {
        const treeKey = `0_${d.id}`;
        if (treeKey === selectedTreeKey) {
          matchSelectedItemKey = true;
        }
        const treeNode = {
          key: treeKey,
          title: d.name,
          isLeaf: !d.members || d.members.length === 0,
          children: d.members?.map(
            (m) => convertDictionaryItemToTree(m, d.id, 1) as DataNode
          ),
        } as DataNode;
        dictionaryTree.push(treeNode);
        const item: DictionarySelectItem = {
          id: treeKey,
          itemDataId: d?.id!,
          key: d.id,
          label: d.name,
          labels: d.name as any,
          value: d.name,
          isDictionary: true,
          dictionaryTypeId: d.dictionaryTypeId,
          members: d.members?.map((m) => {
            return `1_${d.id}_${m.key}`;
          }),
        };
        itemsMap.set(`0_${d.id}`, item);
        d.members?.forEach((i) => {
          setDictionaryItemToSelectItemMap(i, d.id, 1, d.dictionaryTypeId);
        });
      });
      setDictionaryTree(dictionaryTree);
      setSelectItemsMap(itemsMap);
      if (dictionaries && dictionaries.length > 0) {
        if (!matchSelectedItemKey) {
          const key = `0_${dictionaries[0].id}`;
          setSelectedTreeKey(key);
          setSelectedItem(itemsMap.get(key));
        } else if (selectedTreeKey) {
          setSelectedItem(itemsMap.get(selectedTreeKey));
        }
      }
    },
    [language, selectedTreeKey]
  );

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const data = await Promise.all([
        getDictionaryTypes(serviceConfig),
        getDictionaryList(serviceConfig),
      ]);
      const dictionaryTypes = data[0];
      const entityDictionaries = data[1].map((d) => ({
        ...d,
        members: d.members.sort((a, b) => (a.key > b.key ? 1 : -1)),
      }));
      setDictionaries(entityDictionaries);
      setDictionaryTypes(dictionaryTypes);
      setTreeAfterDictionaryChange(entityDictionaries);
    } catch (err) {
      errorHandler(err, "fetch dictionary");
    } finally {
      setLoading(false);
    }
  }, [serviceConfig, errorHandler, setTreeAfterDictionaryChange]);

  useEffect(() => {
    if (refresh) {
      fetch();
      setRefresh(false);
    }
  }, [refresh, fetch]);

  const handleTreeSelect = useCallback(
    (key: string) => {
      if (!key) return;
      setSelectedTreeKey(key);
      setSelectedItem(selectItemsMap.get(key));
    },
    [selectItemsMap]
  );

  const addItemToDictionaryItem = useCallback(
    (
      form: AddDictionaryItemFormData,
      findItemId: string,
      item: DictionaryItem,
      prefix: string,
      findLevel: number,
      currentLevel: number
    ): DictionaryItem => {
      if (findLevel === currentLevel) {
        const mapItemKey = `${currentLevel}_${prefix}`;
        if (findItemId === mapItemKey) {
          let _item: DictionaryItem = Object.assign(item);
          const members = _item.members ? [..._item.members] : [];
          members.push({
            key: form.key,
            labels: form.labels,
            value: form.value,
            members: [],
            isEngaged: false,
          });
          _item.members = members;
          return _item;
        } else {
          return item;
        }
      } else if (item.members) {
        let _item: DictionaryItem = Object.assign(item);
        _item.members = item.members.map((m) => {
          return addItemToDictionaryItem(
            form,
            selectedItem!.id,
            m,
            `${prefix}_${m.key}`,
            findLevel,
            currentLevel + 1
          );
        });
        return _item;
      } else {
        return item;
      }
    },
    [selectedItem]
  );

  const mapDictionaryItemForm = useCallback(
    (member: DictionaryItem): DictionaryItem => {
      return {
        key: member.key,
        labels: member.labels,
        value: member.value,
        members: member.members?.map(mapDictionaryItemForm),
        isEngaged: member.isEngaged,
      };
    },
    []
  );

  const handleAddDictionaryItem = useCallback(
    async (form: AddDictionaryItemFormData) => {
      const splitArray = selectedItem?.id.split("_");
      const dictionaryId = get(splitArray, "[1]");
      try {
        const postData = {
          labels: form?.labels,
          key: form?.key,
          value: form?.value,
          dictionaryDataId: dictionaryId,
          parentId: head(splitArray) !== "0" ? selectedItem?.itemDataId : null,
          isEngaged: true,
          label: form.labels.find((label) => label.langKey === LangLabel.EN)
            ?.value,
        } as unknown as CreateDictionaryTypeItem;
        const newDictionaryData = await createDictionaryTypeItem(
          serviceConfig,
          postData
        );
        notify.actionCompleted();
        setRefresh(true);
        setShowAddItemModal(false);
        return newDictionaryData;
      } catch (err) {
        errorHandler(err, "add dictionary item");
      } finally {
        setLoading(false);
      }
    },
    [selectedItem, serviceConfig, errorHandler]
  );

  const editDictionaryItem = useCallback(
    (
      form: AddDictionaryItemFormData,
      findItemId: string,
      members: DictionaryItem[],
      prefix: string,
      findLevel: number,
      currentLevel: number
    ): DictionaryItem[] => {
      if (findLevel === currentLevel + 1) {
        return members.map((m) => {
          const mapItemKey = `${currentLevel + 1}_${prefix}_${m.key}`;
          if (findItemId === mapItemKey) {
            let _m: DictionaryItem = Object.assign(m);
            _m.key = form.key;
            _m.labels = form.labels;
            _m.value = form.value;
            return _m;
          } else {
            return m;
          }
        });
      } else {
        return members.map((m) => {
          let _m: DictionaryItem = Object.assign(m);
          if (_m.members) {
            _m.members = editDictionaryItem(
              form,
              findItemId,
              _m.members,
              `${prefix}_${_m.key}`,
              findLevel,
              currentLevel + 1
            );
          }
          return _m;
        });
      }
    },
    []
  );

  const handleEditDictionaryItem = useCallback(
    async (form: AddDictionaryItemFormData) => {
      try {
        setLoading(true);
        const postData = {
          labels: form?.labels,
          key: form?.key,
          value: form?.value,
          isEngaged: true,
          label: form.labels.find((label) => label.langKey === LangLabel.EN)
            ?.value,
        } as EditDictionaryTypeItem;
        const newDictionaryData = await patchDictionaryTypeItem(
          serviceConfig,
          postData,
          get(editDictionaryItemData, "item.itemDataId") || ""
        );
        notify.actionCompleted();
        setRefresh(true);
        setShowAddItemModal(false);
        return newDictionaryData;
      } catch (err) {
        errorHandler(err, "edit dictionary item");
      } finally {
        setLoading(false);
      }
    },
    [serviceConfig, errorHandler, editDictionaryItemData]
  );

  const handleAddDictionaryItemSubmit = useCallback(
    async (form: AddDictionaryItemFormData) => {
      const langPack = DEFAULT_LANGUAGES.map((lang) => {
        const fieldName =
          lang === LangLabel.ZH_HK ? `labelCN` : `label${toUpper(lang)}`;
        return {
          langKey: lang,
          value: form[fieldName as keyof AddDictionaryItemFormData],
        };
      }) as DictionaryItem["labels"];
      if (!isEditDictionaryItemModal) {
        // Add Item
        await handleAddDictionaryItem({
          ...form,
          labels: langPack,
          label: langPack.find((lang) => lang.langKey === LangLabel.EN)?.value!,
        });
      } else {
        // Edit Item
        await handleEditDictionaryItem({
          ...form,
          labels: langPack,
          label: langPack.find((lang) => lang.langKey === LangLabel.EN)?.value!,
        });
      }
    },
    [
      isEditDictionaryItemModal,
      handleAddDictionaryItem,
      handleEditDictionaryItem,
    ]
  );

  const removeItemFromDictionaryMembers = useCallback(
    (
      removeItemId: string,
      members: DictionaryItem[],
      prefix: string,
      findLevel: number,
      currentLevel: number
    ): DictionaryItem[] => {
      if (findLevel === currentLevel + 1) {
        return members
          .map((m) => {
            const mapItemKey = `${currentLevel + 1}_${prefix}_${m.key}`;
            if (removeItemId === mapItemKey) {
              return undefined; // remove
            } else {
              return m;
            }
          })
          .filter((m) => m !== undefined) as DictionaryItem[];
      } else {
        return members.map((m) => {
          let _m: DictionaryItem = Object.assign(m);
          if (_m.members) {
            _m.members = removeItemFromDictionaryMembers(
              removeItemId,
              _m.members,
              `${prefix}_${_m.key}`,
              findLevel,
              currentLevel + 1
            );
          }
          return _m;
        });
      }
    },
    []
  );

  const handleDeleteDictionaryItem = useCallback(
    async (item?: DictionarySelectItem) => {
      try {
        setLoading(true);
        if (!item) return;
        const delectDictionaryData = await deleteDictionaryTypeItem(
          serviceConfig,
          item?.itemDataId
        );
        notify.actionCompleted();
        setRefresh(true);
        setShowAddItemModal(false);
        return delectDictionaryData;
      } catch (err) {
        errorHandler(err, "delete dictionary item");
      } finally {
        setLoading(false);
      }
    },
    [serviceConfig, errorHandler]
  );

  const getDictionaryItemKeys = useCallback(
    (members: DictionaryItem[]): string[] => {
      const keyList: string[] = [];
      members.forEach((m) => {
        keyList.push(m.key);
        if (m.members) {
          const membersKeyList = getDictionaryItemKeys(m.members);
          membersKeyList.forEach((mk) => {
            keyList.push(mk);
          });
        }
      });
      return keyList;
    },
    []
  );

  const handleClickHeaderEdit = useCallback(() => {
    if (selectedItem?.isDictionary) {
      const editDictionaryData: AddDictionaryFormData = {
        name: selectedItem.label,
        dictionaryType: dictionaryTypes!.find(
          (t) => t.id === selectedItem.dictionaryTypeId
        )!.name,
      };
      setShowAddDictionaryModal(true);
      setIsEditDictionaryModal(true);
      setEditDictionaryData(editDictionaryData);
    } else if (selectedItem) {
      const editDictionaryData: AddDictionaryItemFormData & {
        labelCN?: string;
        labelEN?: string;
        labelES?: string;
        labelDE?: string;
      } = {
        key: selectedItem.key,
        labels: selectedItem.label as any,
        value: selectedItem.value,
        item: selectedItem,
        label: "",
      };
      const splitArray = selectedItem.id.split("_");
      const dictionaryId = splitArray[1];
      const dictionary = dictionaries?.find((d) => d.id === dictionaryId);
      const itemMembers = dictionary
        ? getDictionaryItemKeys(dictionary.members).filter(
            (k) => k !== selectedItem.key
          )
        : [];

      setShowAddItemModal(true);
      setIsEditDictionaryItemModal(true);
      setEditDictionaryItemData(editDictionaryData);
      setItemMembers(itemMembers);
    }
  }, [dictionaryTypes, dictionaries, selectedItem, getDictionaryItemKeys]);

  const handleTableRowEdit = useCallback(
    (item: DictionarySelectItem) => {
      const splitArray = item.id.split("_");
      const dictionaryId = splitArray[1];
      const dictionary = dictionaries?.find((d) => d.id === dictionaryId);
      const itemMembers = dictionary
        ? getDictionaryItemKeys(dictionary.members).filter(
            (k) => k !== item.key
          )
        : [];
      let langPack: Record<any, any> = {};
      forIn(item?.labels, (v, k) => {
        let feildName = k === "zh-HK" ? `labelCN` : `label${toUpper(k)}`;
        langPack[feildName] = v;
      });

      const editDictionaryData: AddDictionaryItemFormData = {
        key: item.key,
        labels: langPack as unknown as DictionaryItem["labels"],
        ...(langPack as unknown as DictionaryItem["labels"]),
        value: item.value,
        item: item,
        label: find(langPack, { langPack: "en" })?.value,
      };
      setShowAddItemModal(true);
      setIsEditDictionaryItemModal(true);
      setEditDictionaryItemData(editDictionaryData);
      setItemMembers(itemMembers);
    },
    [dictionaries, getDictionaryItemKeys]
  );

  const handleClickAddDictionary = useCallback(() => {
    setShowAddDictionaryModal(true);
    setIsEditDictionaryModal(false);
    setEditDictionaryData(undefined);
  }, []);

  const handleClickAddItem = useCallback(() => {
    setIsEditDictionaryItemModal(false);
    setShowAddItemModal(true);
    if (selectedItem) {
      const splitArray = selectedItem.id.split("_");
      const dictionaryId = splitArray[1];
      const dictionary = dictionaries?.find((d) => d.id === dictionaryId);
      const itemMembers = dictionary
        ? getDictionaryItemKeys(dictionary.members)
        : [];
      setItemMembers(itemMembers);
    } else {
      setItemMembers([]);
    }
  }, [dictionaries, selectedItem, getDictionaryItemKeys]);

  const handleAddDictionarySubmit = useCallback(
    async (form: AddDictionaryFormData) => {
      setLoading(true);
      if (!isEditDictionaryModal) {
        try {
          const addDictionaryData: CreateDictionaryData = {
            name: form.name,
            entityType: activeEntityType!,
            entityId: activeEntity!.id,
            dictionaryTypeId: form.dictionaryType,
            members: [],
            isInheritable: true,
          };
          const newDictionary = await createDictionaryData(
            serviceConfig,
            addDictionaryData
          );
          setShowAddDictionaryModal(false);
          notify.actionCompleted();
          setRefresh(true);
          return newDictionary;
        } catch (err) {
          errorHandler(err, "create dictionary");
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const editDictionaryData: EditDictionaryData = {
            name: form.name,
          };
          const newDictionaryData = await patchDictionaryData(
            serviceConfig,
            selectedItem!.key,
            editDictionaryData
          );
          notify.actionCompleted();
          setRefresh(true);
          setShowAddDictionaryModal(false);
          return newDictionaryData;
        } catch (err) {
          errorHandler(err, "edit dictionary");
        } finally {
          setLoading(false);
        }
      }
    },
    [
      activeEntity,
      activeEntityType,
      isEditDictionaryModal,
      selectedItem,
      serviceConfig,
      errorHandler,
    ]
  );

  const dictionaryTypeOptions = useMemo(() => {
    return dictionaryTypes
      ? dictionaryTypes
          .filter((t) => {
            return (
              isEditDictionaryModal ||
              !dictionaries?.some((d) => d.dictionaryTypeId === t.id)
            );
          })
          .map((t) => {
            return {
              key: t.id,
              value: t.id,
              label: t.name,
            } as LabeledValue;
          })
      : [];
  }, [dictionaryTypes, dictionaries, isEditDictionaryModal]);

  const dictionaryDataType = useMemo(() => {
    if (
      dictionaryTypes &&
      dictionaryTypes.some((t) => t.id === selectedItem?.dictionaryTypeId)
    ) {
      return dictionaryTypes.find(
        (t) => t.id === selectedItem?.dictionaryTypeId
      )!.dataType;
    }
    return DictionaryDataType.TEXT;
  }, [dictionaryTypes, selectedItem]);

  return (
    <>
      <DictionaryPageLayout
        dictionaryTree={dictionaryTree}
        selectedTreeKey={selectedTreeKey}
        selectedItem={selectedItem}
        selectItemsMap={selectItemsMap}
        loading={loading}
        onTreeSelect={handleTreeSelect}
        onClickAddDictionary={handleClickAddDictionary}
        onClickAddItem={handleClickAddItem}
        onClickHeaderEdit={handleClickHeaderEdit}
        onClickTableRowEdit={handleTableRowEdit}
      />
      <AddDictionaryModal
        showModal={showAddDictionaryModal}
        loading={loading}
        isEdit={isEditDictionaryModal}
        editData={editDictionaryData}
        dictionaryTypeOptions={dictionaryTypeOptions}
        setShowModal={setShowAddDictionaryModal}
        onSubmit={handleAddDictionarySubmit}
      />
      <AddDictionaryItemModal
        showModal={showAddItemModal}
        loading={loading}
        isEdit={isEditDictionaryItemModal}
        editData={editDictionaryItemData as any}
        members={itemMembers}
        dataType={dictionaryDataType}
        setShowModal={setShowAddItemModal}
        onSubmit={handleAddDictionaryItemSubmit}
        onDelete={handleDeleteDictionaryItem}
      />
    </>
  );
};

export default DictionaryPageContainer;
