import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpPanel,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Input } from "antd";

import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import { LangLabel } from "../../../i18n/LangLabelEnum";
import { DEFAULT_LANGUAGE_KEY, DEFAULT_LANGUAGES } from "../../../utils/const";
import { DataSetDetailsForm } from "./DataSetDetails";

const useStyles = createUseStyles((theme) => ({
  dataSetGeneral: {},
  languageInputDiv: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 3),
    marginTop: theme.spacing(0.5),
    alignContent: "flex-start",
    "& > span.ant-input-group-wrapper": {
      width: `calc(50% - ${theme.spacing(1.5)})`,
      flexShrink: 0,
      marginBottom: 0,
      "& .ant-input-group-addon:first-child": {
        width: theme.spacing(8),
        fontSize: theme.fontSize.smaller,
        color: theme.palette.textLabel,
      },
    },
    "& span.requiredLang": {
      color: theme.palette.danger,
      marginLeft: 2,
    },
  },
}));

interface DataSetGeneralProps {
  form: DataSetDetailsForm;
  validationError: ValidationResult<DataSetDetailsForm>;
  onFormChange: (
    handler: (prevState: DataSetDetailsForm) => DataSetDetailsForm
  ) => void;
}

const DataSetGeneral: React.FC<DataSetGeneralProps> = ({
  form,
  validationError,
  onFormChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const handleFormGridStateChange = (
    fieldName: keyof DataSetDetailsForm,
    value: unknown
  ) => {
    if (["displayName", "description"].includes(fieldName)) {
      onFormChange((prevState) => {
        const map = prevState[fieldName] as Record<LangLabel, string>;
        Object.entries(value as object).forEach(([lang, value]) => {
          map[lang as LangLabel] = value;
        });
        return {
          ...prevState,
          [fieldName]: map,
          name:
            fieldName === "displayName" ? map[LangLabel.EN] : prevState.name,
        };
      });
    } else {
      onFormChange((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
    onChangePromptUpdate(true);
  };

  const formItems: NxpFormGridItemProps<DataSetDetailsForm>[] = [
    {
      controlType: "input",
      label: "Code",
      itemFieldName: "code",
      required: true,
      span: 12,
    },
    {
      controlType: "custom",
      label: "Display Name",
      itemFieldName: "displayName",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.displayName && form.displayName[lang]}
              addonBefore={
                lang === DEFAULT_LANGUAGE_KEY ? (
                  <>
                    {t(`app.common.Language.${lang}`)}
                    <span className="requiredLang">*</span>
                  </>
                ) : (
                  t(`app.common.Language.${lang}`)
                )
              }
              onChange={(e) =>
                handleFormGridStateChange("displayName", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
    {
      controlType: "custom",
      label: "Description",
      itemFieldName: "description",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.description && form.description[lang]}
              addonBefore={t(`app.common.Language.${lang}`)}
              onChange={(e) =>
                handleFormGridStateChange("description", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
  ];

  return (
    <NxpPanel titleContent="General">
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpPanel>
  );
};

export default DataSetGeneral;
