import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpTreeSelectOption,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import moment from "moment-timezone";

import { AddEntityFormData } from "./AddEntityModal";

interface AddEntityFormProps {
  form: AddEntityFormData;
  validationError: ValidationResult<AddEntityFormData>;
  handleFormGridStateChange: (
    fieldName: keyof AddEntityFormData,
    value: any
  ) => void;
  parentTreeSelectOptions: NxpTreeSelectOption[];
  pdsTemplateOptions: LabeledValue[];
}

const AddEntityForm: React.FC<AddEntityFormProps> = ({
  form,
  validationError,
  pdsTemplateOptions,
  handleFormGridStateChange,
  parentTreeSelectOptions,
}) => {
  const timezoneOptions = useMemo(() => {
    return moment.tz.names().map(
      (tz) =>
        ({
          label: tz,
          value: tz,
        } as LabeledValue)
    );
  }, []);

  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<AddEntityFormData>[] = [
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "name",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("EntityList.EntityCode"),
      itemFieldName: "entityCode",
      span: 12,
      required: true,
    },
    {
      controlType: "treeSelect",
      label: t("EntityList.Parent"),
      itemFieldName: "parentId",
      span: 24,
      required: true,
      controlProps: {
        value: form.parentId,
        onChange: (value: string | string[]) => {
          handleFormGridStateChange("parentId", value);
        },
        options: parentTreeSelectOptions,
        treeNodeFilterProp: "title",
      },
    },
    {
      controlType: "select",
      label: t("EntityList.PdsTemplate"),
      itemFieldName: "pdsTemplateId",
      span: 24,
      controlProps: {
        options: pdsTemplateOptions,
      },
    },
    {
      controlType: "select",
      label: t("EntityList.Timezone"),
      itemFieldName: "timezone",
      span: 24,
      required: true,
      controlProps: {
        value: form.timezone,
        options: timezoneOptions,
      },
    },
    {
      controlType: "textarea",
      label: t("app.common.Description"),
      itemFieldName: "description",
      span: 24,
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default AddEntityForm;
