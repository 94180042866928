import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import { AddDictionaryFormData } from "./AddDictionaryModal";

interface AddDictionaryFormProps {
  form: AddDictionaryFormData;
  validationError: ValidationResult<AddDictionaryFormData>;
  isEdit: boolean;
  dictionaryTypeOptions: LabeledValue[];
  handleFormGridStateChange: (
    fieldName: keyof AddDictionaryFormData,
    value: any
  ) => void;
}

const AddDictionaryForm: React.FC<AddDictionaryFormProps> = ({
  form,
  validationError,
  isEdit,
  dictionaryTypeOptions,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<AddDictionaryFormData>[] = [
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "name",
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      label: t("Dictionary.Type"),
      itemFieldName: "dictionaryType",
      span: 12,
      required: true,
      controlProps: {
        disabled: isEdit,
        value: form.dictionaryType,
        options: dictionaryTypeOptions,
        placeholder: t("app.common.PleaseSelect"),
      },
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default AddDictionaryForm;
