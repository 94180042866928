import React from "react";

import { Entity } from "../../../services/entity";
import { OrganisationTypeTableData } from "../../../services/organisationType";
import { User } from "../../../services/userDirectory";

export interface AppContextCache {
  users: User[];
  organisationTypes: OrganisationTypeTableData[];
  activeEntity?: Entity | null;
  activeEntityType?: "Root" | "Operation";
  activeUser?: User;
  accessPermissionList?: string[];
  language?: string;
  tenantId?: string | null;
  isPlatformAdmin?: boolean | null;
}

type AppContextType = AppContextCache & {
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  hasRight: (right: string) => boolean;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
