import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  NxpTreeSelectOption,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";
import { LabeledValue } from "antd/lib/select";
import AddEntityForm from "./AddEntityForm";

interface AddEntityModalProps {
  showAddEntityModal: boolean;
  parentTreeSelectOptions: NxpTreeSelectOption[];
  pdsTemplateOptions: LabeledValue[];
  submitting: boolean;
  setShowAddEntityModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (form: AddEntityFormData) => void;
  onParentEntityChange: (parentEntityId: string) => void;
}

export interface AddEntityFormData {
  name: string;
  entityCode: string;
  timezone: string;
  parentId?: string;
  pdsTemplateId?: string;
  description?: string;
}

const emptyForm: AddEntityFormData = {
  name: "",
  entityCode: "",
  timezone: "",
  parentId: undefined,
  description: undefined,
};

const AddEntityModal: React.FC<AddEntityModalProps> = ({
  showAddEntityModal,
  parentTreeSelectOptions,
  pdsTemplateOptions,
  submitting,
  setShowAddEntityModal,
  onSubmit,
  onParentEntityChange,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<AddEntityFormData>(emptyForm);

  const formSchema = yup.object().shape({
    name: yup.string().required(t("app.common.NameIsRequired")),
    entityCode: yup.string().required(t("EntityList.EntityCodeIsRequired")),
    timezone: yup.string().required(t("EntityList.TimezoneIsRequired")),
    parentId: yup.string().required(t("EntityList.ParentIsRequired")),
  });

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<AddEntityFormData>(form, formSchema, () => onSubmit(form));

  useEffect(() => {
    setForm(emptyForm);
    clearError();
  }, [showAddEntityModal, clearError]);

  const handleSubmitClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddEntityFormData,
    value: any
  ) => {
    const data: Partial<AddEntityFormData> = {};
    data[fieldName] = value;
    if (fieldName === "parentId") {
      onParentEntityChange(value);
      setForm({ ...form, ...data, pdsTemplateId: undefined });
    } else {
      setForm({ ...form, ...data });
    }
  };

  return (
    <NxpModal
      title={t("EntityList.AddNewEntity")}
      visible={showAddEntityModal}
      onCancel={() => setShowAddEntityModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="default"
            onClick={() => setShowAddEntityModal(false)}
            disabled={submitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton onClick={handleSubmitClick} loading={submitting}>
            {t("app.common.Submit")}
          </NxpButton>
        </>
      }
    >
      <AddEntityForm
        form={form}
        validationError={validationError}
        handleFormGridStateChange={handleFormGridStateChange}
        parentTreeSelectOptions={parentTreeSelectOptions}
        pdsTemplateOptions={pdsTemplateOptions}
      />
    </NxpModal>
  );
};

export default AddEntityModal;
