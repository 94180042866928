import React from "react";
import { createUseStyles } from "react-jss";

import { NxpTable } from "@nexploretechnology/nxp-ui";
import { ColumnType } from "antd/lib/table";

import { OrganisationTypeTableData } from "../../services/organisationType";

interface UserTableProps {
  data: OrganisationTypeTableData[];
  columns: ColumnType<OrganisationTypeTableData>[];
  onClickRow: (record: OrganisationTypeTableData) => void;
  actionField?: boolean;
}

const useStyles = createUseStyles((theme) => ({
  disabledRow: {
    cursor: ["not-allowed", "!important"],
    color: [theme.palette.dim, "!important"],
    "& > td.ant-table-cell": {
      backgroundColor: [theme.palette.backgroundDark, "!important"],
    },
  },
}));

const OrganisationTypeTable: React.FC<UserTableProps> = ({
  data,
  columns,
  onClickRow,
  actionField = true,
}) => {
  const classes = useStyles();
  return (
    <NxpTable
      rowKey={(item) => item.id}
      columns={columns}
      useDefaultHeight={actionField ? -70 : -500}
      dataSource={data}
      rowClassName={(item) => (item.isDisabled ? classes.disabledRow : "")}
      onRow={(record: OrganisationTypeTableData) => ({
        onClick: () => onClickRow(record),
      })}
    />
  );
};

export { OrganisationTypeTable };
