import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import {
  deleteSubcontractors,
  enableSubcontractors,
  getSubcontractors,
  Subcontractor,
} from "../../services/subcontractor";
import ManageCompaniesLayout from "./MangeCompaniesLayout";

interface ManageCompaniesContainerProps {}

const ManageCompaniesContainer: React.FC<ManageCompaniesContainerProps> =
  () => {
    const { t } = useTranslation();
    const { serviceConfig, hasRight } = useAppContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSubcontractor, setSelectedSubtractor] =
      useState<Subcontractor>();
    const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);

    const handleEditSubcontractor = (subcontractor: Subcontractor) => {
      setSelectedSubtractor(subcontractor);
      setShowCompanyModal(true);
    };
    const fetchList = useCallback(
      async () => await getSubcontractors(serviceConfig),
      [serviceConfig]
    );
    const [companiesListAsyncData, setCompaniesListAsyncData] = useAsync<
      Subcontractor[]
    >(() => fetchList(), [] as Subcontractor[], "fetch subcontractor error");
    const handleFetch = useCallback(async () => {
      try {
        setLoading(true);
        setCompaniesListAsyncData(() => fetchList());
        if (companiesListAsyncData?.error)
          notify.error(companiesListAsyncData?.error);
      } finally {
        setLoading(false);
      }
    }, [companiesListAsyncData, fetchList, setCompaniesListAsyncData]);
    const handleStateConfig = useCallback(
      async (subcontractor: Subcontractor) => {
        try {
          await (subcontractor?.isDisabled
            ? enableSubcontractors(subcontractor?.id, serviceConfig)
            : deleteSubcontractors(subcontractor?.id, serviceConfig));
        } catch (error) {
          notify.error(error);
        } finally {
          handleFetch();
        }
        notify.success(
          t(
            `ManageCompanies.action.${
              subcontractor?.isDisabled ? "enable" : "disable"
            }.success`
          )
        );
      },
      [handleFetch, serviceConfig, t]
    );
    return (
      <NxpSpin spinning={companiesListAsyncData?.loading || loading}>
        {hasRight("@company/company:view") && (
          <ManageCompaniesLayout
            selectedSubcontractor={selectedSubcontractor!}
            showCompanyModal={showCompanyModal}
            onClickStateConfig={handleStateConfig}
            onEditSubcontractor={handleEditSubcontractor}
            setSelectedSubtractor={setSelectedSubtractor}
            setShowCompanyModal={setShowCompanyModal}
            onFetchingSubcontractor={handleFetch}
            subcontractors={companiesListAsyncData?.data!}
          />
        )}
      </NxpSpin>
    );
  };

export default ManageCompaniesContainer;
