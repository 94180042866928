import React, { useContext, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import { notify, NxpModalNoPropagation } from "@nexploretechnology/nxp-ui";

import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import useAppContext from "../../../../hooks/useAppContext";
import { LangLabel } from "../../../../i18n/LangLabelEnum";
import {
  PdsSpectrum,
  PdsSpectrumForm,
  PdsTranslationFieldNameEnum,
  updatePdsSpectrum,
} from "../../../../services/pds";
import SpectrumModalForm from "./SpectrumForm";

const useStyles = createUseStyles((theme) => ({
  UpdateSpectrumModal: {},
}));

interface UpdateSpectrumModalProps {
  visible: boolean;
  pdsId: string;
  spectrum: PdsSpectrum;
  onSpectrumUpdated: () => void;
  onModalClose: () => void;
}

const UpdateSpectrumModal: React.FC<UpdateSpectrumModalProps> = ({
  visible,
  pdsId,
  spectrum,
  onSpectrumUpdated,
  onModalClose,
}) => {
  const appContext = useAppContext();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [loading, setLoading] = useState(false);

  const handleSave = async (form: PdsSpectrumForm) => {
    try {
      setLoading(true);
      await updatePdsSpectrum(
        spectrum.id,
        pdsId,
        form,
        appContext.serviceConfig
      );
      notify.actionCompleted();
      onModalClose();
      onSpectrumUpdated();
      onChangePromptUpdate(false);
    } catch (ex) {
      appContext.errorHandler(ex, `pds group update`);
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();

  const initForm = useMemo(() => {
    const displayName = {} as Record<LangLabel, string>;
    const description = {} as Record<LangLabel, string>;
    spectrum.translations.forEach((translation) => {
      switch (translation.fieldName) {
        case PdsTranslationFieldNameEnum.DISPLAY_NAME:
          displayName[translation.langKey] = translation.value;
          break;
        case PdsTranslationFieldNameEnum.DESCRIPTION:
          description[translation.langKey] = translation.value;
          break;
      }
    });
    return {
      displayName: displayName,
      code: spectrum.code,
      description: description,
    } as PdsSpectrumForm;
  }, [spectrum]);

  return visible ? (
    <NxpModalNoPropagation
      visible={visible}
      onCancel={onModalClose}
      className={classes.UpdateSpectrumModal}
      title="Edit PDS Group"
    >
      <SpectrumModalForm
        initForm={initForm}
        onSave={handleSave}
        onCancel={onModalClose}
        loading={loading}
      />
    </NxpModalNoPropagation>
  ) : null;
};

export default UpdateSpectrumModal;
