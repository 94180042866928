import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpSelectUserOption,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Tag } from "antd";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { appTheme } from "../../components/App/appTheme";
import { ADUser } from "../../services/userDirectory";
import { SelectUserFormData } from "./ManageRolePageContainer";

interface BatchAssignRoleFormProps {
  form: SelectUserFormData;
  validationError: ValidationResult<SelectUserFormData>;
  isSearchingAdUsers: boolean;
  userSelectOptions: (NxpSelectUserOption & { user?: ADUser; tag: string })[];
  handleFormGridStateChange: (
    fieldName: keyof SelectUserFormData,
    value: any
  ) => void;
  onSearchAdUsers: (searchString: string) => void;
}

const BatchAssignRoleForm: React.FC<BatchAssignRoleFormProps> = ({
  form,
  validationError,
  isSearchingAdUsers,
  userSelectOptions,
  handleFormGridStateChange,
  onSearchAdUsers,
}) => {
  const { t } = useTranslation();

  const renderTag = (props: CustomTagProps) => {
    const tag = userSelectOptions.find((o) => o.id === props.value)!.tag;
    return (
      <Tag
        style={{ fontSize: appTheme.fontSize.main, border: "none" }}
        closable={props.closable}
        onClose={props.onClose}
      >
        {tag}
      </Tag>
    );
  };

  const formItems: NxpFormGridItemProps<SelectUserFormData>[] = [
    {
      controlType: "selectUserMultiple",
      label: t("ManageRole.User"),
      itemFieldName: "users",
      span: 24,
      required: true,
      controlProps: {
        onSearch: onSearchAdUsers,
        loading: isSearchingAdUsers,
        options: userSelectOptions,
        tagRender: renderTag,
      },
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default BatchAssignRoleForm;
