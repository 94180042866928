import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  PlusCircleFilled,
  SearchOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  NxpButton,
  NxpCardList,
  NxpHeader,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Breadcrumb, Input } from "antd";
import clsx from "clsx";

import cardCover from "../../images/card/card-cover.png";
import { EntitySelectionContext } from "../../services/entity";

const useStyles = createUseStyles((theme) => ({
  entityListPage: {},
  highlight: {
    padding: 0,
    backgroundColor: theme.palette.marker,
  },
  entityPath: {
    fontSize: theme.fontSize.smaller,
    textTransform: "uppercase",
    color: theme.palette.textLabel,
    marginBottom: "2px !important",
    "& > span": {
      display: "inline-block",
    },
  },
  description: {
    fontSize: theme.fontSize.small,
  },
  activeEntity: {
    fontWeight: "bold",
  },
  action: {
    display: "flex",
  },
  nxpCard: {
    "& >ul.ant-card-actions": {
      display: "flex",
    },
  },
  favouriteButton: {
    marginLeft: theme.spacing(6),
  },
}));

interface EntityListPageLayoutProps {
  entities?: EntitySelectionContext[];
  searchValue: string;
  searchValueDebounced: string;
  activeEntityId?: string;
  showNewEntityButton: boolean;
  onSearch: (searchValue: string) => void;
  onCardClick: (entityId: string, e: React.MouseEvent) => void;
  setShowAddEntityModal: React.Dispatch<React.SetStateAction<boolean>>;
  isFavouriteEntity: (entityId: string) => boolean;
  setFavouriteEntity: (entityId: string) => void;
}

const EntityListPageLayout: React.FC<EntityListPageLayoutProps> = ({
  entities,
  searchValue,
  searchValueDebounced,
  activeEntityId,
  showNewEntityButton,
  onSearch,
  onCardClick,
  setShowAddEntityModal,
  isFavouriteEntity,
  setFavouriteEntity,
}) => {
  const { t } = useTranslation();

  const searchMatch = (text: string | undefined) =>
    (text || "")
      .toLocaleLowerCase()
      .includes(searchValueDebounced.toLocaleLowerCase());

  const classes = useStyles();

  return (
    <div className={classes.entityListPage}>
      <NxpHeader
        titleContent={t("app.layout.Entities")}
        actionContent={
          <div className={classes.action}>
            <Input
              disabled={!entities || !entities.length}
              prefix={<SearchOutlined />}
              value={searchValue}
              allowClear
              onChange={(e) => onSearch(e.target.value)}
            />
            {entities && showNewEntityButton && (
              <NxpButton
                onClick={() => setShowAddEntityModal(true)}
                icon={<PlusCircleFilled />}
              >
                {t("EntityList.NewEntity")}
              </NxpButton>
            )}
          </div>
        }
      />
      <NxpCardList
        isLoading={!entities}
        cards={
          entities
            ?.filter(
              (entity) =>
                !searchValueDebounced.trim() ||
                searchMatch(entity.breadcrumb) ||
                searchMatch(entity.name) ||
                searchMatch(entity.description)
            )
            .sort((a, b) => {
              if (isFavouriteEntity(a.id)) {
                if (isFavouriteEntity(b.id)) {
                  return sorterForString(a.breadcrumb, b.breadcrumb);
                } else {
                  return -1;
                }
              } else {
                if (isFavouriteEntity(b.id)) {
                  return 1;
                } else {
                  return sorterForString(a.breadcrumb, b.breadcrumb);
                }
              }
            })
            .map((entity) => ({
              id: entity.id,
              isActive: entity.id.toString() === activeEntityId,
              cover: <img src={cardCover} alt={`Entity - ${entity.name}`} />,
              className: classes.nxpCard,
              children: (
                <>
                  <Breadcrumb className={classes.entityPath}>
                    {entity.breadcrumb &&
                      entity.breadcrumb
                        .split(" / ")
                        .map((name: string, idx: number) => (
                          <Breadcrumb.Item key={`${entity.breadcrumb}-${idx}`}>
                            <Highlighter
                              highlightClassName={classes.highlight}
                              searchWords={[searchValueDebounced]}
                              autoEscape={true}
                              textToHighlight={name}
                            />
                          </Breadcrumb.Item>
                        ))}
                  </Breadcrumb>
                  <h3>
                    <Highlighter
                      highlightClassName={classes.highlight}
                      searchWords={[searchValueDebounced]}
                      autoEscape={true}
                      textToHighlight={entity.name}
                    />
                  </h3>
                  <p className={classes.description}>
                    <Highlighter
                      highlightClassName={classes.highlight}
                      searchWords={[searchValueDebounced]}
                      autoEscape={true}
                      textToHighlight={entity.description || ""}
                    />
                  </p>
                </>
              ),
              actions: [
                <NxpButton
                  type="text"
                  onClick={(e) => onCardClick(entity.id, e)}
                  className={clsx(
                    entity.id.toString() === activeEntityId &&
                      classes.activeEntity
                  )}
                >
                  {entity.id.toString() === activeEntityId
                    ? t("EntityList.CurrentEntity")
                    : t("EntityList.Select")}
                </NxpButton>,
                <NxpButton
                  type="text"
                  className={classes.favouriteButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFavouriteEntity(entity.id);
                  }}
                  icon={
                    isFavouriteEntity(entity.id) ? (
                      <StarFilled />
                    ) : (
                      <StarOutlined />
                    )
                  }
                />,
              ],
              onClick: (e) => onCardClick(entity.id, e),
            })) || []
        }
      />
    </div>
  );
};

export default EntityListPageLayout;
