import { ServiceConfig, apiRequest } from "../utils/backend";

export type CurrencyBase = {
  entityId: string;
  level1ParentOperationEntityId?: string;
  currency: string;
  description: string;
  rate: 1;
};

export type QuoteCurrencies = {
  entityId: string;
  level1ParentOperationEntityId?: string;
  currency: string;
  description: string;
  rate: number;
};

export type UpdateCurrencyRateBody = {
  currency: string;
  rate: number;
};

export type Currency = {
  code: string;
  description: string;
};

export const getCurrencyBase = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<CurrencyBase>(
    `${serviceConfig.coreServiceApiBaseUrl}/currency/${serviceConfig.entityId}/base-currency`,
    serviceConfig
  );
};

export const getQuoteCurrencies = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<QuoteCurrencies[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/currency/${serviceConfig.entityId}/conversion`,
    serviceConfig
  );
};

export const getAllCurrencies = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<Currency[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/currency`,
    serviceConfig
  );
};

export const updateCurrencyRate = async (
  serviceConfig: ServiceConfig,
  currencyRate: number,
  currency: string
) => {
  return await apiRequest<QuoteCurrencies>(
    `${serviceConfig.coreServiceApiBaseUrl}/currency/${serviceConfig.entityId}/conversion`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        currency: currency,
        rate: currencyRate,
      }),
    }
  );
};

export const createCurrency = async (
  serviceConfig: ServiceConfig,
  currencyRate: number,
  currency: string
) => {
  return await apiRequest<QuoteCurrencies>(
    `${serviceConfig.coreServiceApiBaseUrl}/currency/${serviceConfig.entityId}/conversion`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        currency: currency,
        rate: currencyRate,
      }),
    }
  );
};
