import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  notify,
  NxpButton,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import {
  AddEntityRole,
  createEntityRole,
  EditEntityRole,
  editEntityRole,
} from "../../services/entity";
import AddRoleForm from "./AddRoleForm";
import { ManageRoleTableData } from "./ManageRolePageContainer";

interface AddRoleModalProps {
  showAddRoleModal: boolean;
  isEdit: boolean;
  editData?: ManageRoleTableData;
  setShowAddRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface AddRoleFormData {
  roleName: string;
  roleCode: string;
  isAdmin: boolean;
  isDisabled: boolean;
  description?: string;
}

const emptyForm: AddRoleFormData = {
  roleName: "",
  roleCode: "",
  isAdmin: false,
  isDisabled: false,
  description: undefined,
};

const AddRoleModal: React.FC<AddRoleModalProps> = ({
  showAddRoleModal,
  isEdit,
  editData,
  setShowAddRoleModal,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const { activeEntity, activeEntityType, serviceConfig, errorHandler } =
    useAppContext();
  const [form, setForm] = useState<AddRoleFormData>(emptyForm);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formSchema = yup.object().shape({
    roleName: yup.string().required(t("ManageRole.RoleNameIsRequired")),
    roleCode: yup.string().required(t("ManageRole.RoleCodeIsRequired")),
  });

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      if (isEdit) {
        const entityRole: EditEntityRole = {
          roleName: form.roleName,
          roleCode: form.roleCode,
          description: form.description,
          isDisabled: form.isDisabled,
          isAdmin: form.isAdmin,
        };
        await editEntityRole(entityRole, editData!.id, serviceConfig);
      } else {
        if (!activeEntity || !activeEntityType) return;
        const addEntityRole: AddEntityRole = {
          entityId: activeEntity.id,
          entityType: activeEntityType,
          roleName: form.roleName,
          roleCode: form.roleCode,
          description: form.description,
          isDisabled: form.isDisabled,
          isAdmin: form.isAdmin,
        };
        await createEntityRole(addEntityRole, serviceConfig);
      }
      setShowAddRoleModal(false);
      notify.actionCompleted();
      setRefresh(true);
    } catch (err) {
      errorHandler(err, "create role");
    } finally {
      setSubmitting(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<AddRoleFormData>(form, formSchema, handleSubmit);

  useEffect(() => {
    if (isEdit && editData) {
      const editForm: AddRoleFormData = {
        roleName: editData.roleName,
        roleCode: editData.roleCode,
        isAdmin: editData.isAdmin,
        isDisabled: editData.isDisabled,
        description: editData.description,
      };
      setForm(editForm);
    } else {
      setForm(emptyForm);
    }
    clearError();
  }, [showAddRoleModal, isEdit, editData, clearError]);

  const handleSubmitClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddRoleFormData,
    value: any
  ) => {
    const data: Partial<AddRoleFormData> = {};
    data[fieldName] = value;
    setForm({ ...form, ...data });
  };

  return (
    <NxpModal
      title={isEdit ? t("app.common.EditRole") : t("app.common.AddRole")}
      visible={showAddRoleModal}
      onCancel={() => setShowAddRoleModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="default"
            onClick={() => setShowAddRoleModal(false)}
            loading={submitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton onClick={handleSubmitClick} loading={submitting}>
            {t("app.common.Submit")}
          </NxpButton>
        </>
      }
    >
      <AddRoleForm
        form={form}
        validationError={validationError}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleModal;
