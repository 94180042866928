import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";

import { NxpLabel, NxpModalConfirm } from "@nexploretechnology/nxp-ui";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import clsx from "clsx";

import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import {
  PdsDataSetEntry,
  PdsTranslationFieldNameEnum,
} from "../../../../services/pds";
import { DEFAULT_LANGUAGE_KEY } from "../../../../utils/const";
import {
  DataSetDetailsEntry,
  DataSetDetailsRelationState,
} from "../DataSetDetails";

const useStyles = createUseStyles((theme) => ({
  dependencyEditor: {
    marginTop: theme.spacing(1),
  },
  checkboxes: {
    maxHeight: "calc(100vh - 236px)",
    overflow: "auto",
    "& .ant-checkbox-wrapper": {
      color: theme.palette.dim,
    },
    "& .ant-checkbox-wrapper.taken": {
      color: theme.palette.text,
    },
    "& .ant-checkbox-wrapper.deleted": {
      textDecoration: "line-through",
    },
  },
}));

interface DependencyEditorProps {
  dataEntries: DataSetDetailsEntry[];
  relationState: DataSetDetailsRelationState;
  activeParentDataSetEntry?: PdsDataSetEntry;
  onDependencyAdd: (childItemUuid: string) => void;
  onDependencyRemove: (childItemUuid: string) => void;
}

const DependencyEditor: React.FC<DependencyEditorProps> = ({
  dataEntries,
  relationState,
  activeParentDataSetEntry,
  onDependencyAdd,
  onDependencyRemove,
}) => {
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);
  const [confirmDependencyChangeUuid, setConfirmDependencyChangeUuid] =
    useState<string>();

  const handleCheckedChange = (
    e: CheckboxChangeEvent,
    entry: DataSetDetailsEntry
  ) => {
    // if (
    //   relationState.relations.find(
    //     (relation) =>
    //       relation.childItemUuid === entry.itemUuid &&
    //       relation.parentDataSetEntryId !== activeParentDataSetEntry?.id
    //   )
    // ) {
    // }
    e.target.checked
      ? onDependencyAdd(entry.itemUuid)
      : onDependencyRemove(entry.itemUuid);
    onChangePromptUpdate(true);
  };

  const classes = useStyles();
  return (
    <div className={classes.dependencyEditor}>
      <NxpLabel>
        EDIT Dependencies
        {activeParentDataSetEntry
          ? ` (${activeParentDataSetEntry?.dataEntry.code} - ${
              activeParentDataSetEntry?.dataEntry.translations.find(
                (translation) =>
                  translation.fieldName ===
                    PdsTranslationFieldNameEnum.DISPLAY_NAME &&
                  translation.langKey === DEFAULT_LANGUAGE_KEY
              )?.value
            })`
          : ""}
      </NxpLabel>
      {!dataEntries.length && <p>No value entries defined.</p>}
      <div className={classes.checkboxes}>
        {dataEntries.map((entry) => (
          <div key={entry.itemUuid}>
            <Checkbox
              disabled={!activeParentDataSetEntry || entry.itemState.deleted}
              checked={
                !!relationState.relations.find(
                  (relation) =>
                    relation.childItemUuid === entry.itemUuid &&
                    relation.parentDataSetEntryId ===
                      activeParentDataSetEntry?.id
                )
              }
              className={clsx(
                relationState.relations.find(
                  (relation) => relation.childItemUuid === entry.itemUuid
                ) && "taken",
                entry.itemState.deleted && "deleted"
              )}
              onChange={(e) => handleCheckedChange(e, entry)}
            >
              {entry.code} - {entry.displayName[DEFAULT_LANGUAGE_KEY]}
            </Checkbox>
          </div>
        ))}
      </div>
      <NxpModalConfirm
        modalNoPropagation
        visible={!!confirmDependencyChangeUuid}
        onClose={() => setConfirmDependencyChangeUuid(undefined)}
        onConfirm={() => {
          if (confirmDependencyChangeUuid) {
            onDependencyAdd(confirmDependencyChangeUuid);
          }
        }}
      >
        Changing dependency parent will remove existing dependenies. OK to
        proceed?
      </NxpModalConfirm>
    </div>
  );
};

export default DependencyEditor;
