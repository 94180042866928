import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { notify, NxpTreeSelectOption } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import useAppContext from "../../hooks/useAppContext";
import { useDebouncedValue } from "../../hooks/useDebounce";
import {
  EntityAccessScope,
  getAllMyRoleAccessRight,
  getMyRoleAccessRight,
} from "../../services/accessControl";
import {
  AddOperationEntity,
  createOperationEntity,
  EntitySelectionContext,
  EntityStatus,
  EntityType,
  getEntity,
  getEntityBreadcrumb,
  getEntityType,
  getOperationEntity,
  OperationEntity,
  OperationEntityStatus,
} from "../../services/entity";
import { copyPds, getPdsList } from "../../services/pds";
import {
  createMyUserPreference,
  getMyUserPreference,
  patchMyUserPreference,
} from "../../services/userDirectory";
import { APPLICATION_CODE } from "../../utils/const";
import AddEntityModal, { AddEntityFormData } from "./AddEntityModal";
import EntityListPageLayout from "./EntityListPageLayout";

interface EntityListPageProps {}

const EntityListPage: React.FC<EntityListPageProps> = () => {
  const appContext = useAppContext();
  const {
    activeEntity,
    serviceConfig,
    errorHandler,
    onAppContextCacheItemUpdate,
  } = appContext;
  const navigate = useNavigate();

  const [userOperationEntities, setUserOperationEntities] = useState<
    OperationEntity[]
  >([]);
  const [entities, setEntities] = useState<EntitySelectionContext[]>();
  const [showAddEntityModal, setShowAddEntityModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const searchValueDebounced = useDebouncedValue(searchValue, 500, [
    searchValue,
  ]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
  const [parentTreeSelectOptions, setParentTreeSelectOptions] = useState<
    NxpTreeSelectOption[]
  >([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [entityAccessScopes, setEntityAccessScopes] = useState<
    EntityAccessScope[]
  >([]);
  const [redirect, setRedirect] = useState<{
    entityType: "Root" | "Operation";
    entityId: string;
  }>();
  const [favouriteEntities, setFavouriteEntities] = useState<string[]>([]);

  const [pdsTemplateOptions, setPdsTemplateOptions] = useState<LabeledValue[]>(
    []
  );

  const fetchPdsTemplates = async (parentEntityId: string) => {
    const pdsList = await getPdsList(
      parentEntityId,
      false,
      true,
      appContext.serviceConfig
    );
    setPdsTemplateOptions(
      pdsList.map((pds) => ({ label: pds.name, value: pds.id }))
    );
  };

  const handleParentEntityChange = (parentEntityId: string) => {
    if (parentEntityId) {
      fetchPdsTemplates(parentEntityId);
    } else {
      setPdsTemplateOptions([]);
    }
  };

  useEffect(() => {
    if (activeEntity === null) {
      onAppContextCacheItemUpdate("activeEntity", undefined);
    }
  }, [activeEntity, onAppContextCacheItemUpdate]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const findOption = useCallback(
    (entityId: string, option: NxpTreeSelectOption) => {
      if (option.id === entityId) {
        return option;
      } else if (option.children && option.children.length > 0) {
        let o: NxpTreeSelectOption | undefined;
        option.children.forEach((c) => {
          o = o ? o : findOption(entityId, c);
        });
        return o;
      }
    },
    []
  );

  const getSelectOption = useCallback(
    (
      operationEntity: OperationEntity,
      tree: NxpTreeSelectOption[],
      hasCreateAccessRight: boolean
    ) => {
      if (operationEntity.rootEntity) {
        const root = operationEntity.rootEntity;
        const rootOption = tree.find((rootOption) => rootOption.id === root.id);
        if (!rootOption) {
          tree.push({
            id: operationEntity.rootEntity.id,
            name: operationEntity.rootEntity.name,
            children: [
              {
                id: operationEntity.id,
                name: operationEntity.name,
                children: [],
                disabled: !hasCreateAccessRight,
                selectable: hasCreateAccessRight,
              },
            ],
            disabled: true,
            selectable: false,
          });
        } else if (!rootOption.children) {
          rootOption.children = [
            {
              id: operationEntity.id,
              name: operationEntity.name,
              children: [],
              disabled: !hasCreateAccessRight,
              selectable: hasCreateAccessRight,
            },
          ];
        } else {
          const findSelf = rootOption.children.find(
            (option) => option.id === operationEntity.id
          );
          if (!findSelf) {
            rootOption.children.push({
              id: operationEntity.id,
              name: operationEntity.name,
              children: [],
              disabled: !hasCreateAccessRight,
              selectable: hasCreateAccessRight,
            });
          } else {
            findSelf.disabled = !findSelf.disabled
              ? false
              : !hasCreateAccessRight;
            findSelf.selectable = findSelf.selectable
              ? true
              : hasCreateAccessRight;
          }
        }
      } else if (operationEntity.parentOperationEntity) {
        const parentEntity = operationEntity.parentOperationEntity;
        tree = getSelectOption(parentEntity, tree, false);
        let parentOption: NxpTreeSelectOption | undefined;
        tree.forEach((o) => {
          parentOption = parentOption
            ? parentOption
            : findOption(parentEntity.id, o);
        });
        if (parentOption) {
          if (!parentOption.children) {
            parentOption.children = [
              {
                id: operationEntity.id,
                name: operationEntity.name,
                children: [],
                disabled: !hasCreateAccessRight,
                selectable: hasCreateAccessRight,
              },
            ];
          } else {
            const findSelf = parentOption.children.find(
              (option) => option.id === operationEntity.id
            );
            if (!findSelf) {
              parentOption.children.push({
                id: operationEntity.id,
                name: operationEntity.name,
                children: [],
                disabled: !hasCreateAccessRight,
                selectable: hasCreateAccessRight,
              });
            } else {
              findSelf.disabled = !findSelf.disabled
                ? false
                : !hasCreateAccessRight;
              findSelf.selectable = findSelf.selectable
                ? true
                : hasCreateAccessRight;
            }
          }
        }
      }
      return tree;
    },
    [findOption]
  );

  const toParentTreeOptions = useCallback(
    (operationEntities: OperationEntity[]): NxpTreeSelectOption[] => {
      let tree: NxpTreeSelectOption[] = [];
      operationEntities.forEach((entity) => {
        tree = getSelectOption(entity, tree, true);
      });
      return tree;
    },
    [getSelectOption]
  );

  const isFavouriteEntity = useCallback(
    (entityId: string) => favouriteEntities.includes(entityId),
    [favouriteEntities]
  );

  const fetch = useCallback(async () => {
    try {
      const result = await Promise.all([
        getEntityType(serviceConfig),
        getAllMyRoleAccessRight(serviceConfig, "Operation"),
        getMyUserPreference(serviceConfig),
      ]);

      const entityTypes = result[0];
      const entityAccessScopes = result[1];
      const userPreference = result[2];
      setEntityTypes(entityTypes);
      setEntityAccessScopes(entityAccessScopes);

      if (!activeEntity) {
        const lastAccessedEntityId = userPreference.find(
          (u) =>
            u.application === APPLICATION_CODE &&
            u.parameter === "lastAccessedEntity"
        );
        if (lastAccessedEntityId) {
          if (
            lastAccessedEntityId.value &&
            lastAccessedEntityId.value.length > 0
          ) {
            if (
              entityAccessScopes.some(
                (scope) =>
                  scope.entity === lastAccessedEntityId.value &&
                  scope.scopes.some((s) => s === "@entity/entity:view")
              )
            ) {
              navigate(`/entities/${lastAccessedEntityId.value}/workspace`);
              return;
            } else {
              await patchMyUserPreference(serviceConfig, {
                application: APPLICATION_CODE,
                parameter: "lastAccessedEntity",
                value: "",
              });
            }
          }
        } else {
          await createMyUserPreference(serviceConfig, {
            application: APPLICATION_CODE,
            parameter: "lastAccessedEntity",
            value: "",
          });
        }
      }

      const favouriteEntity = userPreference.find(
        (u) =>
          u.application === APPLICATION_CODE &&
          u.parameter === "favouriteEntity"
      );
      let favouriteEntities: string[] = [];
      if (favouriteEntity) {
        favouriteEntities = favouriteEntity.value.split(",");
        setFavouriteEntities(favouriteEntities);
      } else {
        await createMyUserPreference(serviceConfig, {
          application: APPLICATION_CODE,
          parameter: "favouriteEntity",
          value: "",
        });
      }

      const operationEntityPromises = entityAccessScopes
        .filter(
          (entityScopes) =>
            entityScopes.scopes.includes("@entity/entity:view") ||
            entityScopes.scopes.includes("@entity/entity:create")
        )
        .map((entityScopes) => {
          return getOperationEntity(serviceConfig, entityScopes.entity, true);
        });

      const operationEntites = await Promise.all(operationEntityPromises);
      setUserOperationEntities(operationEntites);

      const entities: EntitySelectionContext[] = operationEntites
        .filter((operationEntity) =>
          entityAccessScopes.some(
            (entityScopes) =>
              entityScopes.entity === operationEntity.id &&
              entityScopes.scopes.includes("@entity/entity:view")
          )
        )
        .map((operationEntity) => {
          return {
            id: operationEntity.id,
            name: operationEntity.name,
            description: operationEntity.description,
            breadcrumb: getEntityBreadcrumb(operationEntity),
          };
        });

      setEntities(entities);
    } catch (ex) {
      errorHandler(ex, "fetch");
    }
  }, [serviceConfig, activeEntity, navigate, errorHandler]);

  useEffect(() => {
    setParentTreeSelectOptions(
      toParentTreeOptions(
        userOperationEntities.filter((operationEntity) =>
          entityAccessScopes.some(
            (entityScopes) =>
              entityScopes.entity === operationEntity.id &&
              entityScopes.scopes.includes("@entity/entity:create")
          )
        )
      )
    );
  }, [userOperationEntities, entityAccessScopes, toParentTreeOptions]);

  useEffect(() => {
    if (refresh) {
      setEntities(undefined);
      fetch();
      setRefresh(false);
    }
  }, [refresh, fetch]);

  const handleCardClick = (entityId: string, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    navigate(`/entities/${entityId}/workspace`);
  };

  const redirectToEntityAfterCreate = useCallback(
    async (entityType: "Root" | "Operation", entityId: string) => {
      const right = await getMyRoleAccessRight(
        serviceConfig,
        entityType,
        entityId
      );
      if (right.includes("@entity/entity:view")) {
        navigate(`/entities/${entityId}/workspace`);
      } else {
        redirectToEntityAfterCreate(entityType, entityId);
      }
    },
    [serviceConfig, navigate]
  );

  useEffect(() => {
    if (redirect) {
      setRedirect(undefined);
      setEntities(undefined);
      redirectToEntityAfterCreate(redirect.entityType, redirect.entityId);
    }
  }, [redirect, redirectToEntityAfterCreate]);

  const handleSubmit = async (form: AddEntityFormData) => {
    setSubmitting(true);
    try {
      const newEntity = await handleAddOperationEntity(form);
      if (form.pdsTemplateId) {
        await copyPds(
          form.pdsTemplateId,
          newEntity.id,
          appContext.serviceConfig
        );
      }
      notify.actionCompleted();
      setRedirect({ entityType: newEntity.type!, entityId: newEntity.id });
    } catch (err) {
      errorHandler(err, "create entity");
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddOperationEntity = async (form: AddEntityFormData) => {
    const entity = await getEntity(serviceConfig, form.parentId!);
    const accountEntityType = entityTypes.find((t) => t.name === "Account");
    const addOperationEntity: AddOperationEntity = {
      name: form.name,
      entityCode: form.entityCode,
      description: form.description,
      entityTypeId: accountEntityType!.id,
      entityStatus: OperationEntityStatus.ACTIVE,
      environmentStatus: EntityStatus.READY,
      timezone: form.timezone,
    };
    if (entity.type === "Root") {
      addOperationEntity.rootEntityId = entity.id;
    } else {
      addOperationEntity.parentOperationEntityId = entity.id;
    }
    const newOperationEntity = await createOperationEntity(
      addOperationEntity,
      serviceConfig
    );
    setShowAddEntityModal(false);
    return newOperationEntity;
  };

  const handleSetFavouriteEntity = useCallback(
    async (entityId: string) => {
      let newFavouriteEntites = [...favouriteEntities];
      if (isFavouriteEntity(entityId)) {
        newFavouriteEntites = newFavouriteEntites.filter(
          (id) => id !== entityId
        );
      } else {
        newFavouriteEntites.push(entityId);
      }
      try {
        await patchMyUserPreference(serviceConfig, {
          application: APPLICATION_CODE,
          parameter: "favouriteEntity",
          value: newFavouriteEntites.join(","),
        });
        notify.actionCompleted();
        setRefresh(true);
      } catch (ex) {
        errorHandler(ex, "fetch");
      }
    },
    [favouriteEntities, isFavouriteEntity, serviceConfig, errorHandler]
  );

  return (
    <>
      <EntityListPageLayout
        searchValue={searchValue}
        searchValueDebounced={searchValueDebounced}
        entities={entities}
        activeEntityId={activeEntity?.id}
        showNewEntityButton={parentTreeSelectOptions.length > 0}
        onSearch={handleSearch}
        onCardClick={handleCardClick}
        setShowAddEntityModal={setShowAddEntityModal}
        isFavouriteEntity={isFavouriteEntity}
        setFavouriteEntity={handleSetFavouriteEntity}
      />
      <AddEntityModal
        showAddEntityModal={showAddEntityModal}
        parentTreeSelectOptions={parentTreeSelectOptions}
        submitting={submitting}
        pdsTemplateOptions={pdsTemplateOptions}
        setShowAddEntityModal={setShowAddEntityModal}
        onSubmit={handleSubmit}
        onParentEntityChange={handleParentEntityChange}
      />
    </>
  );
};

export default EntityListPage;
