import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { DeleteOutlined } from "@ant-design/icons";
import { NxpButtonWithConfirm, NxpPanel } from "@nexploretechnology/nxp-ui";
import { Affix, Col, Row } from "antd";

import { Pds } from "../../../../services/pds";
import {
  findPdsDataSetByDataSetEntryId,
  findPdsDataSetEntry,
} from "../../pdsHelper";
import {
  DataSetDetailsEntry,
  DataSetDetailsRelationState,
} from "../DataSetDetails";
import DependencyEditor from "./DependencyEditor";
import DependencyTree from "./DependencyTree";

const useStyles = createUseStyles((theme) => ({
  dataSetDependency: {
    marginBottom: theme.spacing(3),
  },
  dependencyEditor: {},
}));

interface DataSetDependencyProps {
  dataSetId: string;
  dataEntries: DataSetDetailsEntry[];
  relationState: DataSetDetailsRelationState;
  pds: Pds;
  onRelationStateChange: (
    handler: (
      prevState: DataSetDetailsRelationState
    ) => DataSetDetailsRelationState
  ) => void;
}

const DataSetDependency: React.FC<DataSetDependencyProps> = ({
  dataSetId,
  dataEntries,
  relationState,
  pds,
  onRelationStateChange,
}) => {
  const classes = useStyles();

  const [parentDataSetId, setParentDataSetId] = useState<string>();

  useEffect(() => {
    // for setting the right parentDataSetId during initialization
    if (relationState.relations.length && !parentDataSetId) {
      const parentDataSet = findPdsDataSetByDataSetEntryId(
        relationState.relations[0].parentDataSetEntryId,
        pds
      );
      if (parentDataSet) {
        setParentDataSetId(parentDataSet.id);
      }
    }
  }, [
    parentDataSetId,
    pds,
    relationState.relations,
    relationState.relations.length,
  ]);

  const [activeParentDataSetEntryId, setActiveParentDataSetEntryId] =
    useState<string>();

  const handleParentDataSetChange = (value: string) => {
    setActiveParentDataSetEntryId(undefined);
    setParentDataSetId(value);
    handleClear();
  };

  const handleActiveParentDataSetEntryIdChange = (value: string) => {
    setActiveParentDataSetEntryId(value);
  };

  const handleClear = () => {
    onRelationStateChange(() => ({ relations: [], changed: true }));
  };

  const handleDependencyAdd = (childItemUuid: string) => {
    if (activeParentDataSetEntryId) {
      onRelationStateChange((prevState) => ({
        changed: true,
        relations: [
          ...prevState.relations,
          {
            childItemUuid,
            parentDataSetEntryId: activeParentDataSetEntryId,
          },
        ],
      }));
    }
  };

  const handleDependencyRemove = (childItemUuid: string) => {
    if (activeParentDataSetEntryId) {
      onRelationStateChange((prevState) => ({
        changed: true,
        relations: prevState.relations.filter(
          (dep) =>
            dep.childItemUuid !== childItemUuid ||
            dep.parentDataSetEntryId !== activeParentDataSetEntryId
        ),
      }));
    }
  };

  return (
    <NxpPanel
      titleContent="Value Dependencies"
      className={classes.dataSetDependency}
      extra={
        <NxpButtonWithConfirm
          type="text"
          onConfirm={handleClear}
          modalContent="Proceed to clear existing dependencies? Please note that the change only takes effect after saving."
          icon={<DeleteOutlined />}
        >
          Clear All
        </NxpButtonWithConfirm>
      }
    >
      <Row>
        <Col span={12}>
          <DependencyTree
            dataSetId={dataSetId}
            dataEntries={dataEntries}
            relationState={relationState}
            pds={pds}
            parentDataSetId={parentDataSetId}
            onParentDataSetChange={handleParentDataSetChange}
            activeParentDataSetEntryId={activeParentDataSetEntryId}
            onActiveParentDataSetEntryIdChange={
              handleActiveParentDataSetEntryIdChange
            }
          />
        </Col>
        <Col span={12} className={classes.dependencyEditor}>
          <Affix offsetTop={100}>
            <DependencyEditor
              dataEntries={dataEntries}
              relationState={relationState}
              activeParentDataSetEntry={
                activeParentDataSetEntryId
                  ? findPdsDataSetEntry(activeParentDataSetEntryId, pds)
                  : undefined
              }
              onDependencyAdd={handleDependencyAdd}
              onDependencyRemove={handleDependencyRemove}
            />
          </Affix>
        </Col>
      </Row>
    </NxpPanel>
  );
};

export default DataSetDependency;
