import React, { useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Pds } from "../../../services/pds";
import UpdatePdsTemplateModal from "./UpdatePdsTemplateModal";

interface UpdatePdsTemplateButtonProps {
  pds: Pds;
  onSpectrumUpdated: () => void;
}

const UpdatePdsTemplateButton: React.FC<UpdatePdsTemplateButtonProps> = ({
  pds,
  onSpectrumUpdated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton type="text" onClick={handleClick} icon={<EditOutlined />}>
        Template Details
      </NxpButton>
      <UpdatePdsTemplateModal
        visible={modalVisible}
        pds={pds}
        onSpectrumUpdated={onSpectrumUpdated}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default UpdatePdsTemplateButton;
