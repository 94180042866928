import React, {
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import AppChangePromptContext from "./AppChangePromptContext";
import { useRoutePrompt } from "./useRoutePrompt";

interface AppChangePromptContextProviderProps {
  initChangePrompt?: boolean;
  children: React.ReactNode;
}

const AppChangePromptContextWrap: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { changePrompt } = useContext(AppChangePromptContext);
  const location = useLocation();
  useRoutePrompt(
    "Proceed to discard unsaved changes?",
    changePrompt,
    location.pathname
  );
  return <>{children}</>;
};

const AppChangePromptContextProvider: React.FC<AppChangePromptContextProviderProps> =
  ({ initChangePrompt, children }) => {
    const [changePrompt, setChangePrompt] = useState(!!initChangePrompt);

    const handleChangePromptUpdate = useCallback((changePrompt: boolean) => {
      setChangePrompt(changePrompt);
    }, []);

    const routerLocation = useLocation();
    const locationPathRef = useRef(routerLocation.pathname);

    useLayoutEffect(() => {
      if (locationPathRef.current !== routerLocation.pathname) {
        locationPathRef.current = routerLocation.pathname;
        if (changePrompt) {
          setChangePrompt(false);
        }
      }
    }, [changePrompt, routerLocation.pathname]);

    return (
      <AppChangePromptContext.Provider
        value={{
          changePrompt,
          onChangePromptUpdate: handleChangePromptUpdate,
        }}
      >
        <AppChangePromptContextWrap>{children}</AppChangePromptContextWrap>
      </AppChangePromptContext.Provider>
    );
  };

export default AppChangePromptContextProvider;
