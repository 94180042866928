import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Input } from "antd";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { LangLabel } from "../../../../i18n/LangLabelEnum";
import {
  createPdsDataSet,
  PdsDataSetForm,
  PdsSpectrumForm,
} from "../../../../services/pds";
import {
  DEFAULT_LANGUAGE_KEY,
  DEFAULT_LANGUAGES,
} from "../../../../utils/const";
import { checkDefaultLanguageDefined } from "../../../../utils/multilingualHelper";

const useStyles = createUseStyles((theme) => ({
  createDataSetModal: {},
  languageInputDiv: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 3),
    marginTop: theme.spacing(0.5),
    alignContent: "flex-start",
    "& > span.ant-input-group-wrapper": {
      width: `calc(50% - ${theme.spacing(1.5)})`,
      flexShrink: 0,
      "& .ant-input-group-addon:first-child": {
        width: theme.spacing(8),
        fontSize: theme.fontSize.smaller,
        color: theme.palette.textLabel,
      },
    },
    "& span.requiredLang": {
      color: theme.palette.danger,
      marginLeft: 2,
    },
  },
}));

interface CreateDataSetModalProps {
  visible: boolean;
  spectrumId: string;
  onDataSetCreated: () => void;
  onModalClose: () => void;
}

const CreateDataSetModal: React.FC<CreateDataSetModalProps> = ({
  spectrumId,
  visible,
  onDataSetCreated,
  onModalClose,
}) => {
  const { serviceConfig, errorHandler } = useAppContext();

  const [form, setForm] = useState<PdsDataSetForm>({
    name: "",
    displayName: {} as Record<LangLabel, string>,
    code: "",
    description: {} as Record<LangLabel, string>,
  });

  const [loading, setLoading] = useState(false);

  const handleSaveValidated = useCallback(async () => {
    try {
      setLoading(true);
      await createPdsDataSet(spectrumId, form, serviceConfig);
      notify.actionCompleted();
      onModalClose();
      onDataSetCreated();
    } catch (ex) {
      errorHandler(ex, `pds attribute creation`);
    } finally {
      setLoading(false);
    }
  }, [
    serviceConfig,
    form,
    spectrumId,
    onDataSetCreated,
    onModalClose,
    errorHandler,
  ]);

  const formSchema = yup.object({
    displayName: yup
      .object()
      .nullable()
      .test("displayName", "Display Name in English required.", function (val) {
        return checkDefaultLanguageDefined(val);
      }) as any,
    code: yup
      .string()
      .nullable()
      .matches(
        /^\s*[A-Za-z0-9_]+\s*$/,
        "Only alphanumerics and underscore allowed."
      )
      .required("Code required."),
    description: yup.object() as any,
  });

  const [validationError, , , saveWithValidate] =
    useYupValidate<PdsSpectrumForm>(form, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof PdsDataSetForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    if (["displayName", "description"].includes(fieldName)) {
      setForm((prevState) => {
        const map = prevState[fieldName] as Record<LangLabel, string>;
        Object.entries(value as object).forEach(([lang, value]) => {
          map[lang as LangLabel] = value;
        });
        return {
          ...prevState,
          [fieldName]: map,
          name:
            fieldName === "displayName" ? map[LangLabel.EN] : prevState.name,
        };
      });
    } else {
      setForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<PdsDataSetForm>[] = [
    {
      controlType: "input",
      label: "Code",
      itemFieldName: "code",
      required: true,
      span: 12,
    },
    {
      controlType: "custom",
      label: "Display Name",
      itemFieldName: "displayName",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.displayName && form.displayName[lang]}
              addonBefore={
                lang === DEFAULT_LANGUAGE_KEY ? (
                  <>
                    {t(`app.common.Language.${lang}`)}
                    <span className="requiredLang">*</span>
                  </>
                ) : (
                  t(`app.common.Language.${lang}`)
                )
              }
              onChange={(e) =>
                handleFormGridStateChange("displayName", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
    {
      controlType: "custom",
      label: "Description",
      itemFieldName: "description",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.description && form.description[lang]}
              addonBefore={t(`app.common.Language.${lang}`)}
              onChange={(e) =>
                handleFormGridStateChange("description", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
  ];

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <NxpModal
      visible={visible}
      onCancel={onModalClose}
      className={classes.createDataSetModal}
      title="Add PDS Attribute"
      footer={
        <>
          <NxpButton disabled={loading} type="text" onClick={onModalClose}>
            Cancel
          </NxpButton>
          <NxpButton loading={loading} onClick={handleSave}>
            Save
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default CreateDataSetModal;
