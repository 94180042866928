import React from "react";

import { Subcontractor } from "../../../services/subcontractor";
import SubcontractorLayout from "./SubcontractorListingLayout";

export interface SubcontractorListingContainerProps {
  onClickStateConfig: (subcontractor: Subcontractor) => void;
  onEditSubcontractor: (subcontractor: Subcontractor) => void;
  subcontractors: Subcontractor[];
}

const SubcontractorListingContainer: React.FC<SubcontractorListingContainerProps> =
  ({ onEditSubcontractor, onClickStateConfig, subcontractors }) => {
    const handleRowClick = (subcontractor: Subcontractor) => {
      onEditSubcontractor(subcontractor);
    };
    return (
      <SubcontractorLayout
        subcontractors={subcontractors}
        onRowClick={handleRowClick}
        onClickStateConfig={onClickStateConfig}
      />
    );
  };

export default SubcontractorListingContainer;
