import React, { useCallback, useContext, useState } from "react";
import { createUseStyles } from "react-jss";

import { notify } from "@nexploretechnology/nxp-ui";

import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import AppChangePromptModal from "../../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../../hooks/useAppContext";
import { LangLabel } from "../../../../i18n/LangLabelEnum";
import {
  createPds,
  createPdsSpectrum,
  PdsSpectrumForm,
} from "../../../../services/pds";
import SpectrumModalForm from "./SpectrumForm";

const useStyles = createUseStyles((theme) => ({
  createSpectrumModal: {},
}));

interface CreateSpectrumModalProps {
  visible: boolean;
  pdsId?: string;
  onSpectrumCreated: () => void;
  onModalClose: () => void;
}

const CreateSpectrumModal: React.FC<CreateSpectrumModalProps> = ({
  pdsId,
  visible,
  onSpectrumCreated,
  onModalClose,
}) => {
  const { activeEntity, serviceConfig, errorHandler } = useAppContext();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    async (form: PdsSpectrumForm) => {
      try {
        if (!activeEntity) return;
        setLoading(true);
        let _pdsId = pdsId;
        if (!_pdsId) {
          const pds = await createPds(
            { enabled: true, operationEntityId: activeEntity.id },
            serviceConfig
          );
          _pdsId = pds.id;
        }
        await createPdsSpectrum(_pdsId, form, serviceConfig);
        notify.actionCompleted();
        onModalClose();
        onSpectrumCreated();
        onChangePromptUpdate(false);
      } catch (ex) {
        errorHandler(ex, `pds group creation`);
      } finally {
        setLoading(false);
      }
    },
    [
      activeEntity,
      pdsId,
      serviceConfig,
      onSpectrumCreated,
      onModalClose,
      onChangePromptUpdate,
      errorHandler,
    ]
  );

  const classes = useStyles();

  return visible ? (
    <AppChangePromptModal
      visible={visible}
      onCancel={onModalClose}
      className={classes.createSpectrumModal}
      title="Add PDS Group"
    >
      <SpectrumModalForm
        initForm={{
          name: "",
          code: "",
          displayName: {} as Record<LangLabel, string>,
          description: {} as Record<LangLabel, string>,
        }}
        onSave={handleSave}
        onCancel={onModalClose}
        loading={loading}
      />
    </AppChangePromptModal>
  ) : null;
};

export default CreateSpectrumModal;
