import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router";

import { EditOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpHeader,
  NxpMainSplitter,
  NxpSpin,
} from "@nexploretechnology/nxp-ui";
import { Space, Tree } from "antd";
import { DataNode } from "antd/lib/tree";

import useAppContext from "../../hooks/useAppContext";
import { DictionarySelectItem } from "./DictionaryPageContainer";
import DictionaryTable from "./DictionaryTable";

interface DictionaryPageLayoutProps {
  dictionaryTree?: DataNode[];
  selectedTreeKey?: string;
  selectedItem?: DictionarySelectItem;
  selectItemsMap: Map<string, DictionarySelectItem>;
  loading: boolean;
  onTreeSelect: (keys: string) => void;
  onClickAddDictionary: () => void;
  onClickAddItem: () => void;
  onClickHeaderEdit: () => void;
  onClickTableRowEdit: (item: DictionarySelectItem) => void;
}

const useStyles = createUseStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "end",
    "> h1": {
      margin: 0,
    },
  },
  action: {
    display: "flex",
  },
  main: {
    margin: theme.spacing(2),
  },
  treePanel: {
    margin: theme.spacing(4, 2),
  },
}));

const DictionaryPageLayout: React.FC<DictionaryPageLayoutProps> = ({
  dictionaryTree,
  selectedTreeKey,
  selectedItem,
  selectItemsMap,
  loading,
  onTreeSelect,
  onClickAddDictionary,
  onClickAddItem,
  onClickHeaderEdit,
  onClickTableRowEdit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const { hasRight } = useAppContext();

  const [tableData, setTableData] = useState<DictionarySelectItem[]>([]);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (selectedItem) {
      setTableData(
        selectedItem.members
          ? selectedItem.members
              .map((m) => selectItemsMap.get(m)!)
              .filter((m) => m !== undefined)
          : []
      );
      setTitle(selectedItem.label);
    } else {
      setTableData([]);
      setTitle(t("app.layout.Dictionaries"));
    }
  }, [selectedItem, selectItemsMap, t]);

  useEffect(() => {
    if (routerLocation.state === "OpenaddDictionary") {
      onClickAddDictionary();
    }
  }, [onClickAddDictionary, routerLocation.state]);
  return (
    <>
      <NxpSpin spinning={loading}>
        <NxpMainSplitter>
          <div className={classes.treePanel}>
            <Space direction="vertical" size="large">
              {hasRight("@dictionary/dictionary:create") && (
                <NxpButton onClick={onClickAddDictionary} loading={loading}>
                  {t("Dictionary.AddDictionary")}
                </NxpButton>
              )}
              {dictionaryTree && selectedTreeKey && (
                <Tree
                  selectedKeys={[selectedTreeKey as React.Key]}
                  treeData={dictionaryTree.sort((a, b) =>
                    (a.title || "") > (b.title || "") ? 1 : -1
                  )}
                  onSelect={(keys) => onTreeSelect(keys[0] as string)}
                />
              )}
            </Space>
          </div>
          <div className={classes.main}>
            <NxpHeader
              titleContent={
                <>
                  {title}
                  {selectedItem && hasRight("@dictionary/dictionary:edit") && (
                    <NxpButton
                      icon={<EditOutlined />}
                      type="text"
                      onClick={onClickHeaderEdit}
                    >
                      {t("app.common.Edit")}
                    </NxpButton>
                  )}
                </>
              }
              onBackClick={() => navigate(-1)}
              actionContent={
                hasRight("@dictionary/dictionary:edit") && (
                  <div className={classes.action}>
                    <NxpButton
                      disabled={!selectedItem}
                      loading={loading}
                      onClick={onClickAddItem}
                    >
                      {t("Dictionary.AddItem")}
                    </NxpButton>
                  </div>
                )
              }
            />
            <DictionaryTable
              loading={loading}
              tableData={tableData}
              onClickTableRowEdit={onClickTableRowEdit}
            />
          </div>
        </NxpMainSplitter>
      </NxpSpin>
    </>
  );
};

export default DictionaryPageLayout;
