import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { UsersTableItem } from "./ManageUsersPageContainer";

interface UserDetailModalProps {
  detailData: UsersTableItem;
  visible: boolean;
  submitting: boolean;
  isEdit: boolean;
  onCancel: () => void;
  onSubmit: (form: UsersTableItem) => void;
}

const UserDetailModal: React.FC<UserDetailModalProps> = ({
  detailData,
  visible,
  submitting,
  isEdit,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const formSchema: yup.ObjectSchema<any> = yup.object().shape({
    salutation: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    title: yup.string().required(),
    email: yup.string().nullable().email(),
    isPrimaryContact: yup.boolean().required(),
    startDate: yup.date().required(),
    status: yup.string().required(),
  });

  const [form, setForm] = useState<UsersTableItem>(detailData);
  const [validationError, , clearError, saveWithValidate] = useYupValidate(
    form,
    formSchema,
    () => onSubmit(form)
  );
  const handleSubmitClick = () => {
    saveWithValidate(undefined);
  };

  useEffect(() => {
    setForm(detailData);
    clearError();
  }, [detailData, visible, clearError]);

  const formItems: NxpFormGridItemProps<UsersTableItem>[] = [
    {
      controlType: "select",
      label: t("ManageUsers.Salutation"),
      itemFieldName: "salutation",
      span: 8,
      required: true,
      controlProps: {
        disabled: !isEdit,
        options: [
          { label: "Mr.", value: "Mr." },
          { label: "Mrs.", value: "Mrs." },
          { label: "Miss", value: "Miss" },
          { label: "Dr.", value: "Dr." },
          { label: "Ms.", value: "Ms." },
        ],
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.FirstName"),
      itemFieldName: "firstName",
      span: 8,
      required: true,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.LastName"),
      itemFieldName: "lastName",
      span: 8,
      required: true,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.JobTitle"),
      itemFieldName: "title",
      span: 8,
      required: true,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.Telephone"),
      itemFieldName: "officeNumber",
      span: 8,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.Mobile"),
      itemFieldName: "mobileNumber",
      span: 8,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("app.common.Email"),
      itemFieldName: "email",
      span: 8,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "checkbox",
      label: t("ManageUsers.ContractorPrimaryContact"),
      itemFieldName: "isPrimaryContact",
      span: 8,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      startOnNewRow: true,
      controlType: "datePicker",
      label: t("ManageUsers.StartDate"),
      itemFieldName: "startDate",
      span: 8,
      required: true,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "datePicker",
      label: t("ManageUsers.EndDate"),
      itemFieldName: "endDate",
      span: 8,
      controlProps: {
        disabled: !isEdit,
      },
    },
    {
      controlType: "select",
      label: t("ManageUsers.Status"),
      itemFieldName: "status",
      span: 8,
      required: true,
      controlProps: {
        options: [
          { label: "US_FULLTIME", value: "US_FULLTIME" },
          { label: "US_PARTTIME", value: "US_PARTTIME" },
        ],
        disabled: !isEdit,
      },
    },
    {
      controlType: "input",
      label: t("ManageUsers.Notes"),
      itemFieldName: "comments",
      span: 24,
      controlProps: {
        disabled: !isEdit,
      },
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof UsersTableItem,
    value: any
  ) => {
    const data: Partial<UsersTableItem> = {};
    data[fieldName] = value;
    setForm({ ...form, ...data });
  };

  return (
    <NxpModal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      width="large"
      title={detailData.name}
      footer={
        isEdit && (
          <>
            <NxpButton
              type="default"
              onClick={() => onCancel()}
              loading={submitting}
            >
              {t("app.common.DiscardChanges")}
            </NxpButton>
            <NxpButton onClick={handleSubmitClick} loading={submitting}>
              {t("app.common.Save")}
            </NxpButton>
          </>
        )
      }
    >
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default UserDetailModal;
