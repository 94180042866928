import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";

import { EntityRole } from "../../services/entity";
import { AssignRoleFormData } from "./AssignRoleModal";

interface AssignRoleFormProps {
  form: AssignRoleFormData;
  validationError: ValidationResult<AssignRoleFormData>;
  entityRoles: Array<EntityRole>;
  handleFormGridStateChange: (
    fieldName: keyof AssignRoleFormData,
    value: any
  ) => void;
}

const AssignRoleForm: React.FC<AssignRoleFormProps> = ({
  form,
  validationError,
  entityRoles,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<AssignRoleFormData>[] = [
    {
      controlType: "checkboxGroup",
      label: t("app.common.Role"),
      itemFieldName: "roles",
      span: 24,
      required: true,
      controlProps: {
        style: { display: "grid" },
        options: entityRoles.map((role) => {
          return {
            value: role.id,
            label: role.roleName,
          };
        }),
      },
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default AssignRoleForm;
