import { useRef } from "react";
import { useParams } from "react-router";

import useAppContext from "../../../hooks/useAppContext";
import {
  getIsPlatformAdmin,
  getMyRoleAccessRight,
} from "../../../services/accessControl";
import { getEntity } from "../../../services/entity";
import { getOrganisationTypes } from "../../../services/organisationType";
import {
  createMyUserPreference,
  getMyUserPreference,
  getUser,
  getUsers,
  patchMyUserPreference,
} from "../../../services/userDirectory";
import { APPLICATION_CODE } from "../../../utils/const";

// for setting cache data in app context based on route param
const useRouteAppContextUpdate = async () => {
  const appContext = useAppContext();
  const {
    activeUser,
    activeEntity,
    serviceConfig,
    isPlatformAdmin,
    onAppContextCacheItemUpdate,
  } = appContext;
  const loadingEntityIdRef = useRef("");
  const loadingMyself = useRef(false);
  const loadingUserPreference = useRef(false);
  const loadingIsPlatformAdmin = useRef(false);

  const { entityId } = useParams<"entityId">();

  if (!serviceConfig.token) return;

  if (
    (isPlatformAdmin === null || isPlatformAdmin === undefined) &&
    !loadingIsPlatformAdmin.current
  ) {
    loadingIsPlatformAdmin.current = true;
    onAppContextCacheItemUpdate(
      "isPlatformAdmin",
      await getIsPlatformAdmin(serviceConfig)
    );
  }

  if (!activeUser && !loadingMyself.current) {
    loadingMyself.current = true;
    const user = await getUser(serviceConfig);
    onAppContextCacheItemUpdate("activeUser", user);
  }

  if (activeUser && !loadingUserPreference.current) {
    loadingUserPreference.current = true;
    const userPreference = await getMyUserPreference(serviceConfig);
    const languagePreference = userPreference.find(
      (u) => u.application === APPLICATION_CODE && u.parameter === "language"
    );
    if (languagePreference) {
      onAppContextCacheItemUpdate("language", languagePreference.value);
    } else {
      const newUserPreference = await createMyUserPreference(serviceConfig, {
        application: APPLICATION_CODE,
        parameter: "language",
        value: "en",
      });
      onAppContextCacheItemUpdate("language", newUserPreference.value);
    }
  }
  if (
    entityId &&
    activeEntity?.id !== entityId &&
    loadingEntityIdRef.current !== entityId
  ) {
    loadingEntityIdRef.current = entityId;

    try {
      const entity = await getEntity(serviceConfig, entityId);
      const userRights = await getMyRoleAccessRight(
        serviceConfig,
        entity.type!,
        entityId
      );
      if (userRights.includes("@user/user:view")) {
        const users = await getUsers(serviceConfig);
        onAppContextCacheItemUpdate("users", users);
      } else {
        onAppContextCacheItemUpdate("users", []);
      }

      if (userRights.includes("@company/type:view")) {
        const organisationTypes = await getOrganisationTypes(serviceConfig);
        onAppContextCacheItemUpdate("organisationTypes", organisationTypes);
      } else {
        onAppContextCacheItemUpdate("organisationTypes", []);
      }

      if (!userRights.includes("@entity/entity:view")) {
        onAppContextCacheItemUpdate("activeEntityType", undefined);
        onAppContextCacheItemUpdate("activeEntity", null);
        onAppContextCacheItemUpdate("tenantId", null);
        onAppContextCacheItemUpdate("accessPermissionList", undefined);
        patchMyUserPreference(serviceConfig, {
          application: APPLICATION_CODE,
          parameter: "lastAccessedEntity",
          value: "",
        });
      } else {
        onAppContextCacheItemUpdate("activeEntityType", entity.type);
        onAppContextCacheItemUpdate("activeEntity", entity);
        onAppContextCacheItemUpdate("tenantId", entity.tenantId);
        onAppContextCacheItemUpdate("accessPermissionList", userRights);
        patchMyUserPreference(serviceConfig, {
          application: APPLICATION_CODE,
          parameter: "lastAccessedEntity",
          value: entityId,
        });
      }
    } catch {
      onAppContextCacheItemUpdate("activeEntityType", undefined);
      onAppContextCacheItemUpdate("activeEntity", null);
      onAppContextCacheItemUpdate("tenantId", null);
      onAppContextCacheItemUpdate("accessPermissionList", undefined);
      patchMyUserPreference(serviceConfig, {
        application: APPLICATION_CODE,
        parameter: "lastAccessedEntity",
        value: "",
      });
    }
  }
};

export default useRouteAppContextUpdate;
