import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { get, isEmpty } from "lodash";
import * as yup from "yup";

import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import AppChangePromptModal from "../../../components/AppChangePrompt/AppChangePromptModal";
import useAppContext from "../../../hooks/useAppContext";
import {
  createOrganisationType,
  OrganisationType,
  patchOrganisationType,
} from "../../../services/organisationType";

type OrganisationTypeForm = OrganisationType;
interface OrganisationTypeModalProps {
  editOrganisationType: OrganisationType;
  show: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

const OrganisationTypeModal: React.FC<OrganisationTypeModalProps> = ({
  editOrganisationType,
  show,
  onClose,
  onRefresh,
}) => {
  const { t } = useTranslation();
  let emptyOrganisationType: OrganisationTypeForm = {
    id: "",
    name: "",
  };
  const appContext = useAppContext();
  const { serviceConfig } = appContext;
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [organisationType, setOrganisationType] =
    useState<OrganisationTypeForm>(emptyOrganisationType);

  const formSchema = yup.object({
    name: yup.string().nullable().required("Company Name is required."),
  });
  const formItems: NxpFormGridItemProps<OrganisationTypeForm>[] = [
    {
      controlType: "input",
      label: t("OrganisationType.Modal.organisationTypeField"),
      itemFieldName: "name",
      required: true,
      startOnNewRow: true,
      span: 24,
    },
  ];

  const handleCancelButton = () => {
    setOrganisationType(emptyOrganisationType);
    onClose();
  };

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleSaveValidated = async () => {
    if (!isEmpty(editOrganisationType)) {
      setSaving(true);
      try {
        const patchOrganisationTypeRes = await patchOrganisationType(
          serviceConfig,
          { name: organisationType?.name },
          get(editOrganisationType, "id")
        );
        if (patchOrganisationTypeRes) {
          notify.success(t("OrganisationType.updated.success"));
          onRefresh();
          onClose();
          setSaving(false);
        }
      } catch (error: any) {
        notify.error(error);
      }
    } else {
      setSaving(true);
      try {
        const createOrganisationTypeRes = await createOrganisationType(
          serviceConfig,
          organisationType
        );
        if (createOrganisationTypeRes) {
          notify.success(t("OrganisationType.created.success"));
          onRefresh();
          onClose();
          setSaving(false);
        }
      } catch (error: any) {
        notify.error(error);
      }
    }
    onChangePromptUpdate(false);
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<any>(
    organisationType,
    formSchema,
    handleSaveValidated
  );
  const handleFormStateChange = useCallback(
    (fieldName: keyof typeof organisationType, value: unknown) => {
      onChangePromptUpdate(true);
      setOrganisationType((prevState: any) => ({
        ...prevState,
        [fieldName]: value,
      }));
    },
    [onChangePromptUpdate]
  );

  useEffect(() => {
    if (show) {
      if (!isEmpty(editOrganisationType)) {
        setOrganisationType({
          name: editOrganisationType.name,
          id: editOrganisationType?.id,
        });
      }
    } else
      setOrganisationType(
        emptyOrganisationType as unknown as OrganisationTypeForm
      );
    return () => clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <AppChangePromptModal
      title={
        !isEmpty(editOrganisationType)
          ? t("OrganisationType.Modal.title.edit")
          : t("OrganisationType.Modal.title.add")
      }
      width="small"
      visible={show}
      onCancel={handleCancelButton}
      showMandatoryLabel={true}
      footer={
        <>
          <NxpButton onClick={handleSave} loading={saving}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={organisationType}
        onFormStateChange={handleFormStateChange}
      />
    </AppChangePromptModal>
  );
};

export default OrganisationTypeModal;
