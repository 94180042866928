import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Nxp404 } from "@nexploretechnology/nxp-ui";

import AccessRightPage from "../../../pages/AccessRightPage";
import DictionaryPage from "../../../pages/DictionaryPage";
import DictionaryTypePage from "../../../pages/DictionaryTypePage";
import EntityListPage from "../../../pages/EntityListPage";
import ManageCompaniesPage from "../../../pages/ManageCompaniesPage";
import ManageRolesPage from "../../../pages/ManageRolePage";
import ManageUsersPage from "../../../pages/ManageUsersPage";
import OrganisationTypePage from "../../../pages/OrganisationTypePage";
import PdsPage from "../../../pages/PdsPage";
import PdsTemplatesPage from "../../../pages/PdsTemplatesPage";
import WorkspacePage from "../../../pages/WorkspacePage";
import { default as AppProtectedPage } from "./AppProtectedPage";
import CurrencyExchangePage from "../../../pages/CurrencyExchangePage/CurrencyExchangePage";
import EditEntityPage from "../../../pages/EditEntityPage/EditEntityPage";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/entities/:entityId/workspace`}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<WorkspacePage />}
            />
          }
        />

        <Route
          path={`/entities/:entityId/roles`}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<ManageRolesPage />}
            />
          }
        />

        <Route
          path={`/entities/:entityId/access`}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<AccessRightPage />}
            />
          }
        />

        <Route
          path={`/entities/:entityId/dictionary`}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<DictionaryPage />}
            />
          }
        />

        <Route
          path={`/entities/:entityId/manage-users`}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<ManageUsersPage />}
            />
          }
        />

        <Route
          path={`/dictionary-type`}
          element={<AppProtectedPage component={<DictionaryTypePage />} />}
        />

        <Route
          path={`/entities/:entityId/company/manage-company`}
          element={<AppProtectedPage component={<ManageCompaniesPage />} />}
        />

        <Route
          path={`/entities/:entityId/manage-currency`}
          element={<AppProtectedPage component={<CurrencyExchangePage />} />}
        />

        <Route
          path={`/entities/:entityId/edit-entity`}
          element={<AppProtectedPage component={<EditEntityPage />} />}
        />

        <Route
          path={`/company/organisation-type`}
          element={<AppProtectedPage component={<OrganisationTypePage />} />}
        />

        <Route
          path={"/entities/:entityId/pds/datasets/:dataSetId"}
          element={<AppProtectedPage component={<PdsPage />} />}
        />

        <Route
          path={"/entities/:entityId/pds"}
          element={<AppProtectedPage component={<PdsPage />} />}
        />

        <Route
          path={
            "/entities/:entityId/pds-templates/:templateId/datasets/:dataSetId"
          }
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<PdsTemplatesPage />}
            />
          }
        />

        <Route
          path={"/entities/:entityId/pds-templates/:templateId"}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<PdsTemplatesPage />}
            />
          }
        />

        <Route
          path={"/entities/:entityId/pds-templates"}
          element={
            <AppProtectedPage
              requireActiveEntity
              component={<PdsTemplatesPage />}
            />
          }
        />

        <Route
          path={`/entities`}
          element={<AppProtectedPage component={<EntityListPage />} />}
        />

        <Route path="/" element={<Navigate to={`/entities`} />}></Route>

        <Route
          path="/*"
          element={<AppProtectedPage component={<Nxp404 />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
