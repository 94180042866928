import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { applyFilter, notify, NxpFilter } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import {
  createDictionaryType,
  CreateDictionaryType,
  deleteDictionaryType,
  DictionaryNature,
  DictionaryType,
  EditDictionaryType,
  getDictionaryTypes,
  patchDictionaryType,
} from "../../services/dictionary";
import AddDictionaryTypeModal, {
  AddDictionaryTypeFormData,
} from "./AddDictionaryTypeModal";
import DictionaryTypePageLayout from "./DictionaryTypePageLayout";

interface DictionaryTypePageContainerProps {}

const DictionaryTypePageContainer: React.FC<DictionaryTypePageContainerProps> =
  () => {
    const navigate = useNavigate();

    const { serviceConfig, activeEntity, errorHandler } = useAppContext();

    const [dictionaryTypes, setDictionaryTypes] = useState<DictionaryType[]>(
      []
    );
    const [refresh, setRefresh] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<AddDictionaryTypeFormData>();

    const fetch = useCallback(async () => {
      try {
        const dictionaryTypes = await getDictionaryTypes(serviceConfig);
        setDictionaryTypes(dictionaryTypes);
      } catch (err) {
        errorHandler(err, "fetch dictionary types");
      }
    }, [serviceConfig, errorHandler]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    useEffect(() => {
      if (refresh) {
        fetch();
        setRefresh(false);
      }
    }, [refresh, fetch]);

    const handleClickAddType = () => {
      setShowModal(true);
      setIsEdit(false);
      setEditData(undefined);
    };

    const handleClickTableRowEdit = (editItem: DictionaryType) => {
      setShowModal(true);
      setIsEdit(true);
      const editData = {
        id: editItem.id,
        name: editItem.name,
        dataType: editItem.dataType,
        application: editItem.application,
        description: editItem.description,
      } as AddDictionaryTypeFormData;
      setEditData(editData);
    };

    const handleSubmit = async (
      form: AddDictionaryTypeFormData,
      redirect: boolean
    ) => {
      setSubmitting(true);
      if (!isEdit) {
        try {
          const addType = {
            name: form.name,
            slug: form.name,
            description: form.description,
            nature: DictionaryNature.APPLICATION,
            application: form.application,
            dataType: form.dataType,
          } as CreateDictionaryType;
          const newDictionaryType = await createDictionaryType(
            serviceConfig,
            addType
          );
          notify.actionCompleted();
          setRefresh(true);
          setShowModal(false);
          if (redirect) {
            if (activeEntity) {
              navigate(`/entities/${activeEntity.id}/dictionary`, {
                state: "OpenaddDictionary",
              });
            }
          }
          return newDictionaryType;
        } catch (err) {
          errorHandler(err, "add type");
        } finally {
          setSubmitting(false);
        }
      } else {
        try {
          const editType = {
            description: form.description,
            dataType: form.dataType,
          } as EditDictionaryType;
          const newDictionaryType = await patchDictionaryType(
            serviceConfig,
            form.id!,
            editType
          );
          notify.actionCompleted();
          setRefresh(true);
          setShowModal(false);
          return newDictionaryType;
        } catch (err) {
          errorHandler(err, "edit type");
        } finally {
          setSubmitting(false);
        }
      }
    };

    const handleDelete = async (dictionaryTypeId: string) => {
      setSubmitting(true);
      try {
        const result = await deleteDictionaryType(
          serviceConfig,
          dictionaryTypeId
        );
        notify.actionCompleted();
        setRefresh(true);
        setShowModal(false);
        return result;
      } catch (err) {
        errorHandler(err, "delete type");
      } finally {
        setSubmitting(false);
      }
    };

    const [tableFilters, setTableFilters] = useState<NxpFilter[]>([]);
    const handleFiltersApply = useCallback((filters: NxpFilter[]) => {
      setTableFilters(filters);
    }, []);

    const filteredDictionaryTypes: DictionaryType[] = useMemo(() => {
      return applyFilter(dictionaryTypes, tableFilters);
    }, [dictionaryTypes, tableFilters]);

    return (
      <>
        <DictionaryTypePageLayout
          dictionaryTypes={filteredDictionaryTypes}
          submitting={submitting}
          onClickAddType={handleClickAddType}
          onClickTableRowEdit={handleClickTableRowEdit}
          onFiltersApply={handleFiltersApply}
          tableFilters={tableFilters}
        />
        <AddDictionaryTypeModal
          showModal={showModal}
          submitting={submitting}
          isEdit={isEdit}
          editData={editData}
          setShowModal={setShowModal}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </>
    );
  };

export default DictionaryTypePageContainer;
