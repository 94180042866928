import { initReactI18next } from "react-i18next";

import {
  nxpUiTranslationDe,
  nxpUiTranslationEn,
  nxpUiTranslationEs,
  nxpUiTranslationZhHk,
} from "@nexploretechnology/nxp-ui";
import i18n from "i18next";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";

import de from "./de/translation.json";
import en from "./en/translation.json";
import es from "./es/translation.json";
import zh_hk from "./zh_hk/translation.json";

// https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
// i18next.d.ts
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  en: {
    translation: { ...en, ...nxpUiTranslationEn },
  },
  "zh-HK": {
    translation: { ...zh_hk, ...nxpUiTranslationZhHk },
  },
  es: {
    translation: { ...es, ...nxpUiTranslationEs },
  },
  de: {
    translation: { ...de, ...nxpUiTranslationDe },
  },
};

const enableInContextProcessor = window.location.search.includes("phrase=true");
if (enableInContextProcessor) {
  i18n.use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: true,
      fullReparse: true,
      autoLowercase: false,
      projectId: "e62e3041b55d8e148bb9a6cd9f3f6ee0",
    })
  );
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    resources,
    returnNull: false, // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    fallbackLng: "en", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    postProcess: enableInContextProcessor ? ["phraseInContextEditor"] : [],
  });

export default i18n;
