import { Pds } from "../../services/pds";

export const findPdsDataSet = (dataSetId: string, pds: Pds) => {
  for (const spectrum of pds.spectrums) {
    const dataSet = spectrum.dataSets.find(
      (dataSet) => dataSet.id === dataSetId
    );
    if (dataSet) {
      return dataSet;
    }
  }
};

export const findPdsSpectrumByDataSetId = (dataSetId: string, pds: Pds) => {
  return pds.spectrums.find(
    (spectrum) =>
      !!spectrum.dataSets.find((dataSet) => dataSet.id === dataSetId)
  );
};

export const findPdsDataSetByDataSetEntryId = (
  dataSetEntryId: string,
  pds: Pds
) => {
  for (const spectrum of pds.spectrums) {
    const dataSet = spectrum.dataSets.find((dataSet) =>
      dataSet.dataSetEntries.find(
        (dataSetEntry) => dataSetEntry.id === dataSetEntryId
      )
    );
    if (dataSet) {
      return dataSet;
    }
  }
};

export const findPdsDataSetByDataEntryId = (dataEntryId: string, pds: Pds) => {
  for (const spectrum of pds.spectrums) {
    const dataSet = spectrum.dataSets.find((dataSet) =>
      dataSet.dataSetEntries.find(
        (dataSetEntry) => dataSetEntry.dataEntry.id === dataEntryId
      )
    );
    if (dataSet) {
      return dataSet;
    }
  }
};

export const findPdsDataSetEntry = (dataSetEntryId: string, pds: Pds) => {
  for (const spectrum of pds.spectrums) {
    for (const dataSet of spectrum.dataSets) {
      const entry = dataSet.dataSetEntries.find(
        (dataSetEntry) => dataSetEntry.id === dataSetEntryId
      );
      if (entry) {
        return entry;
      }
    }
  }
};

export const findPdsDataEntryByCode = (
  code: string,
  dataSetId: string,
  pds: Pds
) => {
  const dataSet = findPdsDataSet(dataSetId, pds);
  if (dataSet) {
    return dataSet.dataSetEntries.find(
      (dataSetEntry) => dataSetEntry.dataEntry.code === code
    )?.dataEntry;
  }
};

export const findPdsRelationParentDataSet = (
  relationChildDataEntryId: string,
  pds: Pds
) => {
  for (const spectrum of pds.spectrums) {
    for (const dataSet of spectrum.dataSets) {
      const entry = dataSet.dataSetEntries.find((dataSetEntry) =>
        dataSetEntry.relationValues.find(
          (childEntry) => childEntry.id === relationChildDataEntryId
        )
      );
      if (entry) {
        return dataSet;
      }
    }
  }
};

export const findPdsRelationParentDataSetEntry = (
  relationChildDataEntryId: string,
  pds: Pds
) => {
  for (const spectrum of pds.spectrums) {
    for (const dataSet of spectrum.dataSets) {
      const entry = dataSet.dataSetEntries.find((dataSetEntry) =>
        dataSetEntry.relationValues.find(
          (childEntry) => childEntry.id === relationChildDataEntryId
        )
      );
      if (entry) {
        return entry;
      }
    }
  }
};
