import React from "react";

import { NxpTableEditable } from "@nexploretechnology/nxp-ui";

import { DictionaryType } from "../../services/dictionary";

interface DictionaryTypeTableProps {
  tableData: DictionaryType[];
  submitting: boolean;
  onClickTableRowEdit: (editItem: DictionaryType) => void;
  columns: any[];
}

const DictionaryTypeTable: React.FC<DictionaryTypeTableProps> = ({
  tableData,
  submitting,
  onClickTableRowEdit,
  columns,
}) => {
  return (
    <NxpTableEditable
      rowKey={(item) => item.id}
      useDefaultHeight={true}
      pagination={false}
      columns={columns}
      dataSource={tableData}
      saveInProgress={submitting}
      itemCompareKey="id"
      onRowEdit={onClickTableRowEdit}
    />
  );
};

export default DictionaryTypeTable;
