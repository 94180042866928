import { useEffect, useState } from "react";

import {
  DateFormatPattern,
  formatDate,
  NxpButton,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import * as XLSX from "xlsx";

import {
  Pds,
  PdsSpectrum,
  PdsTranslationFieldNameEnum,
} from "../../../../services/pds";
import { DEFAULT_LANGUAGES } from "../../../../utils/const";

interface ExportSpectrumButtonProps {
  pds: Pds;
}

const ExportSpectrumButton: React.FC<ExportSpectrumButtonProps> = ({ pds }) => {
  const [exportingExcel, setexportingExcel] = useState(false);

  const handleExportExcel = () => {
    setexportingExcel(true);
  };

  useEffect(() => {
    if (exportingExcel) {
      var wb = XLSX.utils.book_new();
      const tables: PdsSpectrum[] = pds.spectrums;

      for (let i = 0; i < tables.length; i++) {
        let rows: object[] = [];
        rows.push(["code", tables[i].code]);
        rows.push(["", "", "ENGLISH", "ESPAÑOL", "DEUTSCH", "中文"]);
        const groupName = DEFAULT_LANGUAGES.map((lang) => {
          return tables[i].translations.find(
            (translation) =>
              translation.fieldName ===
                PdsTranslationFieldNameEnum.DESCRIPTION &&
              translation.langKey === lang
          )?.value;
        });
        rows.push(["name", "", ...groupName]);

        const attributedisplayName = tables[i].dataSets.map((dataSet) => {
          const displayName = DEFAULT_LANGUAGES.map(
            (lang) =>
              dataSet.translations.find(
                (translation) =>
                  translation.fieldName ===
                    PdsTranslationFieldNameEnum.DISPLAY_NAME &&
                  translation.langKey === lang
              )?.value
          );
          return [dataSet.code, ...displayName];
        });

        const attributedisplayDescrible = tables[i].dataSets.map((dataSet) => {
          const description = DEFAULT_LANGUAGES.map(
            (lang) =>
              dataSet.translations.find(
                (translation) =>
                  translation.fieldName ===
                    PdsTranslationFieldNameEnum.DESCRIPTION &&
                  translation.langKey === lang
              )?.value
          );
          return description;
        });

        attributedisplayName.forEach(
          (data: (string | undefined)[], idx: number) => {
            idx
              ? rows.push(["", ...data])
              : rows.push(["displayName", ...data]);
          }
        );

        attributedisplayDescrible.forEach(
          (data: (string | undefined)[], idx: number) => {
            idx
              ? rows.push(["", "", ...data])
              : rows.push(["description", "", ...data]);
          }
        );

        tables[i].dataSets.forEach((dataSet) => {
          rows.push([]);
          rows.push([
            " ",
            " ",
            "Attribute",
            dataSet.name,
            "Code",
            "ENGLISH",
            "ESPAÑOL",
            "DEUTSCH",
            "中文",
          ]);

          dataSet.dataSetEntries.sort((a, b) =>
            sorterForString(a.dataEntry.code, b.dataEntry.code)
          );

          dataSet.dataSetEntries.forEach((entries) => {
            const subRows = DEFAULT_LANGUAGES.map(
              (lang) =>
                entries.dataEntry.translations.find(
                  (translation) =>
                    translation.fieldName ===
                      PdsTranslationFieldNameEnum.DISPLAY_NAME &&
                    translation.langKey === lang
                )?.value
            );

            const code = entries.dataEntry.code;

            rows.push(["", "", "", "", code, ...subRows]);
          });
        });

        const ws = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(wb, ws, `${tables[i].name}`);
      }
      const today = formatDate(new Date(), DateFormatPattern.date);
      XLSX.writeFile(wb, `PDS_${today}.xlsx`);
      setexportingExcel(false);
    }
  }, [exportingExcel, pds]);

  return (
    <>
      <NxpButton type="default" onClick={handleExportExcel}>
        EXPORT TO EXCEL
      </NxpButton>
    </>
  );
};

export default ExportSpectrumButton;
