import { LangLabel } from "../i18n/LangLabelEnum";
import { apiRequest, ServiceConfig } from "../utils/backend";

export enum PdsTranslationFieldNameEnum {
  DISPLAY_NAME = "display_name",
  DESCRIPTION = "description",
}

export interface PdsTranslation {
  fieldName: PdsTranslationFieldNameEnum;
  langKey: LangLabel;
  value: string;
}

export interface PdsDataEntryTranslation extends PdsTranslation {
  dataEntryId?: string;
}

export interface PdsDataEntry {
  id: string;
  name: string;
  code: string;
  translations: PdsDataEntryTranslation[];
}

export interface PdsDataEntryForm extends Pick<PdsDataEntry, "name" | "code"> {
  id?: string;
  displayName: Record<LangLabel, string>;
}

export interface PdsDataSetEntry {
  id: string;
  dataSet: string;
  dataEntry: PdsDataEntry;
  relationValues: PdsDataEntry[];
}

export interface PdsReorderItem {
  id: string;
  code: string;
  order: number;
  translations: PdsTranslation[];
}

export interface PdsDataSetTranslation extends PdsTranslation {
  dataSetId?: string;
}

export interface PdsDataSet {
  id: string;
  name: string;
  code: string;
  order: number;
  translations: PdsDataSetTranslation[];
  dataSetEntries: PdsDataSetEntry[];
}

export interface PdsDataSetForm extends Pick<PdsDataSet, "name" | "code"> {
  id?: string;
  displayName: Record<LangLabel, string>;
  description: Record<LangLabel, string>;
}

export interface PdsDataSetOrderForm {
  dataSetOrders: { dataSetId: string; order: number }[];
}

export interface PdsSpectrumTranslation extends PdsTranslation {
  spectrumId?: string;
}

export interface PdsSpectrum {
  id: string;
  name: string;
  code: string;
  pdsId: string;
  order: number;
  translations: PdsSpectrumTranslation[];
  dataSets: PdsDataSet[];
}

export interface PdsSpectrumForm extends Pick<PdsSpectrum, "name" | "code"> {
  id?: string;
  displayName: Record<LangLabel, string>;
  description: Record<LangLabel, string>;
}

export interface PdsSpectrumOrderForm {
  spectrumOrders: { spectrumId: string; order: number }[];
}

export const pdsIconTypeValues = [
  "civil",
  "building",
  "railway",
  "military",
] as const;

export type PdsIconType = typeof pdsIconTypeValues[number];

export interface Pds {
  id: string;
  operationEntityId: string;
  enabled: boolean;
  name?: string;
  icon?: PdsIconType;
  spectrums: PdsSpectrum[];
}

export interface PdsForm
  extends Pick<Pds, "enabled" | "operationEntityId" | "name" | "icon"> {}

export type EditPdsForm = Omit<PdsForm, "operationEntityId">;

export interface PdsDataEntryRelation {
  dataSetEntryId: string;
  relationIds: string[];
}

export const updatePdsDataEntryRelation = async (
  payload: {
    relations: PdsDataEntryRelation[];
  },
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-entries/${serviceConfig.entityId}/relations`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    }
  );
};

export const deletePdsDataEntryRelation = async (
  payload: {
    relations: PdsDataEntryRelation[];
  },
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-entries/${serviceConfig.entityId}/relations`,
    serviceConfig,
    {
      method: "DELETE",
      body: JSON.stringify(payload),
    }
  );
};

export const createPdsDataEntry = async (
  dataSetId: string,
  dataEntryForms: PdsDataEntryForm[],
  serviceConfig: ServiceConfig
) => {
  const newDataEntries = dataEntryForms.map((dataEntryForm) => {
    const translations: PdsDataEntryTranslation[] = [];
    Object.entries(dataEntryForm.displayName).forEach(([lang, value]) => {
      translations.push({
        langKey: lang as LangLabel,
        value: value,
        fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
      });
    });
    return {
      name: dataEntryForm.name,
      code: dataEntryForm.code.trim(),
      translations: translations,
    };
  });
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-entries/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        dataSetId: dataSetId,
        dataEntries: newDataEntries,
      }),
    }
  );
};

export const updatePdsDataEntry = async (
  dataSetId: string,
  dataEntryForms: PdsDataEntryForm[],
  serviceConfig: ServiceConfig
) => {
  const modifiedDataEntries = dataEntryForms.map((dataEntryForm) => {
    const translations: PdsDataEntryTranslation[] = [];
    Object.entries(dataEntryForm.displayName).forEach(([lang, value]) => {
      translations.push({
        langKey: lang as LangLabel,
        value: value,
        fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
      });
    });
    return {
      dataEntryId: dataEntryForm.id,
      name: dataEntryForm.name,
      code: dataEntryForm.code.trim(),
      translations: translations,
    };
  });

  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-entries/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        dataSetId: dataSetId,
        dataEntries: modifiedDataEntries,
      }),
    }
  );
};

export const deletePdsDataEntry = async (
  dataEntryIds: string[],
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-entries/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "DELETE",
      body: JSON.stringify({
        dataEntryIds: dataEntryIds,
      }),
    }
  );
};

export const createPdsDataSet = async (
  pdsSpectrumId: string,
  dataSetForm: PdsDataSetForm,
  serviceConfig: ServiceConfig
) => {
  const translations: PdsDataSetTranslation[] = [];
  Object.entries(dataSetForm.displayName).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
    });
  });
  Object.entries(dataSetForm.description).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DESCRIPTION,
    });
  });
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-sets/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        name: dataSetForm.name,
        code: dataSetForm.code.trim(),
        spectrumId: pdsSpectrumId,
        translations: translations,
      }),
    }
  );
};

export const updatePdsDataSet = async (
  dataSetId: string,
  pdsSpectrumId: string,
  dataSetForm: PdsDataSetForm,
  serviceConfig: ServiceConfig
) => {
  const translations: PdsDataSetTranslation[] = [];
  Object.entries(dataSetForm.displayName).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
    });
  });
  Object.entries(dataSetForm.description).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DESCRIPTION,
    });
  });
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-sets/${serviceConfig.entityId}/${dataSetId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        name: dataSetForm.name,
        code: dataSetForm.code.trim(),
        spectrumId: pdsSpectrumId,
        translations: translations,
      }),
    }
  );
};

export const reorderPdsDataSets = async (
  pdsSpectrumId: string,
  dataSetOrderForm: PdsDataSetOrderForm,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/data-sets/${serviceConfig.entityId}/order`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ ...dataSetOrderForm, spectrumId: pdsSpectrumId }),
    }
  );
};

export const createPdsSpectrum = async (
  pdsId: string,
  pdsSpectrumForm: PdsSpectrumForm,
  serviceConfig: ServiceConfig
) => {
  const translations: PdsSpectrumTranslation[] = [];
  Object.entries(pdsSpectrumForm.displayName).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
    });
  });
  Object.entries(pdsSpectrumForm.description).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DESCRIPTION,
    });
  });
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/spectrums/${serviceConfig.entityId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        name: pdsSpectrumForm.name,
        code: pdsSpectrumForm.code.trim(),
        pdsId: pdsId,
        translations: translations,
      }),
    }
  );
};

export const updatePdsSpectrum = async (
  pdsSpectrumId: string,
  pdsId: string,
  pdsSpectrumForm: PdsSpectrumForm,
  serviceConfig: ServiceConfig
) => {
  const translations: PdsSpectrumTranslation[] = [];
  Object.entries(pdsSpectrumForm.displayName).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DISPLAY_NAME,
    });
  });
  Object.entries(pdsSpectrumForm.description).forEach(([lang, value]) => {
    translations.push({
      langKey: lang as LangLabel,
      value: value,
      fieldName: PdsTranslationFieldNameEnum.DESCRIPTION,
    });
  });
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/spectrums/${serviceConfig.entityId}/${pdsSpectrumId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        name: pdsSpectrumForm.displayName.en,
        code: pdsSpectrumForm.code.trim(),
        pdsId: pdsId,
        translations: translations,
      }),
    }
  );
};

export const reorderPdsSpectrums = async (
  pdsId: string,
  spectrumOrderForm: PdsSpectrumOrderForm,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/spectrums/${serviceConfig.entityId}/order`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ ...spectrumOrderForm, pdsId: pdsId }),
    }
  );
};

export const copyPds = async (
  pdsTemplateId: string,
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/pds/copy`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        copyPdsId: pdsTemplateId,
        operationEntityId: entityId,
      }),
    }
  );
};

export const getPdsList = async (
  entityId: string,
  enabled: boolean,
  findParent: boolean,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds[]>(
    `${serviceConfig.coreServiceApiBaseUrl}/pds/${entityId}?enabled=${enabled}&findParent=${findParent}`,
    serviceConfig
  );
};

export const getPds = async (id: string, serviceConfig: ServiceConfig) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/pds/${serviceConfig.entityId}/${id}`,
    serviceConfig
  );
};

export const createPds = async (
  pdsForm: PdsForm,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/pds`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(pdsForm),
    }
  );
};

export const updatePds = async (
  id: string,
  pdsForm: EditPdsForm,
  serviceConfig: ServiceConfig
) => {
  return apiRequest<Pds>(
    `${serviceConfig.coreServiceApiBaseUrl}/pds/${serviceConfig.entityId}/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(pdsForm),
    }
  );
};

export const deletePds = async (id: string, serviceConfig: ServiceConfig) => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/pds/${serviceConfig.entityId}/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
