import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  notify,
  NxpButton,
  NxpFullFormTableItem,
  NxpHeader,
  NxpSpin,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import AppChangePromptButton from "../../../components/AppChangePrompt/AppChangePromptButton";
import AppChangePromptContext from "../../../components/AppChangePrompt/AppChangePromptContext";
import useAppContext from "../../../hooks/useAppContext";
import { LangLabel } from "../../../i18n/LangLabelEnum";
import {
  createPdsDataEntry,
  deletePdsDataEntry,
  deletePdsDataEntryRelation,
  getPds,
  Pds,
  PdsDataEntryForm,
  PdsDataSet,
  PdsDataSetForm,
  PdsTranslationFieldNameEnum,
  updatePdsDataEntry,
  updatePdsDataEntryRelation,
  updatePdsDataSet,
} from "../../../services/pds";
import { DEFAULT_LANGUAGE_KEY } from "../../../utils/const";
import { checkDefaultLanguageDefined } from "../../../utils/multilingualHelper";
import {
  findPdsDataEntryByCode,
  findPdsDataSet,
  findPdsSpectrumByDataSetId,
} from "../pdsHelper";
import DataSetDependency from "./DataSetDependency";
import DataSetEntries from "./DataSetEntries";
import DataSetGeneral from "./DataSetGeneral";

interface DataSetDetailsProps {
  pdsLoading: boolean;
  pds: Pds;
  dataSet: PdsDataSet;
  onPdsRefresh: () => void;
}

export interface DataSetDetailsEntry
  extends PdsDataEntryForm,
    NxpFullFormTableItem {}

export interface DataSetDetailsForm extends PdsDataSetForm {
  dataEntries: DataSetDetailsEntry[];
}

export interface DataSetDetailsRelationState {
  relations: { childItemUuid: string; parentDataSetEntryId: string }[];
  changed: boolean;
}

const DataSetDetails: React.FC<DataSetDetailsProps> = ({
  pdsLoading,
  pds,
  dataSet,
  onPdsRefresh,
}) => {
  const initializeForm = useCallback(() => {
    const displayName = {} as Record<LangLabel, string>;
    const description = {} as Record<LangLabel, string>;
    dataSet.translations.forEach((translation) => {
      switch (translation.fieldName) {
        case PdsTranslationFieldNameEnum.DISPLAY_NAME:
          displayName[translation.langKey] = translation.value;
          break;
        case PdsTranslationFieldNameEnum.DESCRIPTION:
          description[translation.langKey] = translation.value;
          break;
      }
    });
    return {
      name: dataSet.name,
      displayName: displayName,
      code: dataSet.code,
      description: description,
      dataEntries: dataSet.dataSetEntries.length
        ? dataSet.dataSetEntries
            .sort((a, b) => (a.dataEntry.code > b.dataEntry.code ? 1 : -1))
            .map((item) => {
              const dataEntryDisplayName = {} as Record<LangLabel, string>;
              item.dataEntry.translations.forEach((translation) => {
                switch (translation.fieldName) {
                  case PdsTranslationFieldNameEnum.DISPLAY_NAME:
                    dataEntryDisplayName[translation.langKey] =
                      translation.value;
                    break;
                }
              });
              return {
                id: item.dataEntry.id,
                code: item.dataEntry.code,
                name: item.dataEntry.name,
                displayName: dataEntryDisplayName,
                itemState: {
                  new: false,
                  modified: false,
                  deleted: false,
                },
                itemUuid: uuidv4(),
              };
            })
        : [],
    } as DataSetDetailsForm;
  }, [dataSet]);

  const { onChangePromptUpdate } = useContext(AppChangePromptContext);
  const [form, setForm] = useState<DataSetDetailsForm>(initializeForm());

  const [relationState, setRelationState] =
    useState<DataSetDetailsRelationState>({
      relations: [],
      changed: false,
    });

  const handleReset = useCallback(() => {
    const initForm = initializeForm();
    setForm(initForm);
    const relations: DataSetDetailsRelationState["relations"] = [];

    findPdsSpectrumByDataSetId(dataSet.id, pds)!.dataSets.forEach(
      (spectrumDataSet) => {
        spectrumDataSet.dataSetEntries.forEach((spectrumDataSetEntry) => {
          spectrumDataSetEntry.relationValues.forEach((val) => {
            const initFormDataEntry = initForm.dataEntries.find(
              (entry) => entry.id === val.id
            );
            if (initFormDataEntry) {
              relations.push({
                childItemUuid: initFormDataEntry.itemUuid,
                parentDataSetEntryId: spectrumDataSetEntry.id,
              });
            }
          });
        });
      }
    );

    setRelationState({ relations, changed: false });
  }, [dataSet.id, initializeForm, pds]);

  useEffect(() => {
    if (!pdsLoading) {
      // reset for initialization and onPdsRefresh when pds is ready
      handleReset();
    }
  }, [handleReset, pdsLoading]);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const { serviceConfig, errorHandler } = useAppContext();

  const navigate = useNavigate();

  const handleSaveValidated = useCallback(async () => {
    try {
      setSaveInProgress(true);
      await updatePdsDataSet(
        dataSet.id,
        findPdsSpectrumByDataSetId(dataSet.id, pds)!.id,
        {
          displayName: form.displayName,
          description: form.description,
          code: form.code.trim(),
          name: form.displayName[LangLabel.EN],
        } as PdsDataSetForm,
        serviceConfig
      );

      const newDataEntries = form.dataEntries.filter(
        (entry) => entry.itemState.new
      );
      if (newDataEntries && newDataEntries.length > 0) {
        await createPdsDataEntry(
          dataSet.id,
          newDataEntries.map((entry) => ({
            name: entry.name,
            code: entry.code,
            displayName: entry.displayName,
          })),
          serviceConfig
        );
      }

      const modifiedDataEntries = form.dataEntries.filter(
        (entry) =>
          !entry.itemState.new &&
          !entry.itemState.deleted &&
          entry.itemState.modified
      );
      if (modifiedDataEntries && modifiedDataEntries.length > 0) {
        await updatePdsDataEntry(
          dataSet.id,
          modifiedDataEntries.map((entry) => ({
            id: entry.id,
            name: entry.name,
            code: entry.code,
            displayName: entry.displayName,
          })),
          serviceConfig
        );
      }

      const deleteDataEntries = form.dataEntries.filter(
        (entry) => !entry.itemState.new && entry.itemState.deleted
      );
      if (deleteDataEntries && deleteDataEntries.length > 0) {
        await deletePdsDataEntry(
          deleteDataEntries.map((entry) => entry.id!),
          serviceConfig
        );
      }

      if (relationState.changed) {
        // update relations below

        const pdsResult = await getPds(pds.id, serviceConfig);

        const relationsToAdd: {
          parentDataSetEntryId: string;
          childDataEntryId: string;
        }[] = [];

        relationState.relations.forEach((relation) => {
          const formChildEntry = form.dataEntries.find(
            (entry) =>
              !entry.itemState.deleted &&
              entry.itemUuid === relation.childItemUuid
          );
          if (formChildEntry) {
            const pdsChildEntry = findPdsDataEntryByCode(
              formChildEntry.code,
              dataSet.id,
              pdsResult
            );

            if (pdsChildEntry) {
              relationsToAdd.push({
                parentDataSetEntryId: relation.parentDataSetEntryId,
                childDataEntryId: pdsChildEntry.id,
              });
            }
          }
        });

        const relationsToRemove: {
          parentDataSetEntryId: string;
          childDataEntryIds: string[];
        }[] = [];

        const spectrum = findPdsSpectrumByDataSetId(dataSet.id, pdsResult);
        const childDataEntryIds =
          findPdsDataSet(dataSet.id, pdsResult)?.dataSetEntries.map(
            (dataSetEntry) => dataSetEntry.dataEntry.id
          ) || [];

        if (spectrum) {
          for (const varDataSet of spectrum.dataSets) {
            for (const removeParentDataSetEntry of varDataSet.dataSetEntries) {
              const removeChildDataEntryIds =
                removeParentDataSetEntry.relationValues
                  .filter(
                    (removeChildDataEntry) =>
                      childDataEntryIds.includes(removeChildDataEntry.id) &&
                      !relationsToAdd.find(
                        (a) =>
                          a.parentDataSetEntryId ===
                            removeParentDataSetEntry.id &&
                          a.childDataEntryId === removeChildDataEntry.id
                      )
                  )
                  .map((removeChildDataEntry) => removeChildDataEntry.id);
              if (removeChildDataEntryIds.length) {
                relationsToRemove.push({
                  parentDataSetEntryId: removeParentDataSetEntry.id,
                  childDataEntryIds: removeChildDataEntryIds,
                });
              }
            }
          }
        }

        if (relationsToRemove?.length > 0) {
          await deletePdsDataEntryRelation(
            {
              relations: relationsToRemove.map(
                ({ parentDataSetEntryId, childDataEntryIds }) => ({
                  dataSetEntryId: parentDataSetEntryId,
                  relationIds: childDataEntryIds,
                })
              ),
            },
            serviceConfig
          );
        }

        if (relationsToAdd?.length > 0) {
          await updatePdsDataEntryRelation(
            {
              relations: relationsToAdd
                .reduce((aggr, relationToAdd) => {
                  if (!aggr.includes(relationToAdd.parentDataSetEntryId)) {
                    aggr.push(relationToAdd.parentDataSetEntryId);
                  }
                  return aggr;
                }, [] as string[])
                .map((parentDataSetEntryId) => ({
                  dataSetEntryId: parentDataSetEntryId,
                  relationIds: relationsToAdd
                    .filter(
                      (r) => r.parentDataSetEntryId === parentDataSetEntryId
                    )
                    .map((r) => r.childDataEntryId),
                })),
            },
            serviceConfig
          );
        }
      }

      notify.actionCompleted();
      onPdsRefresh();
      onChangePromptUpdate(false);
    } catch (ex) {
      errorHandler(ex, `pds attribute creation`);
    } finally {
      setSaveInProgress(false);
    }
  }, [
    dataSet.id,
    form,
    onChangePromptUpdate,
    onPdsRefresh,
    pds,
    relationState,
    serviceConfig,
    errorHandler,
  ]);

  const handleSave = () => {
    saveWithValidate(undefined, true);
  };

  const yupCheckEntryDeleted = (yupContext: yup.TestContext) => {
    const itemState = yup.ref("itemState");
    if (
      (
        yupContext.resolve(
          itemState
        ) as unknown as NxpFullFormTableItem["itemState"]
      ).deleted
    ) {
      return true;
    }
    return false;
  };

  const formSchema = yup.object({
    displayName: yup
      .object()
      .nullable()
      .test("displayName", "Display Name in English required.", function (val) {
        return checkDefaultLanguageDefined(val);
      }) as any,
    code: yup
      .string()
      .nullable()
      .matches(
        /^\s*[A-Za-z0-9_]+\s*$/,
        "Only alphanumerics and underscore allowed."
      )
      .required("Code required."),
    description: yup.object() as any,
    dataEntries: yup.array(
      yup.object().shape({
        code: yup
          .string()
          .test("code", "Code duplicated.", function (val) {
            return (
              yupCheckEntryDeleted(this) ||
              form.dataEntries.filter(
                (entry) =>
                  entry.code === val?.trim() && !entry.itemState.deleted
              ).length < 2
            );
          })
          .matches(
            /^\s*[A-Za-z0-9_]+\s*$/,
            "Only alphanumerics and underscore allowed."
          )
          .test("code", "Code required.", function (val) {
            return yupCheckEntryDeleted(this) || !!val?.trim();
          }),
        displayName: yup
          .object()
          .test(
            `displayName`,
            "Display Name in English required.",
            function (val: Record<string, string>) {
              return (
                yupCheckEntryDeleted(this) ||
                !!val[DEFAULT_LANGUAGE_KEY]?.trim()
              );
            }
          ),
      })
    ) as any,
  });

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<DataSetDetailsForm>(form, formSchema, handleSaveValidated);

  return (
    <NxpSpin spinning={pdsLoading || saveInProgress}>
      <NxpHeader
        titleContent={`PDS - ${dataSet.code} - ${
          dataSet.translations.find(
            (translation) =>
              translation.fieldName ===
                PdsTranslationFieldNameEnum.DISPLAY_NAME &&
              translation.langKey === DEFAULT_LANGUAGE_KEY
          )?.value || dataSet.name
        }`}
        onBackClick={() =>
          pds.enabled ? navigate("./../../pds") : navigate(`./../../${pds.id}`)
        }
      />
      <DataSetGeneral
        form={form}
        onFormChange={setForm}
        validationError={validationError}
      />
      <DataSetEntries
        form={form}
        dataEntries={form.dataEntries}
        validationError={validationError}
        saveInProgress={saveInProgress}
        onFormChange={setForm}
        clearError={clearError}
      />
      <DataSetDependency
        dataSetId={dataSet.id}
        dataEntries={form.dataEntries}
        relationState={relationState}
        pds={pds}
        onRelationStateChange={setRelationState}
      />
      <Row justify="end">
        <Col>
          <AppChangePromptButton
            onClick={handleReset}
            type="default"
            disabled={saveInProgress}
          >
            Reset
          </AppChangePromptButton>
          <NxpButton onClick={handleSave} loading={saveInProgress}>
            Save
          </NxpButton>
        </Col>
      </Row>
    </NxpSpin>
  );
};

export default DataSetDetails;
