import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { NxpButton, NxpHeader, NxpSpin } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { UsersTableItem } from "./ManageUsersPageContainer";
import ManageUsersTable from "./ManageUsersTable";

interface ManageUsersPageLayoutProps {
  loading: boolean;
  tableData: UsersTableItem[];
  selectedRows: string[];
  onRowSelect: (item: UsersTableItem) => void;
  onRemoveUser: (item: UsersTableItem) => void;
  onResetPassword: (item: UsersTableItem) => void;
  onClickEnable: (item: UsersTableItem) => void;
  onClickViewDetails: (item: UsersTableItem) => void;
  onClickEdit: (item: UsersTableItem) => void;
  setShowAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSignUpUserModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles((theme) => ({
  action: {
    display: "flex",
  },
}));

const ManageUsersPageLayout: React.FC<ManageUsersPageLayoutProps> = ({
  loading,
  tableData,
  selectedRows,
  onRowSelect,
  onRemoveUser,
  onResetPassword,
  onClickEnable,
  onClickViewDetails,
  onClickEdit,
  setShowAssignRoleModal,
  setShowSignUpUserModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { hasRight } = useAppContext();

  return (
    <NxpSpin spinning={loading}>
      <NxpHeader
        titleContent={t("app.layout.ManageUsers")}
        onBackClick={() => navigate(-1)}
        actionContent={
          <div className={classes.action}>
            {hasRight("@entity/role:assign") && (
              <NxpButton
                disabled={!selectedRows || selectedRows.length === 0}
                onClick={() => setShowAssignRoleModal(true)}
              >
                {t("app.common.AssignRole")}
              </NxpButton>
            )}
            {hasRight("@user/user:create") && (
              <NxpButton onClick={() => setShowSignUpUserModal(true)}>
                {t("ManageUsers.SignUpUser")}
              </NxpButton>
            )}
          </div>
        }
      />
      <ManageUsersTable
        tableData={tableData}
        selectedRows={selectedRows}
        onRowSelect={onRowSelect}
        onRemoveUser={onRemoveUser}
        onResetPassword={onResetPassword}
        onClickEnable={onClickEnable}
        onClickViewDetails={onClickViewDetails}
        onClickEdit={onClickEdit}
      />
    </NxpSpin>
  );
};

export default ManageUsersPageLayout;
