import React, { useContext } from "react";

import { NxpButton, NxpButtonProps } from "@nexploretechnology/nxp-ui";

import AppChangePromptContext from "./AppChangePromptContext";

interface AppChangePromptButtonProps extends NxpButtonProps {
  stopEventPropagation?: boolean;
}

const AppChangePromptButton: React.FC<AppChangePromptButtonProps> = ({
  stopEventPropagation,
  onClick,
  ...restProps
}) => {
  const { changePrompt, onChangePromptUpdate } = useContext(
    AppChangePromptContext
  );

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (stopEventPropagation) {
      e.stopPropagation();
    }

    if (changePrompt) {
      if (!window.confirm("Proceed to discard unsaved changes?")) {
        return;
      }
      onChangePromptUpdate(false);
    }

    if (onClick) {
      onClick(e as React.MouseEvent<HTMLElement & HTMLAnchorElement>);
    }
  };

  return <NxpButton onClick={handleClick} {...restProps} />;
};

export default AppChangePromptButton;
