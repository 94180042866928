import React, { useState } from "react";

import { SwapOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Pds, PdsSpectrum } from "../../../../services/pds";
import ReorderDataSetsModal from "./ReorderDataSetsModal";

interface ReorderDataSetsButtonProps {
  pds: Pds;
  spectrum: PdsSpectrum;
  disabled: boolean;
  onPdsRefresh: () => void;
}

const ReorderDataSetsButton: React.FC<ReorderDataSetsButtonProps> = ({
  pds,
  spectrum,
  disabled,
  onPdsRefresh,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton disabled={disabled} onClick={handleClick} type="text">
        <SwapOutlined rotate={90} /> Reorder Attributes
      </NxpButton>
      {modalVisible && (
        <ReorderDataSetsModal
          pds={pds}
          spectrum={spectrum}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
          onPdsRefresh={onPdsRefresh}
        />
      )}
    </>
  );
};

export default ReorderDataSetsButton;
