import {
  apiRequest,
  CustomApiErrorServerResponseData,
  ServiceConfig,
} from "../utils/backend";
import CustomApiError from "../utils/backend/customApiError";
import fetchWithTimeout from "../utils/backend/extendFetchApiTime";
import { User } from "./userDirectory";

export interface FileData {
  blob: string;
  createdAt: string;
  entityId: string;
  id: string;
  imageHeight: number;
  imageWidth: number;
  name: string;
  originalBlob: string;
  originalUrl: string;
  size: number;
  thumbnailBlob: string;
  thumbnailUrl: string;
  type: string;
  url: string;
  createdOn: Date;
  deletedOn: Date;
  updatedOn: Date;
  uploadedBy: User;
}

export type ContainerName = "user-upload" | "company-upload";

export interface PresignResult {
  assetId: string;
  url: string;
}

const fileUpload = async (
  url: string,
  file: File,
  serviceConfig: ServiceConfig
): Promise<PresignResult> => {
  const formData = new FormData();
  formData.append("file", file);

  const uploadData = {
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || "",
    },
    method: "POST",
    body: formData,
  };

  const response = await fetchWithTimeout(
    `${serviceConfig.coreServiceApiBaseUrl}${url}`,
    {
      ...uploadData,
      timeout: 3600000, // one hour
    }
  );

  if (!response.ok) {
    let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
    try {
      errorJson = await response.json();
    } catch {
      // nothing to do
    }
    throw new CustomApiError(
      url,
      uploadData,
      response.status,
      response.statusText,
      errorJson,
      undefined,
      0,
      undefined,
      true // stop retry for defined api server error
    );
  } else {
    const respText = await response.text();
    const result = JSON.parse(respText);
    return result as unknown as PresignResult;
  }
};

export const avatarUpload = async (
  file: File,
  serviceConfig: ServiceConfig
): Promise<PresignResult> => {
  return fileUpload("/file/avatar-upload", file, serviceConfig);
};

export const companyLogoUpload = async (
  file: File,
  serviceConfig: ServiceConfig
): Promise<PresignResult> => {
  return fileUpload("/file/company-upload", file, serviceConfig);
};

export const sign = async (
  containerName: ContainerName,
  request: { assets: string[] },
  serviceConfig: ServiceConfig
): Promise<{ assets: { url: string; asset_name: string }[] }> => {
  return apiRequest(
    `${serviceConfig.coreServiceApiBaseUrl}/file/containers/${containerName}/sign`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(request),
    }
  );
};
