import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  getColumnSettings,
  NxpColumnsSetting,
  NxpTableRowDropdown,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";
import { ColumnType } from "antd/lib/table";

import useAppContext from "../../hooks/useAppContext";
import { OrganisationTypeTableData } from "../../services/organisationType";
import { OrganisationTypeTable } from "./OrganisationTypeTable";

const useStyles = createUseStyles((theme) => ({
  userListingLayout: {},
  titleContent: {
    display: "flex",
  },
}));

interface OrganisationTypesListingLayoutProps {
  data: OrganisationTypeTableData[];
  onClickEdit: (item: OrganisationTypeTableData) => void;
  onClickEnable: (item: OrganisationTypeTableData) => void;
  actionField?: boolean;
}

const OrganisationTypesListingLayout: React.FC<OrganisationTypesListingLayoutProps> =
  ({ data, onClickEdit, onClickEnable, actionField = true }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const renderActionMenu = useCallback(
      (_: any, item: OrganisationTypeTableData) => (
        <NxpTableRowDropdown
          overlay={
            <>
              {hasRight("@company/type:delete") && (
                <Menu>
                  <Menu.Item
                    key={!item.isDisabled ? "Disable" : "Enable"}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      onClickEnable(item);
                    }}
                  >
                    {item.isDisabled
                      ? t("OrganisationType.action.enabled")
                      : t("OrganisationType.action.disabled")}
                  </Menu.Item>
                </Menu>
              )}
            </>
          }
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClickEnable]
    );

    const actionColumn: ColumnType<OrganisationTypeTableData> = useMemo(
      () => ({
        fixed: "right",
        align: "right",
        title: "action",
        dataIndex: "action",
        width: 60,
        render: renderActionMenu,
      }),
      [renderActionMenu]
    );

    const defaultColumns: ColumnType<OrganisationTypeTableData>[] =
      useMemo(() => {
        return [
          {
            title: t("app.common.Name"),
            dataIndex: "name",
            key: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => sorterForString(a.name, b.name),
          },
        ];
      }, [t]);
    const [columns, setColumns] =
      useState<ColumnType<OrganisationTypeTableData>[]>(defaultColumns);

    const [columnsSettings] = useState<NxpColumnsSetting[]>(
      getColumnSettings(columns, defaultColumns)
    );

    useEffect(() => {
      setColumns([...defaultColumns, actionColumn]);
    }, [columnsSettings, defaultColumns, actionColumn, actionField]);

    const { hasRight } = useAppContext();
    return (
      <div className={classes.userListingLayout}>
        <OrganisationTypeTable
          actionField={actionField}
          data={data}
          columns={columns}
          onClickRow={(record) =>
            hasRight("@company/type:edit") && onClickEdit(record)
          }
        />
      </div>
    );
  };

export default OrganisationTypesListingLayout;
