import React from "react";

import { NxpModal, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

import { User } from "../../../../services/userDirectory";
import AppAccountSettingsGeneral from "./AppAccountSettingsGeneral/AppAccountSettingsGeneralContainer";
import AppAccountSettingsSecurity from "./AppAccountSettingsSecurity";

interface AppAccountSettingsModalProps {
  user: User;
  modalVisible: boolean;
  onModalClose: () => void;
}

const AppAccountSettingsModal: React.FC<AppAccountSettingsModalProps> = ({
  user,
  modalVisible,
  onModalClose,
}) => {
  return (
    <NxpModal
      title="Account Settings"
      visible={modalVisible}
      onCancel={onModalClose}
      centered
    >
      <NxpTabs
        defaultActiveKey="general"
        tabsOnRight
        tabBarExtraContent={<></>}
      >
        <TabPane key="general" tab="General">
          <AppAccountSettingsGeneral user={user} onModalClose={onModalClose} />
        </TabPane>
        <TabPane key="security" tab="Security">
          <AppAccountSettingsSecurity onModalClose={onModalClose} />
        </TabPane>
      </NxpTabs>
    </NxpModal>
  );
};

export default AppAccountSettingsModal;
