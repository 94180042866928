import { useContext, useMemo } from "react";
import AppContext from "../components/App/AppContext";
import useErrorHandler from "./useErrorHandler";
import useServiceConfig from "./useServiceConfig";

const useAppContext = () => {
  const appContext = useContext(AppContext);
  const serviceConfig = useServiceConfig();
  const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...appContext,
      serviceConfig,
      errorHandler,
    }),
    [appContext, serviceConfig, errorHandler]
  );
};

export default useAppContext;
