import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpLabel,
  NxpModal,
  NxpTable,
} from "@nexploretechnology/nxp-ui";
import { Col, Input, Row } from "antd";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { LangLabel } from "../../../i18n/LangLabelEnum";
import { DEFAULT_LANGUAGES } from "../../../utils/const";
import { DataSetDetailsEntry, DataSetDetailsForm } from "./DataSetDetails";

const useStyles = createUseStyles((theme) => ({
  dataSetEntriesCopyExcel: {},
}));

interface DataSetEntriesCopyExcelProps {
  dataEntries: DataSetDetailsEntry[];
  onFormChange: (
    handler: (prevState: DataSetDetailsForm) => DataSetDetailsForm
  ) => void;
  modalVisible: boolean;
  onModalClose: () => void;
}

const DataSetEntriesCopyExcel: React.FC<DataSetEntriesCopyExcelProps> = ({
  dataEntries,
  modalVisible,
  onFormChange,
  onModalClose,
}) => {
  const [excelContent, setExcelContent] = useState("");

  const handleExcelContentChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    (e) => {
      setExcelContent(e.target.value.replaceAll("\t", " | "));
    };

  const handleApply = () => {
    const valuesData = getValuesData() as Record<string, string>[];
    const nextEntries = dataEntries
      .map((entry) => {
        const nextEntry = cloneDeep(entry);
        const valuesDataEntry = valuesData.find(
          (data) => data.code === nextEntry.code
        );
        if (valuesDataEntry) {
          nextEntry.itemState.modified = true;
          nextEntry.itemState.deleted = false;
          DEFAULT_LANGUAGES.forEach((lang) => {
            Object.assign(nextEntry.displayName, {
              [lang]: valuesDataEntry[lang]?.trim() || "",
            });
          });
        } else {
          nextEntry.itemState.deleted = true;
        }
        return nextEntry;
      })
      .filter((entry) => !(entry.itemState.new && entry.itemState.deleted));

    valuesData.forEach((valuesDataEntry) => {
      if (!nextEntries.find((entry) => entry.code === valuesDataEntry.code)) {
        const addEntry = {
          code: valuesDataEntry.code,
          itemState: {
            new: true,
            modified: true,
            deleted: false,
          },
          displayName: {} as Record<LangLabel, string>,
          name: valuesDataEntry["en"]?.trim() || "",
          itemUuid: uuidv4(),
        };
        DEFAULT_LANGUAGES.forEach((lang) => {
          Object.assign(addEntry.displayName, {
            [lang]: valuesDataEntry[lang]?.trim() || "",
          });
        });
        nextEntries.push(addEntry);
      }
    });

    onFormChange((prevState) => ({
      ...prevState,
      dataEntries: nextEntries,
    }));

    onModalClose();
  };

  const getValuesData = () => {
    return excelContent
      .split("\n")
      .filter((line) => !!line.trim())
      .map((line) => {
        const cells = line.split("|");
        const data = { code: cells[0].trim() };
        DEFAULT_LANGUAGES.forEach((lang, langIdx) => {
          if (cells.length > langIdx + 1) {
            Object.assign(data, { [lang]: cells[langIdx + 1].trim() });
          }
        });
        return data;
      });
  };

  const { t } = useTranslation();
  const classes = useStyles();

  return modalVisible ? (
    <NxpModal
      className={classes.dataSetEntriesCopyExcel}
      title="Copy from Excel"
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton onClick={onModalClose} type="default">
            Cancel
          </NxpButton>
          <NxpButton onClick={handleApply}>Apply</NxpButton>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <NxpLabel>Paste Excel Content</NxpLabel>
          <Input.TextArea
            onChange={handleExcelContentChange}
            value={excelContent}
            rows={6}
          />
        </Col>
        <Col span={24}>
          <NxpLabel>Values Extracted</NxpLabel>
          <NxpTable
            useBackgroundLight
            dataSource={getValuesData()}
            columns={[
              {
                title: "Code",
                dataIndex: "code",
                width: 150,
                fixed: "left",
              },
              ...DEFAULT_LANGUAGES.map((lang) => ({
                title: t(`app.common.Language.${lang}`),
                dataIndex: lang,
                width: 150,
              })),
            ]}
          />
        </Col>
      </Row>
    </NxpModal>
  ) : null;
};

export default DataSetEntriesCopyExcel;
