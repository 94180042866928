import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { DictionaryDataType } from "../../services/dictionary";
import AddDictionaryTypeForm from "./AddDictionaryTypeForm";

interface AddDictionaryTypeModalProps {
  showModal: boolean;
  submitting: boolean;
  isEdit: boolean;
  editData?: AddDictionaryTypeFormData;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (form: AddDictionaryTypeFormData, redirect: boolean) => void;
  onDelete: (dictionaryTypeId: string) => void;
}

export interface AddDictionaryTypeFormData {
  id?: string;
  name: string;
  dataType: string;
  application?: string;
  description?: string;
}

const emptyForm: AddDictionaryTypeFormData = {
  id: undefined,
  name: "",
  dataType: "",
  application: "",
  description: undefined,
};

const AddDictionaryTypeModal: React.FC<AddDictionaryTypeModalProps> = ({
  showModal,
  submitting,
  isEdit,
  editData,
  setShowModal,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<AddDictionaryTypeFormData>(emptyForm);
  const { activeEntity } = useAppContext();
  const formSchema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required(t("app.common.NameIsRequired")),
      dataType: yup.string().required(t("DictionaryType.DataTypeIsRequired")),
    });
  }, [t]);

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    AddDictionaryTypeFormData,
    { redirect: boolean }
  >(form, formSchema, (context) => onSubmit(form, context.redirect));

  useEffect(() => {
    if (isEdit) {
      setForm(editData!);
    } else {
      setForm(emptyForm);
    }
    clearError();
  }, [isEdit, editData, showModal, clearError]);

  const handleFormGridStateChange = (
    fieldName: keyof AddDictionaryTypeFormData,
    value: any
  ) => {
    const data: Partial<AddDictionaryTypeFormData> = {};
    data[fieldName] = value;
    setForm({ ...form, ...data });
  };

  const dataTypeOptions = Object.entries(DictionaryDataType).map(([k, v]) => {
    return {
      key: k,
      label: v,
      value: v,
    } as LabeledValue;
  });

  const handleSaveandAdd = () => {
    saveWithValidate({ redirect: true });
  };

  return (
    <NxpModal
      title={
        isEdit
          ? t("DictionaryType.EditDictionaryType")
          : t("DictionaryType.AddDictionaryType")
      }
      visible={showModal}
      onCancel={() => setShowModal(false)}
      width="medium"
      destroyOnClose={true}
    >
      <AddDictionaryTypeForm
        form={form}
        validationError={validationError}
        isEdit={isEdit}
        dataTypeOptions={dataTypeOptions}
        handleFormGridStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter
        leftContent={
          <NxpButton
            type="text"
            onClick={() => setShowModal(false)}
            loading={submitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
        }
      >
        {isEdit && (
          <NxpButton
            type="text"
            danger={true}
            onClick={() => onDelete(form.id!)}
            loading={submitting}
          >
            {t("app.common.Delete")}
          </NxpButton>
        )}

        {activeEntity && (
          <NxpButton onClick={handleSaveandAdd} loading={submitting}>
            {t("Dictionary.SaveandAddDictionary")}
          </NxpButton>
        )}

        <NxpButton
          onClick={() => saveWithValidate({ redirect: false })}
          loading={submitting}
        >
          {t("app.common.Save")}
        </NxpButton>
      </NxpModalFooter>
    </NxpModal>
  );
};

export default AddDictionaryTypeModal;
