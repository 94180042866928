import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EllipsisOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpButtonWithConfirm,
  NxpFormItem,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Dropdown, Menu } from "antd";
import { ColumnsType } from "antd/lib/table";

import useAppContext from "../../hooks/useAppContext";
import { UsersTableItem } from "./ManageUsersPageContainer";
import { createUseStyles } from "react-jss";

interface ManageUsersTableProps {
  tableData: UsersTableItem[];
  selectedRows: string[];
  onRowSelect: (item: UsersTableItem) => void;
  onRemoveUser: (item: UsersTableItem) => void;
  onResetPassword: (item: UsersTableItem) => void;
  onClickEnable: (item: UsersTableItem) => void;
  onClickViewDetails: (item: UsersTableItem) => void;
  onClickEdit: (item: UsersTableItem) => void;
}
const useStyles = createUseStyles((theme) => ({
  confirmButtonModal: {
    "&.ant-dropdown-menu-item": {
      padding: "5px 0",
      "& .nxpButton": {
        paddingLeft: 20,
        width: "100%",
        textAlign: "left",
      },
    },
  },
}));
const ManageUsersTable: React.FC<ManageUsersTableProps> = ({
  tableData,
  selectedRows,
  onRowSelect,
  onRemoveUser,
  onResetPassword,
  onClickEnable,
  onClickViewDetails,
  onClickEdit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRight } = useAppContext();

  const renderCheckbox = useCallback(
    (_: any, item: UsersTableItem) => (
      <NxpFormItem
        key={`CheckBox-${item.id}`}
        controlType="checkbox"
        controlProps={{
          checked: selectedRows.includes(item.id),
          onChange: (_) => onRowSelect(item),
        }}
      />
    ),
    [selectedRows, onRowSelect]
  );

  const renderRole = useCallback((_: any, item: UsersTableItem) => {
    return item.roles.join(", ");
  }, []);

  const renderActionMenu = useCallback(
    (_: any, item: UsersTableItem) => (
      <Dropdown
        dropdownRender={() => (
          <Menu>
            {hasRight("@user/user:view") && (
              <Menu.Item
                key="viewDetail"
                onClick={() => onClickViewDetails(item)}
              >
                <NxpButton type="link" style={{ pointerEvents: "all" }}>
                  {t("ManageUsers.ViewDetails")}
                </NxpButton>
              </Menu.Item>
            )}
            {hasRight("@user/user:edit") && (
              <Menu.Item key="edit" onClick={() => onClickEdit(item)}>
                <NxpButton type="link" style={{ pointerEvents: "all" }}>
                  {t("app.common.Edit")}
                </NxpButton>
              </Menu.Item>
            )}
            {hasRight("@user/user:delete") && (
              <Menu.Item
                key="removeUser"
                className={classes.confirmButtonModal}
              >
                <NxpButtonWithConfirm
                  type="link"
                  modalSize="small"
                  modalTitle={t("ManageUsers.RemoveUser")}
                  modalContent={t("ManageUsers.DoYouWantToRemovbeUser", {
                    user: item.name,
                  })}
                  confirmButtonContent={t("app.common.Confirm")}
                  onConfirm={() => onRemoveUser(item)}
                >
                  {t("ManageUsers.RemoveUser")}
                </NxpButtonWithConfirm>
              </Menu.Item>
            )}
            {hasRight("@user/user:admin") && (
              <Menu.Item
                key="resetPassword"
                className={classes.confirmButtonModal}
              >
                <NxpButtonWithConfirm
                  type="link"
                  modalSize="small"
                  modalTitle={t("ManageUsers.ResetPassword")}
                  modalContent={t("ManageUsers.DoYouWantToResetPassword", {
                    user: item.name,
                  })}
                  confirmButtonContent={t("app.common.Confirm")}
                  onConfirm={() => onResetPassword(item)}
                >
                  {t("ManageUsers.ResetPassword")}
                </NxpButtonWithConfirm>
              </Menu.Item>
            )}
            {hasRight("@user/user:admin") && (
              <Menu.Item key="enable" onClick={() => onClickEnable(item)}>
                <NxpButton type="link">
                  {item.disabled
                    ? t("ManageUsers.Enable")
                    : t("ManageUsers.Disable")}
                </NxpButton>
              </Menu.Item>
            )}
          </Menu>
        )}
      >
        <NxpButton type="link">
          <EllipsisOutlined />
        </NxpButton>
      </Dropdown>
    ),
    [
      hasRight,
      t,
      classes.confirmButtonModal,
      onClickViewDetails,
      onClickEdit,
      onRemoveUser,
      onResetPassword,
      onClickEnable,
    ]
  );

  const columns: ColumnsType<UsersTableItem> = useMemo(
    () => [
      {
        dataIndex: "select",
        width: 40,
        render: renderCheckbox,
      },
      {
        title: t("app.common.Name"),
        dataIndex: "name",
        sorter: (a: UsersTableItem, b: UsersTableItem) =>
          sorterForString(a.name, b.name),
      },
      {
        title: t("app.common.Role"),
        dataIndex: "role",
        render: renderRole,
      },
      {
        title: t("app.common.Email"),
        dataIndex: "email",
        sorter: (a: UsersTableItem, b: UsersTableItem) =>
          sorterForString(a.email, b.email),
      },
      {
        fixed: "right",
        align: "right",
        title: t("app.common.Action"),
        dataIndex: "action",
        width: 60,
        render: renderActionMenu,
      },
    ],
    [renderActionMenu, renderCheckbox, t, renderRole]
  );

  return (
    <NxpTable
      rowKey={(item) => item.id}
      useDefaultHeight={true}
      pagination={false}
      columns={columns}
      dataSource={tableData}
    />
  );
};

export default ManageUsersTable;
