import React, { MouseEventHandler, useState } from "react";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { User } from "../../../../services/userDirectory";
import AppAccountSettingsModal from "./AppAccountSettingsModal";

const useStyles = createUseStyles(() => ({
  appAccountSettingsButton: {},
}));

interface AppAccountSettingsModalButtonProps {
  user: User;
  onPopoverVisbleChange: (visible: boolean) => void;
}

const AppAccountSettingsButton: React.FC<AppAccountSettingsModalButtonProps> =
  ({ user, onPopoverVisbleChange }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const handleClick: MouseEventHandler<HTMLElement> = (e) => {
      onPopoverVisbleChange(false);
      setModalVisible(true);
    };

    const handleModalClose = () => {
      setModalVisible(false);
    };

    const classes = useStyles();

    return (
      <>
        <NxpButton
          type="default"
          className={classes.appAccountSettingsButton}
          onClick={handleClick}
        >
          Account Settings
        </NxpButton>
        {modalVisible && (
          <AppAccountSettingsModal
            user={user}
            modalVisible={modalVisible}
            onModalClose={handleModalClose}
          />
        )}
      </>
    );
  };

export default AppAccountSettingsButton;
