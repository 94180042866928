import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { map, toUpper } from "lodash";

import { LangLabel } from "../../i18n/LangLabelEnum";
import { DictionaryDataType } from "../../services/dictionary";
import { DEFAULT_LANGUAGES } from "../../utils/const";
import { AddDictionaryItemFormData } from "./AddDictionaryItemModal";

interface AddDictionaryItemFormProps {
  form: AddDictionaryItemFormData;
  validationError: ValidationResult<AddDictionaryItemFormData>;
  dataType: DictionaryDataType;
  handleFormGridStateChange: (
    fieldName: keyof AddDictionaryItemFormData & {
      labelCN: string;
      labelEN: string;
      labelES: string;
      labelDE: string;
    },
    value: any
  ) => void;
}

const AddDictionaryItemForm: React.FC<AddDictionaryItemFormProps> = ({
  form,
  validationError,
  dataType,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<
    AddDictionaryItemFormData & {
      labelCN: string;
      labelEN: string;
      labelES: string;
      labelDE: string;
    }
  >[] = useMemo(
    () => [
      {
        controlType: "input",
        label: t("Dictionary.Key"),
        itemFieldName: "key",
        span: 12,
        required: true,
      },
      {
        controlType:
          dataType === DictionaryDataType.TEXT ? "input" : "inputNumber",
        label: t("Dictionary.Value"),
        itemFieldName: "value",
        span: 12,
        required: true,
      },
      ...(map(DEFAULT_LANGUAGES, (lang) => {
        const fieldName =
          lang === LangLabel.ZH_HK ? `labelCN` : `label${toUpper(lang)}`;
        return {
          controlType: "input",
          itemFieldName: fieldName as keyof AddDictionaryItemFormData & {
            labelCN: string;
            labelEN: string;
            labelES: string;
            labelDE: string;
          },
          span: 12,
          label: t("Dictionary.Label"),
          controlProps: {
            addonBefore: t(`app.common.Language.${lang}`),
            allowClear: true,
            onChange: (e: any) => {
              handleFormGridStateChange(
                fieldName as keyof AddDictionaryItemFormData & {
                  labelCN: string;
                  labelEN: string;
                  labelES: string;
                  labelDE: string;
                },
                e.target.value
              );
            },
          },
          required: true,
        };
      }) as any[]),
    ],
    [dataType, handleFormGridStateChange, t]
  );
  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={
          form as AddDictionaryItemFormData & {
            labelCN: string;
            labelEN: string;
            labelES: string;
            labelDE: string;
          }
        }
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange as any}
      />
    </>
  );
};

export default AddDictionaryItemForm;
