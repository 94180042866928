import React from "react";

export interface AppChangePromptContextType {
  changePrompt: boolean;
  onChangePromptUpdate: (changePrompt: boolean) => void;
}

const AppChangePromptContext = React.createContext<AppChangePromptContextType>(
  {} as AppChangePromptContextType
);

export default AppChangePromptContext;
