import React from "react";
import { useParams } from "react-router-dom";

import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import { getPdsList } from "../../services/pds";
import DataSetDetails from "./DataSetDetails";
import { findPdsDataSet } from "./pdsHelper";
import Spectrums from "./Spectrums";

const PdsPage: React.FC = () => {
  const { serviceConfig } = useAppContext();
  const { dataSetId } = useParams<{ dataSetId: string }>();

  const fetchPdsList = () =>
    getPdsList(serviceConfig.entityId!, true, false, serviceConfig);

  const [pdsListAsyncResult, setPdsListAsyncRequest] = useAsync(fetchPdsList);

  const handleRefresh = () => {
    setPdsListAsyncRequest(fetchPdsList);
  };

  const enabledPds = pdsListAsyncResult.data
    ? pdsListAsyncResult.data[0]
    : undefined;
  const dataSet =
    enabledPds && dataSetId ? findPdsDataSet(dataSetId, enabledPds) : undefined;

  if (pdsListAsyncResult.data?.length && dataSetId && !dataSet) {
    notify.error("Pds attribute not found.");
  }

  return (
    <NxpSpin spinning={pdsListAsyncResult.loading} size="large" expand>
      {dataSet && enabledPds ? (
        <DataSetDetails
          pdsLoading={pdsListAsyncResult.loading}
          pds={enabledPds}
          dataSet={dataSet}
          onPdsRefresh={handleRefresh}
        />
      ) : (
        <Spectrums
          loading={pdsListAsyncResult.loading}
          pds={enabledPds}
          onPdsRefresh={handleRefresh}
        />
      )}
    </NxpSpin>
  );
};

export default PdsPage;
