import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { EntityRole } from "../../services/entity";
import AssignRoleForm from "./AssignRoleForm";

interface AssignRoleModalProps {
  isSubmitting: boolean;
  showAssignRoleModal: boolean;
  entityRoles: EntityRole[];
  setShowAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAssignRole: (roles: string[]) => void;
}

export interface AssignRoleFormData {
  roles: string[];
}

export const emptyForm: AssignRoleFormData = {
  roles: [],
};

const AssignRoleModal: React.FC<AssignRoleModalProps> = ({
  isSubmitting,
  showAssignRoleModal,
  entityRoles,
  setShowAssignRoleModal,
  onAssignRole,
}) => {
  const { t } = useTranslation();

  const [form, setForm] = useState<AssignRoleFormData>(emptyForm);

  const formSchema = yup.object().shape({
    roles: yup.array().min(1, t("ManageUsers.RoleIsRequired")),
  });

  const handleFormGridStateChange = (
    fieldName: keyof AssignRoleFormData,
    value: any
  ) => {
    const data: Partial<AssignRoleFormData> = {};
    data[fieldName] = value;
    setForm({ ...form, ...data });
  };

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<AssignRoleFormData>(form, formSchema, () =>
      onAssignRole(form.roles)
    );

  useEffect(() => {
    setForm(emptyForm);
    clearError();
  }, [showAssignRoleModal, clearError]);

  return (
    <NxpModal
      title={t("app.common.AssignRole")}
      visible={showAssignRoleModal}
      onCancel={() => setShowAssignRoleModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="default"
            onClick={() => setShowAssignRoleModal(false)}
            loading={isSubmitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton
            onClick={() => saveWithValidate(undefined)}
            loading={isSubmitting}
          >
            {t("app.common.Assign")}
          </NxpButton>
        </>
      }
    >
      <AssignRoleForm
        form={form}
        validationError={validationError}
        entityRoles={entityRoles}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AssignRoleModal;
