import React, { useCallback, useState } from "react";

import { notify, useYupValidate } from "@nexploretechnology/nxp-ui";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import * as yup from "yup";

import useAppContext from "../../../../../hooks/useAppContext";
import { avatarUpload, FileData } from "../../../../../services/file";
import { updateMyProfile, User } from "../../../../../services/userDirectory";
import AppAccountSettingsGeneralLayout from "./AppAccountSettingsGeneralLayout";

interface AppAccountSettingsGeneralContainerProps {
  user: User;
  onModalClose: () => void;
}

const AppAccountSettingsGeneralContainer: React.FC<AppAccountSettingsGeneralContainerProps> =
  ({ user, onModalClose }) => {
    const {
      activeUser,
      serviceConfig,
      onAppContextCacheItemUpdate,
      errorHandler,
    } = useAppContext();

    const [editForm, setEditForm] = useState<User>(user);

    const [saveInProgress, setSaveInProgress] = useState(false);

    const handleSaveValidated = useCallback(async () => {
      setSaveInProgress(true);

      try {
        // update is not returning user object at the moment
        await updateMyProfile(editForm, serviceConfig);
        onAppContextCacheItemUpdate("activeUser", {
          ...activeUser!,
          ...editForm,
        });
        setSaveInProgress(false);
        notify.actionCompleted();
        onModalClose();
      } catch (ex) {
        errorHandler(ex, "update personal profile");
      } finally {
        setSaveInProgress(false);
      }
    }, [
      activeUser,
      editForm,
      errorHandler,
      onAppContextCacheItemUpdate,
      onModalClose,
      serviceConfig,
    ]);

    const formSchema = yup.object({
      salutation: yup.string().nullable(),
      firstName: yup.string().nullable().required("First Name required."),
      lastName: yup.string().nullable().required("Last Name required."),
      displayName: yup.string().nullable().required("Display Name required."),
      primaryEmail: yup
        .string()
        .nullable()
        .required("Email required.")
        .email("Email not in correct format."),
      title: yup.string().nullable(),
      mobileNumber: yup.string().nullable(),
      officeNumber: yup.string().nullable(),
    });

    const [validationError, , , saveWithValidate] = useYupValidate<User>(
      editForm,
      formSchema as any,
      handleSaveValidated
    );

    const handleFormGridStateChange = useCallback(
      (fieldName: keyof User, value: unknown) => {
        setEditForm((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      },
      []
    );

    const handleSave = useCallback(() => {
      saveWithValidate(undefined);
    }, [saveWithValidate]);

    const handleImageUploadDone = useCallback((file: FileData) => {
      setEditForm((prevState) => ({
        ...prevState,
        avatar: {
          assetId: file.id,
          url: file.url,
          filename: file.name,
        },
      }));
    }, []);

    const handleImageUpload = useCallback(
      async (file: RcFile) => {
        try {
          const avatarUploadResult = await avatarUpload(file, serviceConfig);
          const uploadResult = {
            blob: file,
            id: avatarUploadResult.assetId,
            name: file.name,
            originalBlob: file,
            originalUrl: avatarUploadResult.url,
            thumbnailBlob: file,
            thumbnailUrl: avatarUploadResult.url,
            type: file.type,
            url: avatarUploadResult.url,
          } as unknown as FileData;

          handleImageUploadDone(uploadResult);
          return uploadResult as unknown as UploadFile;
        } catch (ex) {
          errorHandler(ex, "upload avatar");
          return {} as unknown as UploadFile;
        }
      },
      [errorHandler, handleImageUploadDone, serviceConfig]
    );

    const handleImageUploadClear = useCallback(() => {
      setEditForm((prevState) => ({
        ...prevState,
        avatar: user.avatar,
      }));
    }, [user.avatar]);

    return (
      <AppAccountSettingsGeneralLayout
        editForm={editForm}
        validationError={validationError}
        saveInProgress={saveInProgress}
        onFormGridStateChange={handleFormGridStateChange}
        onImageUpload={handleImageUpload}
        onImageUploadClear={handleImageUploadClear}
        onSave={handleSave}
        onModalClose={onModalClose}
      />
    );
  };

export default AppAccountSettingsGeneralContainer;
