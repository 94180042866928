import clsx from "clsx";
import React from "react";
import { useDrop } from "react-dnd";
import { createUseStyles } from "react-jss";

import { PdsReorderItem } from "../../../../services/pds";
import { DRAG_DROP_TYPE_PDS_ITEM } from "../../../../utils/const";

const useStyles = createUseStyles((theme) => ({
  reorderPdsDragDropPlaceholder: {
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5),
    borderTop: `1px solid transparent`,
    height: theme.spacing(0.5),
    "&.isOverCurrent": {
      borderColor: theme.palette.primary,
    },
  },
}));

interface ReorderPdsDragDropPlaceholderProps {
  position: number;
  onDrop: (item: PdsReorderItem, newPosition: number) => void;
}

const ReorderPdsDragDropPlaceholder: React.FC<ReorderPdsDragDropPlaceholderProps> =
  ({ position, onDrop }) => {
    const [{ isOverCurrent }, drop] = useDrop(
      () => ({
        accept: DRAG_DROP_TYPE_PDS_ITEM,
        drop(dropItem: PdsReorderItem, monitor) {
          if (monitor.didDrop()) {
            return;
          }
          onDrop(dropItem, position);
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        }),
      }),
      [position, onDrop]
    );

    const classes = useStyles();

    return (
      <div
        title="Reorder Drag Drop Placeholder"
        ref={drop}
        className={clsx(
          classes.reorderPdsDragDropPlaceholder,
          isOverCurrent && "isOverCurrent"
        )}
      />
    );
  };

export default ReorderPdsDragDropPlaceholder;
