import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";

import { SignUpUserFormData } from "./SignUpUserModal";

interface SignUpUserFormProps {
  form: SignUpUserFormData;
  validationError: ValidationResult<SignUpUserFormData>;
  handleFormGridStateChange: (
    fieldName: keyof SignUpUserFormData,
    value: any
  ) => void;
}

const SignUpUserForm: React.FC<SignUpUserFormProps> = ({
  form,
  validationError,
  handleFormGridStateChange,
}) => {
  const { t } = useTranslation();
  const formItems: NxpFormGridItemProps<SignUpUserFormData>[] = [
    {
      controlType: "input",
      label: t("ManageUsers.FirstName"),
      itemFieldName: "firstName",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("ManageUsers.LastName"),
      itemFieldName: "lastName",
      span: 12,
      required: true,
    },
    {
      controlType: "input",
      label: t("app.common.Email"),
      itemFieldName: "email",
      span: 24,
      required: true,
    },
  ];

  return (
    <>
      <NxpFormGrid
        formItems={formItems}
        formState={form}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </>
  );
};

export default SignUpUserForm;
