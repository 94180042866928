import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  NxpSpin,
  NxpUpload,
  sorterForString,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Divider, Image, Space } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { get, isEmpty, omit } from "lodash";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import noPreviewImg from "../../../images/company/noPreview-image.png";
import { companyLogoUpload, FileData, sign } from "../../../services/file";
import {
  createSubcontractor,
  Subcontractor,
  SubcontractorForm,
  updateSubcontractor,
} from "../../../services/subcontractor";
import { countryList } from "../../../utils/backend/countryList";

interface AddCompanyModalProps {
  editSubcontractor: Subcontractor | null;
  show: boolean;
  onClose: () => void;
  onRefresh: () => void;
}
const useStyles = createUseStyles((theme) => ({
  upload: {
    "& .ant-upload": {
      display: "block",
    },
    "& button": {
      marginLeft: 0,
      width: "100%",
    },
    "& p.ant-upload-text": {
      fontSize: theme.fontSize.small,
      padding: theme.spacing(0, 2),
    },
  },
}));
const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  editSubcontractor,
  show,
  onClose,
  onRefresh,
}) => {
  const [logoImage, setLogoImage] = useState<FileData>();
  const classes = useStyles();

  let emptySubcontractor: SubcontractorForm = useMemo(
    () => ({
      name: "",
      logoAssetId: "",
      organisationTypeId: "",
      mailDomain: "",
      businessRegistrationNumber: "",
      phoneNumber: "",
      postalAddress: "",
      city: "",
      country: "",
      contactPerson: "",
      contactPhone: "",
      parentCompany: "",
      bvdRating: "",
      state: "",
      jdeCode: "",
    }),
    []
  );
  const { serviceConfig, organisationTypes, errorHandler } = useAppContext();
  const { t } = useTranslation();
  const [saving, setSaving] = useState<boolean>(false);
  const [subcontractor, setSubcontractor] =
    useState<SubcontractorForm>(emptySubcontractor);
  const handleImageUploadDone = useCallback((file: FileData) => {
    setLogoImage(file);
    setSubcontractor((prevState) => ({
      ...prevState,
      logoAssetId: file.id,
    }));
  }, []);

  const handleImageUploadClear = useCallback(() => {
    setSubcontractor(
      (prevState) => omit(prevState, ["logoAssetId"]) as SubcontractorForm
    );
  }, []);
  const formSchema = yup.object({
    name: yup.string().nullable().required("Company Name is required."),
    logoAssetId: yup.string().nullable(),
    organisationTypeId: yup.string().required("Organization Type is required."),
    businessRegistrationNumber: yup
      .string()
      .required("Business Registration Number is required."),
    postalAddress: yup
      .string()
      .nullable()
      .required("Postal Address is required."),
    city: yup.string().required("City is required."),
    country: yup.string().required("Country is required."),
    phoneNumber: yup.string().required("Phone Number is required."),
    mailDomain: yup
      .string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        "Please define a Domain-name only, e.g. 'nexplore.com'"
      )
      .nullable(),
    contactPerson: yup.string().nullable(),
    contactPhone: yup.string().nullable(),
    jdeCode: yup.string().nullable(),
    parentCompany: yup.string().nullable(),
    state: yup.string().nullable(),
    bvdRating: yup.string().nullable(),
  });

  const handleUpload = useCallback(
    async (file: RcFile) => {
      try {
        const avatarUploadResult = await companyLogoUpload(file, serviceConfig);
        const uploadResult = {
          blob: file,
          id: avatarUploadResult.assetId,
          name: file.name,
          originalBlob: file,
          originalUrl: avatarUploadResult.url,
          thumbnailBlob: file,
          thumbnailUrl: avatarUploadResult.url,
          type: file.type,
          url: avatarUploadResult.url,
        } as unknown as FileData;

        handleImageUploadDone(uploadResult);
        return uploadResult as unknown as UploadFile;
      } catch (ex) {
        errorHandler(ex, "upload avatar");
        return {} as unknown as UploadFile;
      }
    },
    [errorHandler, handleImageUploadDone, serviceConfig]
  );

  const formItems: NxpFormGridItemProps<SubcontractorForm>[] = useMemo(
    () => [
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.companyName"),
        itemFieldName: "name",
        required: true,
        controlProps: {
          placeholder: t("app.common.placeholder.pleaseEnter"),
        },
        span: 12,
      },
      {
        controlType: "select",
        label: t("ManageCompanies.modal.label.organizationType"),
        itemFieldName: "organisationTypeId",
        required: true,
        controlProps: {
          placeholder: t("app.common.placeholder.pleaseEnter"),
          allowClear: true,
          options: [
            ...organisationTypes
              .sort((a, b) => sorterForString(a?.name, b?.name))
              .map((otp) => ({
                key: otp?.id,
                label: otp?.name,
                value: otp?.id,
                disabled: otp?.isDisabled,
              })),
          ],
        },
        span: 12,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.emailDomain"),
        itemFieldName: "mailDomain",
        controlProps: {
          addonBefore: "@",
        },
        span: 12,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.businessNumber"),
        itemFieldName: "businessRegistrationNumber",
        required: true,
        span: 12,
      },
      {
        controlType: "textarea",
        label: t("ManageCompanies.modal.label.postalAddress"),
        itemFieldName: "postalAddress",
        required: true,
        startOnNewRow: true,
        span: 24,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.city"),
        itemFieldName: "city",
        required: true,
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.state"),
        itemFieldName: "state",
        span: 8,
      },
      {
        controlType: "select",
        label: t("ManageCompanies.modal.label.country"),
        itemFieldName: "country",
        required: true,
        controlProps: {
          allowClear: true,
          options: countryList,
        },
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.phoneNumber"),
        itemFieldName: "phoneNumber",
        required: true,
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.contactPerson"),
        itemFieldName: "contactPerson",
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.contactPhone"),
        itemFieldName: "contactPhone",
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.jdeCode"),
        itemFieldName: "jdeCode",
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.parentCompany"),
        itemFieldName: "parentCompany",
        span: 8,
      },
      {
        controlType: "input",
        label: t("ManageCompanies.modal.label.bvdRating"),
        itemFieldName: "bvdRating",
        span: 8,
      },
      {
        controlType: "custom",
        customContent: (
          <Space align="center" split={<Divider type="vertical" />}>
            <NxpUpload
              previewFile={undefined}
              dragger={true}
              maxCount={1}
              className={classes.upload}
              showUploadList
              onUpload={handleUpload}
              onChange={(e) => {
                if (!e.fileList.length) {
                  handleImageUploadClear();
                }
              }}
            />
            {!isEmpty(subcontractor?.logoAssetId) && (
              <Image
                src={logoImage?.url}
                fallback={noPreviewImg}
                height={165}
                width={250}
              />
            )}
          </Space>
        ),
        label: t("ManageCompanies.modal.label.logo"),
        itemFieldName: "logoAssetId",
        startOnNewRow: true,
        span: 24,
      },
    ],
    [
      classes.upload,
      handleImageUploadClear,
      handleUpload,
      logoImage?.url,
      organisationTypes,
      subcontractor?.logoAssetId,
      t,
    ]
  );

  const handleCancelButton = useCallback(() => {
    setSubcontractor(emptySubcontractor);
    onClose();
  }, [emptySubcontractor, onClose]);

  const handleSaveValidated = useCallback(async () => {
    if (editSubcontractor && get(editSubcontractor, "id", false)) {
      setSaving(true);
      try {
        const updateSubcontractorRes = await updateSubcontractor(
          editSubcontractor?.id,
          omit(subcontractor, [
            "id",
            "operationEntityId",
            "createdDate",
            "lastUpdatedDate",
            "createdById",
            "lastUpdatedById",
            "isDisabled",
            "deletedAt",
          ]) as SubcontractorForm,
          serviceConfig
        );
        if (updateSubcontractorRes) {
          notify.success(
            t("ManageCompanies.action.success", {
              action: t("ManageCompanies.action.updated"),
            })
          );
          onClose();
        }
      } catch (error: any) {
        notify.error(error);
      } finally {
        onRefresh();
        setSaving(false);
      }
    } else {
      setSaving(true);
      try {
        const createSubcontractorRes = await createSubcontractor(
          subcontractor,
          serviceConfig
        );
        if (createSubcontractorRes) {
          notify.success(
            t("ManageCompanies.action.success", {
              action: t("ManageCompanies.action.created"),
            })
          );
          onClose();
        }
      } catch (error: any) {
        notify.error(error);
      } finally {
        onRefresh();
        setSaving(false);
      }
    }
  }, [editSubcontractor, onClose, onRefresh, serviceConfig, subcontractor, t]);

  const [validationError, , clearError, saveWithValidate] = useYupValidate<any>(
    subcontractor,
    formSchema,
    handleSaveValidated
  );

  const handleSave = useCallback(() => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  }, [saveWithValidate]);

  const handleFormStateChange = useCallback(
    (fieldName: keyof typeof subcontractor, value: unknown) => {
      setSubcontractor((prevState: any) => ({
        ...prevState,
        [fieldName]: value,
      }));
    },
    []
  );

  useEffect(() => {
    if (show) {
      if (editSubcontractor) {
        if (editSubcontractor.logoAssetId) {
          sign(
            "company-upload",
            { assets: [editSubcontractor.logoAssetId] },
            serviceConfig
          ).then((imgPack) => {
            setLogoImage(
              (preState) =>
                ({
                  ...preState,
                  url: get(imgPack, "assets[0].url"),
                  id: get(imgPack, "assets[0].asset_name"),
                } as FileData)
            );
            setSubcontractor(editSubcontractor);
          });
        }
        setSubcontractor(editSubcontractor);
      }
    } else setSubcontractor(emptySubcontractor as SubcontractorForm);
    return () => {
      setSaving(false);
      setLogoImage({} as FileData);
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <NxpModal
      title={t("ManageCompanies.action.label", {
        action: editSubcontractor
          ? t("ManageCompanies.action.edit")
          : t("ManageCompanies.action.add"),
      })}
      width="large"
      visible={show}
      onCancel={handleCancelButton}
      showMandatoryLabel={true}
      footer={
        <>
          <NxpButton onClick={handleSave} loading={saving}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpSpin spinning={!subcontractor}>
        <NxpFormGrid
          validationError={validationError}
          formItems={formItems}
          formState={subcontractor}
          onFormStateChange={handleFormStateChange}
        />
      </NxpSpin>
    </NxpModal>
  );
};

export default AddCompanyModal;
