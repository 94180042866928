import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpHeader,
  NxpModalFooter,
  NxpSpin,
  notify,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import moment from "moment-timezone";
import {
  EditEntity,
  getOperationEntity,
  updateEntityDetail,
} from "../../services/entity";
import useAppContext from "../../hooks/useAppContext";
import { useTranslation } from "react-i18next";
import { LabeledValue } from "antd/lib/select";

type EditEntityForm = {
  name: string;
  entityCode: string;
  parentOperationEntityId: string;
  timezone: string;
  description?: string;
};

const formSchema = yup.object({
  name: yup.string().required(),
  entityCode: yup.string().required(),
  parentOperationEntityId: yup.string().nullable(),
  timezone: yup.string().required(),
  description: yup.string().nullable(),
});

const initiState = {
  name: "",
  entityCode: "",
  parentOperationEntityId: "",
  timezone: "",
  description: "",
};

type LoadingState = "unInitialization" | "fetching" | "ready" | "editSaving";

function EditEntityPage() {
  const { t } = useTranslation();
  const { serviceConfig, errorHandler } = useAppContext();
  const [editForm, setEditForm] = useState<EditEntityForm>(initiState);
  const [loading, setLoading] = useState<LoadingState>("unInitialization");

  const timezoneOptions = useMemo(() => {
    return moment.tz.names().map(
      (tz) =>
        ({
          label: tz,
          value: tz,
        } as LabeledValue)
    );
  }, []);

  const formItems: NxpFormGridItemProps<EditEntityForm>[] = [
    {
      controlType: "input",
      controlProps: {
        type: "text",
      },
      startOnNewRow: true,
      required: true,
      label: t("app.common.Name"),
      itemFieldName: "name",
      span: 15,
    },
    {
      controlType: "input",
      controlProps: {
        type: "text",
        disabled: true,
      },
      required: true,
      label: t("EditEntityPage.column.label.code"),
      itemFieldName: "entityCode",
      span: 15,
    },
    {
      controlType: "input",
      controlProps: {
        type: "text",
        disabled: true,
      },
      required: true,
      label: t("EditEntityPage.column.label.parent"),
      itemFieldName: "parentOperationEntityId",
      span: 15,
    },
    {
      controlType: "select",
      required: true,
      label: t("EditEntityPage.column.label.timezone"),
      itemFieldName: "timezone",
      controlProps: {
        options: timezoneOptions,
        allowClear: true,
      },
      span: 15,
    },
    {
      controlType: "input",
      controlProps: {
        type: "text",
      },
      label: t("EditEntityPage.column.label.description"),
      itemFieldName: "description",
      span: 15,
    },
  ];

  const fetchOperationEntity = useCallback(async () => {
    setLoading("fetching");
    try {
      if (serviceConfig.entityId) {
        const operationEntity = await getOperationEntity(
          serviceConfig,
          serviceConfig.entityId
        );
        setEditForm(() => {
          return operationEntity as EditEntityForm;
        });
      }
    } catch (err) {
      errorHandler(err, t("EditEntityPage.fetch.error"));
    } finally {
      setLoading("ready");
    }
  }, [errorHandler, serviceConfig, t]);

  useEffect(() => {
    fetchOperationEntity();
  }, [fetchOperationEntity]);

  const handleFormGridStateChange = (
    fieldName: keyof EditEntityForm,
    value: any
  ) => {
    const data: Partial<EditEntityForm> = {};
    data[fieldName] = value;
    setEditForm({ ...editForm, ...data });
  };

  const handleSaveValidated = async () => {
    setLoading("editSaving");
    try {
      const newEntityDetail: EditEntity = {
        name: editForm.name,
        description: editForm.description,
        timezone: editForm.timezone,
      };
      await updateEntityDetail(serviceConfig, newEntityDetail);
      notify.actionCompleted();
    } catch (err) {
      errorHandler(err, t("EditEntityPage.updated.error"));
    } finally {
      setLoading("ready");
    }
  };

  const [validationError, , , saveWithValidate] = useYupValidate<any>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  if (loading === "fetching") {
    return (
      <NxpSpin size="large" style={{ marginTop: "180px" }}>
        <div className="content" />
      </NxpSpin>
    );
  }

  return (
    <>
      <NxpHeader titleContent={t("EditEntityPage.Header")} />
      <NxpFormGrid
        formState={editForm}
        validationError={validationError}
        formItems={formItems}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <NxpButton loading={loading === "editSaving"} onClick={handleSave}>
          {t("app.common.Save")}
        </NxpButton>
      </NxpModalFooter>
    </>
  );
}

export default EditEntityPage;
