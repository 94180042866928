import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";

import { CaretRightFilled } from "@ant-design/icons";
import { NxpLabel, NxpModalConfirm } from "@nexploretechnology/nxp-ui";
import { Select } from "antd";
import clsx from "clsx";

import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import { Pds, PdsTranslationFieldNameEnum } from "../../../../services/pds";
import { DEFAULT_LANGUAGE_KEY } from "../../../../utils/const";
import { findPdsDataSet, findPdsSpectrumByDataSetId } from "../../pdsHelper";
import {
  DataSetDetailsEntry,
  DataSetDetailsRelationState,
} from "../DataSetDetails";

const useStyles = createUseStyles((theme) => ({
  dependencyTree: {
    "& > .nxpLabel": {
      display: "inline-block",
      marginRight: theme.spacing(1),
    },
    "& > .ant-select": {
      width: "calc(100% - 150px)",
    },
    "& > button": {
      textAlign: "left",
      cursor: "pointer",
      borderRadius: theme.borderRadius.main,
      border: "none",
      background: "none",
      display: "block",
      marginTop: theme.spacing(1),
      marginLeft: 0,
      "&:hover, &.active": {
        backgroundColor: theme.palette.backgroundPrimaryLight,
      },
      "& > ul": {
        listStyle: "none",
        padding: 0,
        margin: 0,
        "& > li::before": {
          content: "'┗╸'",
          marginLeft: theme.spacing(2),
        },
      },
    },
  },
}));

interface DependencyTreeProps {
  dataSetId: string;
  dataEntries: DataSetDetailsEntry[];
  relationState: DataSetDetailsRelationState;
  pds: Pds;
  parentDataSetId?: string;
  activeParentDataSetEntryId?: string;
  onParentDataSetChange: (value: string) => void;
  onActiveParentDataSetEntryIdChange: (value: string) => void;
}

const DependencyTree: React.FC<DependencyTreeProps> = ({
  dataSetId,
  dataEntries,
  relationState,
  pds,
  parentDataSetId = "",
  activeParentDataSetEntryId,
  onParentDataSetChange,
  onActiveParentDataSetEntryIdChange,
}) => {
  const classes = useStyles();
  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const [confirmNewParentDataSetId, setConfirmNewParentDataSetId] =
    useState<string>();

  const handleParentDataSetChange = (value: string) => {
    if (relationState.relations.length) {
      setConfirmNewParentDataSetId(value);
    } else {
      onParentDataSetChange(value);
      onChangePromptUpdate(true);
    }
  };

  const buildButtons = () => {
    const buttons = findPdsDataSet(parentDataSetId, pds)
      ?.dataSetEntries.sort((a, b) =>
        a.dataEntry.code > b.dataEntry.code ? 1 : -1
      )
      .map((parentDataSetEntry) => (
        <button
          key={parentDataSetEntry.id}
          onClick={() =>
            onActiveParentDataSetEntryIdChange(parentDataSetEntry.id)
          }
          className={clsx(
            parentDataSetEntry.id === activeParentDataSetEntryId && "active"
          )}
        >
          <span>
            <CaretRightFilled />{" "}
            {`${parentDataSetEntry.dataEntry.code} - ${
              parentDataSetEntry.dataEntry.translations.find(
                (translation) =>
                  translation.fieldName ===
                    PdsTranslationFieldNameEnum.DISPLAY_NAME &&
                  translation.langKey === DEFAULT_LANGUAGE_KEY
              )?.value
            }`}
          </span>
          {relationState.relations.find(
            (relation) =>
              relation.parentDataSetEntryId === parentDataSetEntry.id
          ) ? (
            <ul>
              {relationState.relations
                .filter(
                  (relation) =>
                    relation.parentDataSetEntryId === parentDataSetEntry.id
                )
                .map((relation) =>
                  dataEntries.find(
                    (childEntry) =>
                      childEntry.itemUuid === relation.childItemUuid
                  )
                )
                .sort((a, b) =>
                  a?.code && b?.code && a.code > b.code ? 1 : -1
                )
                .map((childEntry) => (
                  <li key={childEntry?.itemUuid}>
                    {childEntry?.code} -{" "}
                    {childEntry?.displayName[DEFAULT_LANGUAGE_KEY]}
                  </li>
                ))}
            </ul>
          ) : null}
        </button>
      ));

    return buttons?.length === 0 ? (
      <p>Parent has no value entries defined.</p>
    ) : (
      buttons
    );
  };

  return (
    <div className={classes.dependencyTree}>
      <NxpLabel>Select Parent</NxpLabel>{" "}
      <Select
        aria-label="Select Parent"
        value={parentDataSetId || undefined}
        options={findPdsSpectrumByDataSetId(dataSetId, pds)
          ?.dataSets.filter((set) => set.id !== dataSetId)
          .map((set) => ({
            label: `${set.code} - ${
              set.translations.find(
                (translation) =>
                  translation.fieldName ===
                    PdsTranslationFieldNameEnum.DISPLAY_NAME &&
                  translation.langKey === DEFAULT_LANGUAGE_KEY
              )?.value
            }`,
            value: set.id,
          }))}
        onChange={handleParentDataSetChange}
      />
      {buildButtons()}
      <NxpModalConfirm
        modalNoPropagation
        visible={!!confirmNewParentDataSetId}
        onClose={() => setConfirmNewParentDataSetId(undefined)}
        onConfirm={() => {
          if (confirmNewParentDataSetId) {
            onParentDataSetChange(confirmNewParentDataSetId);
            setConfirmNewParentDataSetId(undefined);
            onChangePromptUpdate(true);
          }
        }}
      >
        Changing dependency parent will clear existing dependenies. OK to
        proceed?
      </NxpModalConfirm>
    </div>
  );
};

export default DependencyTree;
