import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpPanel } from "@nexploretechnology/nxp-ui";
import { Divider } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
  ApplicationPermissionSetData,
  EntityRoleAccessRight,
} from "../../services/accessControl";
import { EntityRole } from "../../services/entity";

interface AccessRightPanelProps {
  selectedRole?: EntityRole;
  editing: boolean;
  isSubmitting: boolean;
  userRights: EntityRoleAccessRight[];
  applicationPermissionSetData: ApplicationPermissionSetData[];
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (scopes: string[]) => void;
}

const useStyles = createUseStyles((theme) => ({
  managePanel: {
    flex: [1, 1, "auto"],
  },
  selectAllCheckbox: {
    paddingRight: theme.spacing(4),
  },
  row: {
    display: "flex",
    padding: theme.spacing(0, 3),
  },
  accessRight: {
    flex: [1, 1, "auto"],
    fontWeight: theme.fontWeight.bold,
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AccessRightPanel: React.FC<AccessRightPanelProps> = ({
  selectedRole,
  editing,
  isSubmitting,
  userRights,
  applicationPermissionSetData,
  setEditing,
  onSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<string[]>([]);
  const [selectAllIndeterminate, setSelectAllIndeterminate] = useState<
    string[]
  >([]);
  const [userRightsMap, setUserRightsMap] = useState<
    Map<string, ApplicationPermissionSetData[]>
  >(new Map<string, ApplicationPermissionSetData[]>());

  const toPermissionString = (
    application: string,
    resource: string,
    action: string
  ) => {
    return `@${application}/${resource}:${action}`;
  };

  const toI18nKey = (application: string, resource: string, action: string) => {
    return `AccessRight.${application}${resource}${action}`;
  };

  const handleSelectAll = (
    e: CheckboxChangeEvent,
    value: ApplicationPermissionSetData[]
  ) => {
    const permissionList = value.map((v) =>
      toPermissionString(v.applicationCode, v.objectCode, v.action)
    );
    const newSelectedRows = selectedRows.filter(
      (s) => !permissionList.includes(s)
    );
    if (e.target.checked) {
      setSelectedRows(newSelectedRows.concat(permissionList));
    } else {
      setSelectedRows(newSelectedRows);
    }
  };

  const handleCheck = (data: ApplicationPermissionSetData) => {
    const permission = toPermissionString(
      data.applicationCode,
      data.objectCode,
      data.action
    );
    const newSelectedRows = selectedRows.includes(permission)
      ? selectedRows.filter((s) => s !== permission)
      : [...selectedRows, permission];
    setSelectedRows(newSelectedRows);
  };

  const resetData = useCallback(() => {
    setEditing(false);
    const userRight = userRights?.find(
      (right) => right.roleId === selectedRole?.id
    );
    setSelectedRows(
      userRight
        ? userRight.permissions.map((p) =>
            toPermissionString(p.application, p.resource, p.action)
          )
        : []
    );
  }, [selectedRole, userRights, setEditing]);

  useEffect(() => {
    const m = new Map<string, ApplicationPermissionSetData[]>();
    applicationPermissionSetData.forEach((data) => {
      const arr = m.get(data.applicationCode);
      if (!arr) {
        m.set(data.applicationCode, [data]);
      } else {
        m.set(data.applicationCode, [...arr, data]);
      }
    });
    setUserRightsMap(m);
  }, [applicationPermissionSetData]);

  useEffect(() => {
    resetData();
  }, [selectedRole, resetData]);

  const handleClickCancel = () => {
    resetData();
  };

  useEffect(() => {
    const newSelectAll: string[] = [];
    const newSelectAllIndeterminate: string[] = [];
    Array.from(userRightsMap.entries()).forEach(([key, value]) => {
      const permissionList = value.map((v) =>
        toPermissionString(v.applicationCode, v.objectCode, v.action)
      );
      const newRightsLength = selectedRows.filter((s) =>
        permissionList.includes(s)
      ).length;
      if (newRightsLength === value.length) {
        newSelectAll.push(key);
      } else if (newRightsLength > 0) {
        newSelectAllIndeterminate.push(key);
      }
    });
    setSelectAll(newSelectAll);
    setSelectAllIndeterminate(newSelectAllIndeterminate);
  }, [selectedRows, userRightsMap]);

  return (
    <>
      <div className={classes.managePanel}>
        {Array.from(userRightsMap.entries()).map(([key, value]) => (
          <NxpPanel
            key={`panel-${key}`}
            titleContent={t(`AccessRight.${key}`)}
            extra={
              <div
                className={classes.selectAllCheckbox}
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox
                  disabled={!editing}
                  indeterminate={selectAllIndeterminate.includes(key)}
                  onChange={(e: CheckboxChangeEvent) =>
                    handleSelectAll(e, value)
                  }
                  checked={selectAll.includes(key)}
                />
              </div>
            }
          >
            {value.map((row, idx) => {
              return (
                <div key={`panel-${key}-row-${row.id}`}>
                  <div className={classes.row}>
                    <span className={classes.accessRight}>
                      {t(
                        toI18nKey(
                          row.applicationCode,
                          row.objectCode,
                          row.action
                        )
                      )}
                    </span>
                    <Checkbox
                      disabled={!editing}
                      checked={selectedRows.includes(
                        toPermissionString(
                          row.applicationCode,
                          row.objectCode,
                          row.action
                        )
                      )}
                      onChange={() => handleCheck(row)}
                    />
                  </div>
                  {idx !== value.length - 1 && <Divider />}
                </div>
              );
            })}
          </NxpPanel>
        ))}
        {editing && (
          <div className={classes.buttonGroup}>
            <NxpButton
              type="default"
              onClick={handleClickCancel}
              loading={isSubmitting}
            >
              {t("app.common.Cancel")}
            </NxpButton>
            <NxpButton
              onClick={() => onSubmit(selectedRows)}
              loading={isSubmitting}
            >
              {t("app.common.Submit")}
            </NxpButton>
          </div>
        )}
      </div>
    </>
  );
};

export default AccessRightPanel;
