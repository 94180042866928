import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModalFooter,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Input } from "antd";
import * as yup from "yup";

import AppChangePromptButton from "../../../../components/AppChangePrompt/AppChangePromptButton";
import AppChangePromptContext from "../../../../components/AppChangePrompt/AppChangePromptContext";
import { LangLabel } from "../../../../i18n/LangLabelEnum";
import { PdsSpectrumForm } from "../../../../services/pds";
import {
  DEFAULT_LANGUAGE_KEY,
  DEFAULT_LANGUAGES,
} from "../../../../utils/const";
import { checkDefaultLanguageDefined } from "../../../../utils/multilingualHelper";

const useStyles = createUseStyles((theme) => ({
  SpectrumModalForm: {},
  languageInputDiv: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 3),
    marginTop: theme.spacing(0.5),
    alignContent: "flex-start",
    "& > span.ant-input-group-wrapper": {
      width: `calc(50% - ${theme.spacing(1.5)})`,
      flexShrink: 0,
      "& .ant-input-group-addon:first-child": {
        width: theme.spacing(8),
        fontSize: theme.fontSize.smaller,
        color: theme.palette.textLabel,
      },
    },
    "& span.requiredLang": {
      color: theme.palette.danger,
      marginLeft: 2,
    },
  },
}));

interface SpectrumModalFormProps {
  initForm: PdsSpectrumForm;
  loading: boolean;
  onSave: (form: PdsSpectrumForm) => void;
  onCancel: () => void;
}

const SpectrumModalForm: React.FC<SpectrumModalFormProps> = ({
  initForm,
  loading,
  onSave,
  onCancel,
}) => {
  const [form, setForm] = useState<PdsSpectrumForm>(initForm);

  const formSchema = yup.object({
    displayName: yup
      .object()
      .nullable()
      .test("displayName", "Display Name in English required.", function (val) {
        return checkDefaultLanguageDefined(val);
      }) as any,
    code: yup
      .string()
      .nullable()
      .matches(
        /^\s*[A-Za-z0-9_]+\s*$/,
        "Only alphanumerics and underscore allowed."
      )
      .required("Code required."),
    description: yup.object() as any,
    order: yup.number(),
  });

  const [validationError, , , saveWithValidate] =
    useYupValidate<PdsSpectrumForm>(form, formSchema, () => onSave(form));

  const { onChangePromptUpdate } = useContext(AppChangePromptContext);

  const handleFormGridStateChange = (
    fieldName: keyof PdsSpectrumForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    if (["displayName", "description"].includes(fieldName)) {
      setForm((prevState) => {
        const map = prevState[fieldName] as Record<LangLabel, string>;
        Object.entries(value as object).forEach(([lang, value]) => {
          map[lang as LangLabel] = value;
        });
        return {
          ...prevState,
          [fieldName]: map,
          name:
            fieldName === "displayName" ? map[LangLabel.EN] : prevState.name,
        };
      });
    } else {
      setForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
    onChangePromptUpdate(true);
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<PdsSpectrumForm>[] = [
    {
      controlType: "input",
      label: "Code",
      itemFieldName: "code",
      required: true,
      span: 12,
    },
    {
      controlType: "custom",
      label: "Display Name",
      itemFieldName: "displayName",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.displayName && form.displayName[lang]}
              addonBefore={
                lang === DEFAULT_LANGUAGE_KEY ? (
                  <>
                    {t(`app.common.Language.${lang}`)}
                    <span className="requiredLang">*</span>
                  </>
                ) : (
                  t(`app.common.Language.${lang}`)
                )
              }
              onChange={(e) =>
                handleFormGridStateChange("displayName", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
    {
      controlType: "custom",
      label: "Description",
      itemFieldName: "description",
      required: false,
      span: 24,
      customContent: (
        <div className={classes.languageInputDiv}>
          {DEFAULT_LANGUAGES.map((lang) => (
            <Input
              key={lang}
              value={form.description && form.description[lang]}
              addonBefore={t(`app.common.Language.${lang}`)}
              onChange={(e) =>
                handleFormGridStateChange("description", {
                  [lang]: e.target.value,
                })
              }
            />
          ))}
        </div>
      ),
    },
  ];

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <div className={classes.SpectrumModalForm}>
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormGridStateChange}
      />
      <NxpModalFooter>
        <AppChangePromptButton
          disabled={loading}
          type="text"
          onClick={onCancel}
        >
          Cancel
        </AppChangePromptButton>
        <NxpButton loading={loading} onClick={handleSave}>
          Save
        </NxpButton>
      </NxpModalFooter>
    </div>
  );
};

export default SpectrumModalForm;
