import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpModal,
  NxpSelectUserOption,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { ADUser } from "../../services/userDirectory";
import BatchAssignRoleForm from "./BatchAssignRoleForm";
import { SelectUserFormData } from "./ManageRolePageContainer";

interface BatchAssignRoleFormProps {
  showBatchAssignRoleModal: boolean;
  isSubmitting: boolean;
  isSearchingAdUsers: boolean;
  userSelectOptions: (NxpSelectUserOption & { user?: ADUser; tag: string })[];
  onBatchAssign: (form: SelectUserFormData) => void;
  setShowBatchAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSearchAdUsers: (searchString: string) => void;
}

const emptyForm: SelectUserFormData = {
  users: [],
  adUsers: [],
};

const BatchAssignRoleModal: React.FC<BatchAssignRoleFormProps> = ({
  showBatchAssignRoleModal,
  isSubmitting,
  isSearchingAdUsers,
  userSelectOptions,
  onBatchAssign,
  setShowBatchAssignRoleModal,
  onSearchAdUsers,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<SelectUserFormData>(emptyForm);

  const formSchema = yup.object().shape({
    users: yup.array().min(1, t("ManageRole.UsersIsRequired")),
  });

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<SelectUserFormData>(form, formSchema, () =>
      onBatchAssign(form)
    );

  useEffect(() => {
    setForm(emptyForm);
    clearError();
  }, [showBatchAssignRoleModal, clearError]);

  const handleFormGridStateChange = (
    fieldName: keyof SelectUserFormData,
    value: any
  ) => {
    const data: Partial<SelectUserFormData> = {};
    if (fieldName === "users") {
      const users = value as Array<string>;
      data[fieldName] = users;
      let adUsers = [...form.adUsers];
      users
        .filter((u) => u.startsWith("a_"))
        .forEach((u) => {
          if (!form.adUsers.some((a) => a.id === u)) {
            const adUser = userSelectOptions.find((o) => o.id === u);
            adUsers.push(adUser!.user!);
          }
        });
      adUsers = adUsers.filter((a) => users.includes(`a_${a.id}`));
      data["adUsers"] = adUsers;
    }
    setForm({ ...form, ...data });
  };

  return (
    <NxpModal
      title={t("app.common.BatchAssign")}
      visible={showBatchAssignRoleModal}
      onCancel={() => setShowBatchAssignRoleModal(false)}
      width="medium"
      destroyOnClose={true}
      footer={
        <>
          <NxpButton
            type="default"
            onClick={() => setShowBatchAssignRoleModal(false)}
            loading={isSubmitting}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton
            onClick={() => saveWithValidate(undefined)}
            loading={isSubmitting}
          >
            {t("app.common.Assign")}
          </NxpButton>
        </>
      }
    >
      <BatchAssignRoleForm
        form={form}
        validationError={validationError}
        onSearchAdUsers={onSearchAdUsers}
        isSearchingAdUsers={isSearchingAdUsers}
        userSelectOptions={userSelectOptions}
        handleFormGridStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default BatchAssignRoleModal;
