import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { notify, NxpSelectUserOption } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useDebouncedValue } from "../../hooks/useDebounce";
import { getMyRoleAccessRight } from "../../services/accessControl";
import { deleteEntityRole, getEntityRoles } from "../../services/entity";
import {
  AddUser,
  AddUserEntity,
  ADUser,
  createUser,
  createUserEntity,
  deleteUserEntity,
  getADUsers,
  getUsers,
  User,
} from "../../services/userDirectory";
import AddRoleModal from "./AddRoleModal";
import BatchAssignConfirmationModal from "./BatchAssignConfirmationModal";
import BatchAssignRoleModal from "./BatchAssignRoleModal";
import ManageRolePageLayout from "./ManageRolePageLayout";

interface ManageRolePageContainerProps {}

export interface SelectUserFormData {
  users: string[];
  adUsers: ADUser[];
}

export interface ManageRoleTableData {
  id: string;
  roleCode: string;
  roleName: string;
  users: { userEntityId: string; user: User }[];
  isAdmin: boolean;
  isDisabled: boolean;
  description: string;
}

const ManageRolePageContainer: React.FC<ManageRolePageContainerProps> = () => {
  const appContext = useAppContext();
  const {
    activeEntity,
    activeEntityType,
    serviceConfig,
    users,
    accessPermissionList,
    tenantId,
    errorHandler,
    onAppContextCacheItemUpdate,
    hasRight,
  } = appContext;
  const navigate = useNavigate();

  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const [showBatchAssignRoleModal, setShowBatchAssignRoleModal] =
    useState<boolean>(false);
  const [showBatchAssignConfirmModal, setShowBatchAssignConfirmModal] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<ManageRoleTableData>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [rowData, setRowData] = useState<ManageRoleTableData[]>();
  const [searchValue, setSearchValue] = useState<string>("");
  const searchValueDebounced = useDebouncedValue(searchValue, 500, [
    searchValue,
  ]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<
    Record<string, SelectUserFormData>
  >({});
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [batchAssignConfirmForm, setBatchAssignConfirmForm] =
    useState<SelectUserFormData>();
  const [init, setInit] = useState<boolean>(true);
  const [adUsers, setAdUsers] = useState<ADUser[]>([]);
  const [searchAdUserString, setSearchAdUserString] = useState<string>("");
  const searchAdUserStringDebounced = useDebouncedValue(
    searchAdUserString,
    500,
    [searchAdUserString]
  );
  const [isSearchingAdUsers, setIsSearchingAdUsers] = useState<boolean>(false);
  const [userSelectOptions, setUserSelectOptions] = useState<
    (NxpSelectUserOption & { user?: ADUser; tag: string })[]
  >([]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      if (!activeEntity || !activeEntityType || !tenantId) return;

      const result = await Promise.all([
        getEntityRoles(serviceConfig, activeEntity!.id, activeEntityType, true),
        getUsers(serviceConfig),
        getADUsers(serviceConfig, tenantId),
        getMyRoleAccessRight(serviceConfig, activeEntityType, activeEntity!.id),
      ]);

      const entityRoles = result[0];
      const users = result[1];
      onAppContextCacheItemUpdate("users", users);
      const adUsers = result[2];
      setAdUsers(adUsers);
      const accessPermissionList = result[3];
      onAppContextCacheItemUpdate("accessPermissionList", accessPermissionList);

      const rowData: ManageRoleTableData[] = [];
      entityRoles.forEach((role) => {
        const usersWithRole = role.userEntities
          ?.map((entity) => {
            const user = users.find((users) => users.id === entity.userId);
            if (!user) return undefined;
            return {
              userEntityId: entity.id,
              user: user,
            };
          })
          .filter((user) => user !== undefined);
        const row = {
          id: role.id,
          roleCode: role.roleCode,
          roleName: role.roleName,
          users: usersWithRole,
          isAdmin: role.isAdmin,
          isDisabled: role.isDisabled,
          description: role.description,
        } as ManageRoleTableData;
        rowData.push(row);
        setUserRoles((prevState) => ({
          ...prevState,
          [role.id]: {
            users: usersWithRole
              ? usersWithRole.map((userWithRole) => userWithRole!.user.id)
              : [],
            adUsers: [],
          },
        }));
      });
      setInit(false);
      setRowData(rowData);
    } catch (err) {
      errorHandler(err, "fetch roles");
    } finally {
      setLoading(false);
    }
  }, [
    activeEntity,
    activeEntityType,
    serviceConfig,
    tenantId,
    errorHandler,
    onAppContextCacheItemUpdate,
  ]);

  useEffect(() => {
    if (refresh) {
      fetch();
      setSelectedRows([]);
      setIsFormDirty(false);
      setRefresh(false);
    }
  }, [refresh, fetch]);

  useEffect(() => {
    if (
      !init &&
      (!hasRight("@entity/entity:view") || !hasRight("@entity/role:view"))
    ) {
      onAppContextCacheItemUpdate("activeEntity", undefined);
      navigate("/entities");
    }
  }, [
    init,
    accessPermissionList,
    hasRight,
    onAppContextCacheItemUpdate,
    navigate,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleAddRole = () => {
    setIsEdit(false);
    setShowAddRoleModal(true);
  };

  const handleRowSelect = (item: ManageRoleTableData) => {
    let newSelectedRows: string[] = [];
    if (selectedRows.includes(item.id)) {
      newSelectedRows = selectedRows.filter((row) => row !== item.id);
    } else {
      newSelectedRows = [...selectedRows, item.id];
    }
    setSelectedRows(newSelectedRows);
  };

  const handleRowEdit = (item: ManageRoleTableData) => {
    setEditData(item);
    setIsEdit(true);
    setShowAddRoleModal(true);
  };

  const handleRowDelete = async (item: ManageRoleTableData) => {
    try {
      setIsSubmitting(true);
      const deleteSuccess = await deleteEntityRole(item.id, serviceConfig);
      if (deleteSuccess) {
        notify.actionCompleted();
        setRefresh(true);
      }
    } catch (err) {
      errorHandler(err, "delete entity");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUserRolesChange = (entityRoleId: string, users: string[]) => {
    setIsFormDirty(true);
    let adUsers = [...userRoles[entityRoleId].adUsers];
    users
      .filter((u) => u.startsWith("a_"))
      .forEach((u) => {
        if (!userRoles[entityRoleId].adUsers.some((a) => a.id === u)) {
          const adUser = userSelectOptions.find((o) => o.id === u);
          if (adUser && adUser.user) {
            adUsers.push(adUser.user);
          }
        }
      });
    adUsers = adUsers.filter((a) => users.includes(`a_${a.id}`));
    setUserRoles((prevState) => ({
      ...prevState,
      [entityRoleId]: {
        users: users,
        adUsers: adUsers,
      },
    }));
  };

  const handleBatchAssign = (form: SelectUserFormData) => {
    setShowBatchAssignRoleModal(false);
    setShowBatchAssignConfirmModal(true);
    setBatchAssignConfirmForm(form);
  };

  const handleBatchAssignConfirm = async (notifyUsers: string[]) => {
    try {
      setIsSubmitting(true);
      if (!batchAssignConfirmForm) return;
      const createUserPromiseArray = batchAssignConfirmForm.adUsers.map((a) => {
        const addUser = {
          firstName: a.givenName,
          lastName: a.surname,
          primaryEmail: a.mail,
          isADUser: true,
          entityRoleIds: selectedRows,
        } as AddUser;
        return createUser(serviceConfig, addUser);
      });
      await Promise.all(createUserPromiseArray);
      const addUserEntities = batchAssignConfirmForm.users
        .filter((userId) => !userId.startsWith("a_"))
        .map((userId) => {
          const rolesToBeUpdate = selectedRows.filter(
            (row) => !userRoles[row].users.includes(userId)
          );
          const addUserEntity: AddUserEntity = {
            entityRoleIds: rolesToBeUpdate,
          };
          return createUserEntity(serviceConfig, userId, addUserEntity);
        });
      await Promise.all(addUserEntities);
      notify.actionCompleted();
      setShowBatchAssignConfirmModal(false);
      setIsSubmitting(false);
      setRefresh(true);
    } catch (err) {
      errorHandler(err, "batch assign");
    }
  };

  const handleReset = () => {
    setRefresh(true);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (!rowData) return;
      const adUsersIdMap: Map<string, AddUser> = new Map<string, AddUser>();

      rowData.forEach((row) => {
        userRoles[row.id].adUsers.forEach((adUser) => {
          let addUser: AddUser;
          addUser = {
            firstName: adUser.givenName,
            lastName: adUser.surname,
            primaryEmail: adUser.mail,
            isADUser: true,
            entityRoleIds: [],
          };
          if (!adUsersIdMap.has(adUser.mail)) {
            adUsersIdMap.set(adUser.mail, addUser);
          } else {
            addUser = adUsersIdMap.get(adUser.mail)!;
          }
          if (
            addUser.entityRoleIds &&
            !addUser.entityRoleIds.includes(row.id)
          ) {
            addUser.entityRoleIds?.push(row.id);
          }
          adUsersIdMap.set(adUser.mail, addUser);
        });
      });

      const newAdUsers = [] as AddUser[];

      adUsersIdMap.forEach((adUser) => {
        newAdUsers.push(adUser);
      });

      const createUserPromiseArray = newAdUsers.map((addUser) => {
        return createUser(serviceConfig, addUser);
      });

      // create AD users and add roles
      await Promise.all(createUserPromiseArray);

      const promiseArray: Promise<any>[] = [];
      rowData.forEach((row) => {
        const originalRoleUsers = row.users;
        const removedRoleUsers = originalRoleUsers.filter(
          (ori) =>
            !userRoles[row.id].users.some((userId) => ori.user.id === userId)
        );
        const newRoleUsers = userRoles[row.id].users.filter(
          (userId) =>
            !userId.startsWith("a_") &&
            !originalRoleUsers.some((ori) => ori.user.id === userId)
        );
        removedRoleUsers.forEach((userEntity) => {
          promiseArray.push(
            deleteUserEntity(serviceConfig, userEntity.userEntityId)
          );
        });
        newRoleUsers.forEach((user) => {
          const addUserEntity: AddUserEntity = {
            entityRoleIds: [row.id],
          };
          promiseArray.push(
            createUserEntity(serviceConfig, user, addUserEntity)
          );
        });
      });

      const results = await Promise.all(promiseArray);
      notify.actionCompleted();
      setRefresh(true);
      return results;
    } catch (err) {
      errorHandler(err, "save changes");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearchAdUsers = (searchString: string) => {
    setSearchAdUserString(searchString);
  };

  const searchAdUser = useCallback(async () => {
    if (!tenantId) {
      return;
    }
    setIsSearchingAdUsers(true);
    const adUsers = await getADUsers(
      serviceConfig,
      tenantId,
      searchAdUserStringDebounced
    );
    setAdUsers(adUsers);
    setIsSearchingAdUsers(false);
  }, [searchAdUserStringDebounced, serviceConfig, tenantId]);

  useEffect(() => {
    searchAdUser();
  }, [searchAdUser]);

  useEffect(() => {
    const userSelectOptions = users
      .map((user) => {
        return {
          id: user.id,
          name: `${
            user.displayName
              ? user.displayName
              : `${user.firstName} ${user.lastName}`
          } <${user.primaryEmail}>`,
          tag: user.displayName
            ? user.displayName
            : `${user.firstName} ${user.lastName}`,
        } as NxpSelectUserOption & { user?: ADUser; tag: string };
      })
      .concat(
        adUsers
          .filter((user) => {
            return !users.some(
              (u) =>
                !user.givenName ||
                !user.surname ||
                !user.mail ||
                user.mail.toLowerCase() === u.primaryEmail.toLowerCase()
            );
          })
          .map((user) => {
            return {
              id: `a_${user.id}`,
              name: `${user.displayName} <${user.mail}>`,
              user: user,
              tag: user.displayName,
            } as NxpSelectUserOption & { user?: ADUser; tag: string };
          })
      );
    setUserSelectOptions(userSelectOptions);
  }, [users, adUsers]);

  return (
    <>
      <ManageRolePageLayout
        rowData={rowData}
        userRoles={userRoles}
        selectedRows={selectedRows}
        searchValue={searchValue}
        searchValueDebounced={searchValueDebounced}
        isFormDirty={isFormDirty}
        isSubmitting={isSubmitting}
        loading={loading}
        isSearchingAdUsers={isSearchingAdUsers}
        userSelectOptions={userSelectOptions}
        onSearch={handleSearch}
        onClickAddRole={handleAddRole}
        onRowSelect={handleRowSelect}
        onRowEdit={handleRowEdit}
        onRowDelete={handleRowDelete}
        onUserRolesChange={handleUserRolesChange}
        onReset={handleReset}
        onClickSubmit={handleSubmit}
        setShowBatchAssignRoleModal={setShowBatchAssignRoleModal}
        onSearchAdUsers={handleSearchAdUsers}
      />
      <AddRoleModal
        showAddRoleModal={showAddRoleModal}
        isEdit={isEdit}
        editData={editData}
        setShowAddRoleModal={setShowAddRoleModal}
        setRefresh={setRefresh}
      />
      <BatchAssignRoleModal
        showBatchAssignRoleModal={showBatchAssignRoleModal}
        isSubmitting={isSubmitting}
        isSearchingAdUsers={isSearchingAdUsers}
        userSelectOptions={userSelectOptions}
        onBatchAssign={handleBatchAssign}
        setShowBatchAssignRoleModal={setShowBatchAssignRoleModal}
        onSearchAdUsers={handleSearchAdUsers}
      />
      <BatchAssignConfirmationModal
        showModal={showBatchAssignConfirmModal}
        isSubmitting={isSubmitting}
        batchAssignConfirmForm={batchAssignConfirmForm}
        onBatchAssignConfirm={handleBatchAssignConfirm}
        setShowModal={setShowBatchAssignConfirmModal}
      />
    </>
  );
};

export default ManageRolePageContainer;
